import React, { useImperativeHandle, useRef, forwardRef } from 'react';

import {
    Modal as ModalSoluto,
    ModalRef,
    cls,
    Icon,
    useIsMobile,
    ModalProps,
} from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Button, { ButtonProps } from '@ds/components/shared/Button';
import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';

export interface ModalSmallProps extends ModalProps {
    title?: string;
    description?: string;
    buttons?: ButtonProps[];
    iconName?: string;
    labelClose?: string;
    iconType?: 'error' | 'success' | 'loading' | 'null';
    hideButtons?: boolean;
}

export type { ModalRef };

const ModalSmall = forwardRef<ModalRef | null, ModalSmallProps>(function (
    { title, description, buttons, iconName, iconType, labelClose, hideButtons = false, ...props },
    ref,
) {
    const isMobile = useIsMobile();

    const modalRef = useRef<ModalRef>(null);

    useImperativeHandle(ref, () => ({
        show: modalRef?.current?.show ?? false,
        preShow: modalRef?.current?.preShow ?? false,
        toggle: () => modalRef?.current?.toggle(),
    }));

    return (
        <ModalSoluto
            {...props}
            ref={modalRef}
            size={isMobile ? 'sm' : 'md'}
            header={<span></span>}
            classes={{
                body: cls('text-center', styles.body),
                closeButton: cls('d-none'),
            }}>
            {iconName && (
                <Icon
                    name={iconName}
                    size={32}
                    classes={{
                        icon: cls(
                            'mb-tiny',
                            styles.icon,
                            iconType ? styles[iconType] : '',
                        ),
                    }}
                />
            )}
            {title && (
                <Heading font="h100" component={'div'} className="mb-tiny">
                    {title}
                </Heading>
            )}

            {description && (
                <Text component={'div'} className={hideButtons ? '' : 'mb-tiny'} font="b300">
                    {description}
                </Text>
            )}

            {!hideButtons && (
                <div
                    className={cls(
                        'mt-lg',
                        buttons?.length
                            ? [
                                'd-flex',
                                'align-items-center',
                                'justify-content-center',
                                styles.buttons,
                            ]
                            : [],
                    )}>
                    {buttons?.length > 0 ? (
                        buttons?.map((button: ButtonProps, i: number) => {
                            return <Button  key={`modal-small-${i}`} {...button} />;
                        })
                    ) : (
                        <Button
                            label={labelClose ?? 'Close'}
                            onClick={() => {
                                modalRef?.current?.toggle();
                            }}
                        />
                    )}
                </div>
            )}
            
        </ModalSoluto>
    );
});

export default ModalSmall;
