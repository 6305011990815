import React from 'react';

import { Container, Row, Col, cls, useIsMobile } from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';
import Heading from '@ds/components/shared/Heading';
import StepItem, { StepItemProps } from './Item';

import CarouselBaseSection from '@ds/components/organisms/Carousel';

import styles from './styles.module.scss';

export interface StepSectionProps {
    title: string;
    items: StepItemProps[];
    anchor?: string;
    classes?: {
        wrapper?: string;
        section?: string;
        controls?: string;
    };
}

const StepSection = ({ title, items, anchor }: StepSectionProps) => {
    const isMobile = useIsMobile();

    return (
        <Section anchor={anchor}>
            <Container>
                <div data-theme="bg-gray" className={cls('p-grid-margin')}>
                    <Row>
                        <Col col={{ xl: 9 }}>
                            <Heading
                                component="h2"
                                font="h200"
                                className={cls('mb-xg')}>
                                {title}
                            </Heading>
                        </Col>
                    </Row>
                    {items?.length > 0 && (
                        isMobile ? (
                            <CarouselBaseSection
                                classes={{
                                    wrapper: cls(styles.carousel),
                                    controls: cls(styles.controls, 'mt-xg'),
                                }}
                                items={
                                    items?.length
                                        ? items.map((item, i) => (
                                            <StepItem
                                                key={i}
                                                {...item}
                                                className={cls(styles.item, 'position-relative')}
                                            />
                                        ))
                                        : []
                                }
                                showControls={true}
                                alignControls='right'
                                anchor={anchor}
                                reverse={false}
                                component="div"
                            />
                        ): (
                            <div className={cls(styles.wrapper)}>
                                {items.map((item, i) => {
                                    return (
                                        <StepItem
                                            key={i}
                                            {...item}
                                            className={cls(styles.item, 'position-relative')}
                                        />
                                    );
                                })}
                            </div>
                        )
                    )}
                </div>
            </Container>
        </Section>
    );
};

export default StepSection;
