function set(obj: Object, path: string, value: string) {
    let pList = path.split('.');
    let len = pList.length;
    for (let i = 0; i < len - 1; i++) {
        let elem = pList[i];
        if (!obj[elem]) obj[elem] = {};
        obj = obj[elem];
    }

    obj[pList[len - 1]] = value;
}

export function flatToNestedObject(target: Object) {
    const nested = {};

    Object.keys(target).forEach((path) => set(nested, path, target[path]));

    return nested;
}
