import {
    GraphQLClient,
    gql,
    RequestDocument,
    Variables,
} from 'graphql-request';
import { formatPage } from './utils/page';
import {
    buildSlugByLocale,
    buildSlugsForPages,
    formatButton,
    getSlug,
} from './utils/link';

import type { HeaderItem } from './types/header';
import type { FooterItem } from './types/footer';

import SiteQuery from './graphql/Site.gql';
import TranslationQuery from './graphql/Translation.gql';

import { flatToNestedObject } from './utils/translation';
import { getTextWithoutAnyWrapper } from './utils/marked';

export default function request({
    query,
    variables = {},
    preview = process.env.PREVIEW_MODE === '1',
}: {
    query: RequestDocument;
    variables?: Variables;
    preview?: boolean;
}) {
    const endpoint = preview
        ? `${process.env.DATOCMS_GRAPHQL_URL}preview`
        : `${process.env.DATOCMS_GRAPHQL_URL}`;

    const client = new GraphQLClient(endpoint, {
        headers: {
            authorization: `Bearer ${process.env.DATOCMS_API_KEY}`,
            'X-Environment': process.env.DATOCMS_ENVIRONMENT ?? 'main',
        },
    });

    return client.request(query, variables);
}

export { gql };

export const getAllPages = async (
    templateKeys = ['page'],
    locales: string[],
) => {
    let count = 0;
    let reachLimit = false;
    let allPages: any[] = [];

    while (!reachLimit) {
        const { pages, meta } = await request({
            query: gql`
                query allPages($templateKeys: [String], $skip: IntType = 0) {
                    pages: allPages(
                        first: 100
                        filter: { templateKey: { in: $templateKeys } }
                        skip: $skip
                    ) {
                        _allSlugLocales {
                            locale
                            value
                        }
                        parent {
                            _allSlugLocales {
                                locale
                                value
                            }
                        }
                        priority
                        changeFrequency
                        updatedAt
                        noindex
                    }
                    meta: _allPagesMeta(
                        filter: { templateKey: { in: $templateKeys } }
                    ) {
                        count
                    }
                }
            `,
            variables: {
                templateKeys,
                skip: 0,
            },
        });

        count += pages.length;
        allPages = allPages.concat(pages);

        if (count >= meta.count) {
            reachLimit = true;
        }
    }

    let formattedPages = allPages
        .map(buildSlugsForPages)
        .flat()
        .filter((page) => locales.includes(page?.locale));

    return {
        pages: formattedPages,
    };
};

export interface SeoPage {
    slug: string;
    lastmod?: string;
    priority: number;
    changefreq: string;
    noIndex: boolean;
}

export const getAllSeoPages = async (
    templateKeys = ['page'],
): Promise<{ pages: SeoPage[] }> => {
    let { pages } = await request({
        query: gql`
            query allPages($templateKeys: [String]) {
                pages: allPages(
                    first: 100
                    filter: { templateKey: { in: $templateKeys } }
                ) {
                    slug
                    parent {
                        slug
                    }
                    priority
                    changeFrequency
                    updatedAt
                    noindex
                }
            }
        `,
        variables: {
            templateKeys,
        },
    });

    pages = pages?.map(
        (page: {
            slug: string;
            parent: {
                slug: string;
            };
            updatedAt: Date;
            priority: string;
            changeFrequency: string | number;
            noindex: boolean;
        }) => {
            return {
                slug: getSlug(page),
                lastmod: page?.updatedAt,
                priority: page.priority,
                changefreq: page?.changeFrequency,
                noIndex: page?.noindex ?? false,
            };
        },
    );

    return {
        pages,
    };
};

export const getPage = async (
    path: string | string[] | undefined,
    locale: string = 'en',
    query: string,
) => {
    const slug =
        Array.isArray(path) && path?.length > 0 ? path[path?.length - 1] : path;

    let { page, ...restQuery } = await request({
        query,
        variables: {
            slug: !slug || slug === '' || slug === '/' ? '_' : slug,
            locale,
        },
    });

    page.alternateLanguages =
        page?._allSlugLocales.map(
            (alternate: { locale: string; value: string }) => {
                let slug =
                    buildSlugByLocale(
                        page,
                        alternate.locale,
                        alternate.value,
                    ) ?? [];

                const href = slug.length > 0 ? `/${slug.join('/')}/` : '/';

                return {
                    href,
                    locale: alternate?.locale,
                    active: alternate?.locale === locale,
                };
            },
        ) ?? [];

    return {
        page: await formatPage(page),
        ...restQuery,
    };
};

export const getSite = async (locale: string = 'en') => {
    const { site, header, footer, ...restQuery } = await request({
        query: SiteQuery,
        variables: {
            locale,
        },
    });

    const getItemsFormatted = (
        data: HeaderItem[] | FooterItem[] = [],
    ): HeaderItem[] | FooterItem[] => {
        return data.map(({ children, ...item }: HeaderItem | FooterItem) => ({
            ...formatButton(item),
            ...(!!(item as HeaderItem).isButton && {
                isButton: (item as HeaderItem).isButton,
            }),
            items:
                children && children.length
                    ? children.map((subitem: any) => formatButton(subitem))
                    : [],
        }));
    };

    return {
        site,
        header: getItemsFormatted(header),
        footer: getItemsFormatted(footer),
        ...restQuery,
    };
};

export const getTranslations = async (locale: string = 'en') => {
    try {
        const { translations, site } = await request({
            query: TranslationQuery as DocumentNode,
            variables: {
                locale,
            },
        });

        const locales = site?.locales ?? [];

        let data: any = {};

        for (const locale of locales) {
            if (!data[locale]) {
                data[locale] = flatToNestedObject(
                    translations.reduce((acc, current) => {
                        if (acc[current.key] === undefined) {
                            const translationsByLocale =
                                current?._allTextLocales ?? [];

                            const itemTranslation = translationsByLocale.find(
                                (textLocale) => textLocale?.locale === locale,
                            );

                            const value = itemTranslation?.value ?? '';

                            acc[current.key] = getTextWithoutAnyWrapper(value);
                        }
                        return acc;
                    }, {}),
                );
            }
        }

        return data;
    } catch (error) {
        console.error(error);
        return {};
    }
};
