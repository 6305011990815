import React from 'react';

import BannerLarge, {
    BannerLargeProps,
} from '@ds/components/shared/Banners/Large';

export interface BannerLargeSectionProps extends BannerLargeProps {}

const BannerLargeSection: React.FC<BannerLargeSectionProps> = ({
    title,
    cta,
    text,
    ...props
}) => {
    return (
        <section {...props}>
            <BannerLarge title={title} cta={cta} text={text} />
        </section>
    );
};

export default BannerLargeSection;
