import React from 'react';

import Section from '@ds/components/shared/Section';
import Summary, { SummaryProps } from '@ds/components/shared/Summary';
import { ButtonProps } from '@ds/components/shared/Button';
export interface SummarySectionProps {
    items: Omit<SummaryProps, 'alignImage'> & {cta: ButtonProps}[];
    anchor?: string;
}

const SummarySection = ({ items, anchor }: SummarySectionProps) => {
    const itemsWithModification = items.map((item) => {
        const { cta, ...props } = item
        if (cta) {
            return {...props, ctas: [item.cta]}
        } else {
            return {...props}
        }
    });

    return (
        <Section anchor={anchor}>
            {itemsWithModification.map((item, index) => {
                return (
                    <Summary
                        key={index}
                        {...item}
                        alignImage={index % 2 !== 0 ? 'start' : 'end'}
                        className={
                            items.length - 1 === index ? '' : 'mb-xxxl'
                        }
                    />
                );
            })}
        </Section>
    );
};

export default SummarySection;
