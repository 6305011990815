import React from 'react';

import CardCheck from '@ds/components/shared/Cards/Check';
import CardPhoto from '@ds/components/shared/Cards/Photo';
import CardTestimonial from '@ds/components/shared/Cards/Testimonial';

export type Component = {
    typename: string;
    id?: string;
    originalId?: string;
    internal?: { type: string };

    [key: string]: any;
};

const Components: { [key: string]: React.ElementType } = {
    card_check: CardCheck,
    card_photo: CardPhoto,
    card_testimonial: CardTestimonial,
    testimonial: CardTestimonial,
};

const DynamicCarouselCard: React.FC<Component> = ({
    typename: __typename,
    id,
    originalId,
    internal = {},
    ...props
}) => {
    const typename = __typename ?? internal?.type ?? '';

    if (typeof Components[typename] !== 'undefined') {
        const Component = Components[typename];

        return (
            <Component {...props} typename={typename} key={id ?? originalId} />
        );
    }

    return (
        <code>
            The component <strong>{typename}</strong> has not been created yet.
        </code>
    );
};

export default DynamicCarouselCard;
