import React from 'react';

import { cls, Image, Icon } from '@solublestudio/soluto-design-system';
import Picture, { PictureProps } from 'Picture';
import styles from './styles.module.scss';
import logo from '~isotipo-inverse';

export interface CardIconProps {
    icon: PictureProps;
    iconName: string;
    title: string;
}

const CardIcon = ({ icon, iconName, title }: CardIconProps) => {
    return (
        <div
            className={cls(
                'bg-base-400 text-base-000 d-flex flex-column align-items-center py-md px-xs text-center',
                styles.wrapper,
            )}>
            {iconName ? (
                <Icon
                    name={iconName}
                    size={40}
                    classes={{
                        icon: cls(styles.icon, 'mb-tiny')
                    }}
                />
            ) : (
                <Picture
                    image={icon.src}
                    layout="fixed"
                    width="40px"
                    height="40px"
                    className={cls('mb-tiny')}
                />
            )}
            {title}
            {logo && (
                <div
                    className={cls(
                        'position-absolute d-flex align-items-center justify-content-center bg-primary-darker',
                        styles.layer,
                    )}>
                    <Image src={logo?.src ? logo.src : logo} />
                </div>
            )}
        </div>
    );
};

export default CardIcon;
