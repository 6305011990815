import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Button, { ButtonProps } from '@ds/components/shared/Button';

import styles from './styles.module.scss';

export interface InfoBoxSectionProps {
    title: string;
    cta?: ButtonProps;
    children: React.ReactNode;
}

const InfoBoxSection = ({ title, cta, children }: InfoBoxSectionProps) => {
    return (
        <div className={cls('bg-base-1000 py-xg px-grid-margin', styles.fullWidth)}>
            <div
                className={cls(
                    'd-flex align-items-center justify-content-between',
                )}>
                <Heading component="h2" font="h100">
                    {title}
                </Heading>
                {cta && <Button {...cta} iconName="edit" variant="secondarySmall" />}
            </div>
            <hr className={cls('my-xg bg-base-400', styles.line)} />
            {children}
        </div>
    );
};

export default InfoBoxSection;
