import React from 'react';

import HeroSection, {
    HeroSectionProps,
} from '@ds/components/organisms/HeroSection';
import { LinkProps } from 'Link';

export interface HeroSearchProps
    extends Pick<
        HeroSectionProps,
        'background' | 'title' | 'text' | 'search' | 'searchPlaceholder'
    > {
        links: LinkProps;
    }

const HeroSearch = ({ links, ...props }: HeroSearchProps) => {
    return <HeroSection {...props} search searchLinks={links} />;
};

export default HeroSearch;
