import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Text from '@ds/components/shared/Text';
import Button, { ButtonProps } from '@ds/components/shared/Button';

import styles from './styles.module.scss';

export interface BannerSmallProps {
    text: string;
    cta: Pick<ButtonProps, 'label'>;
    isContainer?: boolean;
}

const BannerSmall: React.FC<BannerSmallProps> = ({
    text,
    cta,
    isContainer,
}) => {
    return (
        <div
            data-theme="bg-primary-darker"
            className={cls(
                'p-grid-small',
                'd-flex',
                'flex-column',
                'flex-lg-row',
                'justify-content-lg-between',
                'align-items-start',
                'align-items-lg-center',
            )}>
            <Text component="div" font="b300" className={styles.text}>
                {text}
            </Text>
            <Button
                {...cta}
                variant="primarySmall"
                className={cls(
                    'mt-xs',
                    styles.button,
                    styles.wrapperButton,
                )}
            />
        </div>
    );
};

export default BannerSmall;
