import { useMemo, useEffect } from 'react';
import { UserContext, useUser } from 'api-base/auth';
import { LayoutPrivate as DSLayoutPrivate } from '@solublestudio/legalbrave-design-system';

import Menu from '../../Menu';
import { ToastContextProvider } from '../../../base-components/Toast/ToastContext';
import { UserStatus } from 'api-base/lib/types/User';

const LayoutPrivate = ({ children, menu, theme }) => {
    const { user, isLoading }: UserContext = useUser();
    const builtMenu = useMemo(
        () =>
            menu.filter(
                ({ hideFromColectivos }) =>
                    !(user?.profile?.isColectivo && hideFromColectivos),
            ),
        [user],
    );
    const menuComponent = useMemo(
        () =>
            user?.profile?.status === UserStatus.ACTIVE ? (
                <Menu menu={builtMenu} />
            ) : null,
        [user],
    );

    // This is for Colectivos -> set theme values as CSS custom properties in body
    useEffect(() => {
        theme?.colors.map(({ key, value }) => {
            document.documentElement.style.setProperty(`--${key}`, value);
        });
    }, []);

    return (
        <ToastContextProvider>
            <DSLayoutPrivate dataLoading={isLoading} menu={menuComponent}>
                {children}
            </DSLayoutPrivate>
        </ToastContextProvider>
    );
};

export default LayoutPrivate;
