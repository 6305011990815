import React from 'react';

import Section, { SectionProps } from '@ds/components/shared/Section';
import Summary, { SummaryProps } from '@ds/components/shared/Summary';

export interface HeroInnerProps extends Omit<SummaryProps, 'alignImage'> {
    variant?: 'default' | 'intense';
    anchor?: string;
}

const themes = {
    default: 'light',
    intense: 'bg-primary',
};
const variantsButtons = {
    default: ['primaryLarge', 'link'],
    intense: ['secondaryLargeWithOutBorder', 'link',],
};

const HeroInner = ({
    anchor,
    ctas,
    variant = 'default',
    ...props
}: HeroInnerProps) => {
    const ctasWithVariant = ctas.map((button, i) => ({
        ...button,
        variant:
            variantsButtons[variant][i],
    }));
    return (
        <Section
            id="hero-section"
            anchor={anchor}
            data-theme={themes[variant] as SectionProps['dataTheme']}>
            <Summary
                {...props}
                ctas={ctasWithVariant}
                alignImage="start"
                titleFont="h300"
            />
        </Section>
    );
};

export default HeroInner;
