import React from 'react';

import { Container, Icon, cls } from '@solublestudio/soluto-design-system';

import Link from 'Link';
import type { LinkProps } from 'Link';

import Section from '@ds/components/shared/Section';
import Text from '@ds/components/shared/Text';
import TitleSubtitle, { TitleSubtitleProps } from '@ds/components/shared/TitleSubtitle';

import styles from './styles.module.scss';

export interface DocumentsListSectionProps {
    title: TitleSubtitleProps['title'];
    links: LinkProps[];
    anchor?: string;
}

const DocumentsListSection = ({
    title,
    links,
    anchor,
}: DocumentsListSectionProps) => {
    return (
        <Section anchor={anchor}>
            <Container>
                <TitleSubtitle
                    title={title}
                    className={cls('mb-xg')}
                />
                <div
                    className={cls(styles.wrapper)}
                >
                    {links.map((link, i) => {
                        return (
                            <Link
                                key={i}
                                {...link}
                                className={cls(styles.link, 'd-flex', 'align-items-center')}
                                title={link.label}
                            >
                                <Icon
                                    name="check"
                                    size={32}
                                    classes={{
                                        icon: cls(styles.icon, 'mr-tiny'),
                                    }}
                                />
                                <Text
                                    component="div"
                                    font="b200"
                                    className={cls(styles.text, 'text-base-000')}
                                >
                                    {link.label}
                                </Text>
                            </Link>
                        );
                    })}
                </div>
            </Container>
        </Section>
    );
};

export default DocumentsListSection;
