import React from 'react';
import { cls } from '@solublestudio/soluto-design-system';
import styles from './styles.module.scss';

export interface ProgressBarProps {
    total: number;
    current: number;
    label: string;
}

const ProgressBar = ({ total, current, label }: ProgressBarProps) => {
    const fill = (current / total * 100) + '%';
    return (
        <>
            <p id="progressbar-label" className={cls(styles.hide)}>
                {label}
            </p>
            <div
                className={cls(styles.parent)}
                role="meter"
                aria-valuenow={current}
                aria-valuemin="0"
                aria-valuemax={total}
                aria-labelledby="progressbar-label">
                <svg
                    width="100%"
                    height="6px"
                    className={styles.fill}
                    aria-hidden="true"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect
                        x="0"
                        y="0"
                        width={fill}
                        height="100%"
                        fill="currentColor"></rect>
                </svg>
            </div>
        </>
    );
};

export default ProgressBar;
