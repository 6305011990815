import { UserContext } from "@auth0/nextjs-auth0";

export enum UserStatus {
    ACTIVE = 'Activo',
    BLOCKED = 'Mora',
    CANCELLED = 'Cancelado',
    EXPIRED = 'Vencido', // Used in colectivos
}

type User = {
    name?: string;
    lastName?: string;
    email: string;
    phone?: string;
    phone_2?: string;
    address?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    plan: UserPlan;
    planName?: string;
    planId?: string;
    status: UserStatus;
    isColectivo?: boolean;
    clientId?: string;
};

export default User;

export enum UserPlan {
    ESSENTIAL = 'ESSENTIAL',
    PREMIUM = 'PREMIUM',
    ESSENTIAL_SEMESTRAL = 'ESSENTIAL_SEMESTRAL',
    PREMIUM_SEMESTRAL = 'PREMIUM_SEMESTRAL',
    UNIQUE_PLAN = 'UNIQUE_PLAN',
}

export interface UserAuth0 extends UserContext {
    profile: User & {
        accountId: string;
    }
}
