import type {
    NextPage,
    GetStaticPaths,
    GetStaticProps,
    InferGetStaticPropsType,
} from 'next';
import { LayoutCheckout } from '@solublestudio/legalbrave-design-system';

import { ReactNode, useEffect } from 'react';
import CONFIG from 'lib/config/global';
import { resolvers } from '../next-node';

import DynamicSection, { Component } from 'cms-base/components/DynamicSection';

import Header from 'cms-base/components/Header';
import Footer from 'cms-base/components/Footer';
import HeaderSimple from 'cms-base/components/HeaderSimple';
import LayoutPrivate from 'cms-base/components/Layout/Private';

import {
    getAllPages,
    getPage,
    getSite,
    getTranslations,
    Layout,
} from 'cms-base';

import pageQuery from '../graphql/Page.gql';

export const getStaticPaths: GetStaticPaths = async ({ locales = [] }) => {
    const { pages: paths } = await getAllPages(['page', 'checkout'], locales);

    return {
        paths: paths,
        fallback: false,
    };
};

export const getStaticProps: GetStaticProps = async ({
    params,
    locale,
    locales,
}) => {
    let { page } = await getPage(params?.slug, locale, pageQuery);
    const { site, header, footer } = await getSite(locale);
    const translations = await getTranslations(locale);

    if (!page) {
        return {
            notFound: true,
        };
    }

    page = await resolvers(page, locale);

    return {
        props: {
            page,
            site,
            header,
            footer,
            translations,
            locale,
            locales,
        },
    };
};

const Home = ({ page }: InferGetStaticPropsType<typeof getStaticProps>) => {
    const sections = page?.sections;

    useEffect(() => {
        if (!sections?.length || typeof window === 'undefined') {
            return;
        }

        window.document.body.dataset.firstSectionType = sections[0]?.typename;
    }, [sections]);

    return sections?.length > 0
        ? sections.map((section: Component) => (
              <DynamicSection
                  {...section}
                  key={`${section?.typename}_${
                      section?.id ?? section?.originalId
                  }`}
                  globalConfig={CONFIG}
              />
          ))
        : null;
};

export const getLayout = (
    children: ReactNode,
    { page, site, header, footer }: any,
) => {
    const socialLinks =
        footer?.length > 0 && footer[0]?.items?.length
            ? footer[0].items.map((item: any) => ({
                  url: item.href,
                  name: item.label.toLowerCase(),
                  label: item.label,
              }))
            : [];

    const menu = header?.length
        ? header.find((item: any) => item.label === '_menu')?.items
        : [];

    const headerSimple: any = {
        checkout: header?.length
            ? header.find((item: any) => item.label === '_checkout')?.items
            : [],
        private: header?.length
            ? header.find((item: any) => item.label === '_private')?.items
            : [],
    };

    const headerPublic = header?.length
        ? header.filter(
              (item: any) =>
                  !['_checkout', '_private', '_menu'].includes(item?.label),
          )
        : [];

    const HeaderByPage =
        page?.templateKey === 'page' ? (
            <Header
                items={headerPublic}
                social={socialLinks}
                alternateLanguages={page?.alternateLanguages}
                altPhone={page?.phoneInHeader}
            />
        ) : ['checkout', 'private'].includes(page?.templateKey) ? (
            <HeaderSimple
                items={headerSimple[page?.templateKey]}
                menu={page.templateKey === 'private' ? menu : undefined}
                alternateLanguages={page?.alternateLanguages}
                altPhone={page?.phoneInHeader}
            />
        ) : null;

    const FooterByPage =
        page?.templateKey === 'page' ? (
            <Footer
                items={footer}
                social={socialLinks}
                theme="bg-dark"
                alternateLanguages={page?.alternateLanguages}
            />
        ) : ['checkout'].includes(page?.templateKey) ? (
            <Footer
                social={socialLinks}
                withCancelText={true}
                theme="bg-dark"
                alternateLanguages={page?.alternateLanguages}
            />
        ) : ['private'].includes(page?.templateKey) ? (
            <Footer
                social={socialLinks}
                theme="bg-dark"
                alternateLanguages={page?.alternateLanguages}
            />
        ) : null;

    const getLayoutContent = (children: ReactNode) =>
        page?.templateKey === 'page' ? (
            children
        ) : page?.templateKey === 'checkout' ? (
            <LayoutCheckout>{children}</LayoutCheckout>
        ) : (
            <LayoutPrivate menu={menu}>{children}</LayoutPrivate>
        );

    return (
        <Layout
            page={page}
            site={site}
            header={HeaderByPage}
            footer={FooterByPage}
            globalConfig={CONFIG}>
            {getLayoutContent(children)}
        </Layout>
    );
};

Home.getLayout = getLayout;

export default Home;
