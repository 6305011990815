import React from 'react';
import { Alert as AlertSoluto } from '@solublestudio/soluto-design-system';
import styles from './styles.module.scss';
import Text from '../Text';

type ToastItem = {
    type: 'error' | 'success';
    message: string;
};

interface ToastStackProps {
    items: ToastItem[];
}

const ToastStack = ({ items }: ToastStackProps) => {
    return (
        <div className={styles.wrapper}>
            {items.map(({ type, message }: ToastItem, i) => (
                <AlertSoluto
                    key={`alert-${i}`}
                    color={type}
                    className={styles.alert}
                    mt="tiny"
                    children={
                        <Text font="b100" component="p" className={styles.linkUnderline}>
                            {message}
                        </Text>
                    }
                />
            ))}
        </div>
    );
};

export default ToastStack;
