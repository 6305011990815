import React from 'react';

import {
    Container,
    Row,
    Col,
    cls,
    Accordion,
    AccordionItemProps,
    useIsMobile,
} from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';
import Heading from '@ds/components/shared/Heading';
import Picture from 'Picture';
import styles from './styles.module.scss';
import Button, { ButtonProps } from '@ds/components/shared/Button';

export interface DocumentsSectionProps {
    title: string;
    items: AccordionItemProps[];
    anchor?: string;
    image: string;
    cta?: Omit<ButtonProps, 'variant'>;
    featured?: boolean;
}

export default function DocumentsSection({
    title,
    items,
    anchor,
    image,
    cta,
    featured = false,
}: DocumentsSectionProps) {
    const isMobile = useIsMobile();
    const showImage = image && featured ? !isMobile : true;
    return (
        <Section anchor={anchor}>
            <Container>
                <Row className="align-items-flex-start">
                    <Col col={{ xs: 12 }}>
                        <Heading
                            component="h2"
                            font={featured ? 'h300' : 'h200'}
                            className={cls(
                                'mb-xg',
                                !featured && styles.titleMobile,
                            )}>
                            {title}
                        </Heading>
                    </Col>
                    {showImage && (
                        <Col
                            col={{ xs: 12, lg: 6 }}
                            order={{ xs: 1, lg: 2 }}
                            className={styles.imageWrapper}>
                            <div className={cls(styles.imageContainer)}>
                                <Picture
                                    image={image}
                                    alt={title}
                                    layout="full"
                                    objectFit="cover"
                                />
                            </div>
                        </Col>
                    )}
                    <Col col={{ xs: 12, lg: image ? 6 : 12 }}>
                        {!featured && (
                            <Heading
                                component="h2"
                                font="h200"
                                className={cls(
                                    'mb-xg mt-xl',
                                    styles.titleDesktop,
                                )}>
                                {title}
                            </Heading>
                        )}
                        <Accordion
                            initialShow={-1}
                            items={items?.length ? items : []}
                            classes={{
                                wrapper: cls(
                                    styles.accordion,
                                    featured && styles.featured,
                                ),
                                item: cls(styles.item),
                                openItem: cls(styles.open),
                                title: cls(
                                    'font-weight-semi-bold text-base-000',
                                    featured ? 'b400' : 'b300',
                                    styles.itemTitle,
                                ),
                                body: cls(
                                    'b300',
                                    featured ? 'pb-lg pt-lg' : 'pb-md',
                                    styles.body,
                                ),
                            }}
                        />
                        {cta && (
                            <div
                                className={cls(
                                    'd-flex justify-content-end mt-huge',
                                )}>
                                <Button {...cta} variant="primaryLarge" />
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}
