import React from 'react';

export default function Header() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '150px',
                backgroundColor: '#ccc',
                fontSize: '1rem',
            }}>
            HEADER
        </div>
    );
}
