import React, { useCallback } from 'react';

import {
    Row,
    Col,
    cls,
    Container,
    animateScroll,
    useIsMobile,
} from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';
import Button, { ButtonProps } from '@ds/components/shared/Button';
import MediaBackgroundComponent from '@ds/components/shared/MediaBackground';
import Text from '@ds/components/shared/Text';
import Heading from '@ds/components/shared/Heading';
import VideoButton, { VideoButtonProps } from './VideoButton';
import Link from 'Link';
import Picture from 'Picture';
import type { LinkProps } from 'Link';
import styles from './styles.module.scss';
import InputSearch from '@ds/components/shared/InputSearch';
import ReviewBlock, {
    ReviewBlockProps,
} from '@ds/components/shared/ReviewBlock';

export interface HeroSectionProps {
    subtitle?: string;
    title: string;
    titleMobile: string;
    linkSubtitle?: LinkProps;
    background: any;
    mobileImage: any;
    text?: string;
    ctas?: ButtonProps[];
    ctaVideo?: ButtonProps;
    ctasMobile?: ButtonProps[];
    fullscreen?: boolean;
    className?: string;
    ctaClassName?: string;
    video?: VideoButtonProps['video'];
    search?: boolean;
    searchPlaceholder?: string;
    searchLinks: LinkProps[];
    searchError?: string;
    block?: ReviewBlockProps[];
}

export function scrollToNextItem() {
    if (typeof window === 'undefined') {
        return;
    }

    const node = document.getElementById('hero-section');

    if (node) {
        const { bottom } = node.getBoundingClientRect();
        const offset = window.pageYOffset;

        animateScroll(bottom, offset, 0.2);
    }
}

const HeroSection = ({
    subtitle,
    title,
    titleMobile,
    background,
    mobileImage,
    text,
    ctas,
    ctasMobile,
    ctaVideo,
    className,
    ctaClassName,
    fullscreen,
    video,
    linkSubtitle,
    search,
    searchPlaceholder,
    searchLinks,
    searchError,
    block,
}: HeroSectionProps) => {
    const isMobile = useIsMobile();

    const getVariant = useCallback((cta: ButtonProps) => {
        return cta?.detached ? 'secondaryLarge' : 'primaryLarge';
    }, []);
    return (
        <Section
            id="hero-section"
            className={cls(
                styles.heroSection,
                fullscreen && styles.fullscreen,
                search && styles.heroSearch,
                'position-relative',
                'd-flex',
                'flex-column',
                'justify-content-center',
                className,
            )}>
            {(!isMobile || search) && (
                <MediaBackgroundComponent
                    background={background}
                    bgCover={true}
                    withDelay={false}
                />
            )}
            <Container
                className={`${styles.heroContainer} d-flex flex-column position-relative`}>
                <Row>
                    <Col
                        col={{ lg: search ? 6 : 7 }}
                        className={cls(
                            'd-flex',
                            'flex-column',
                            'justify-content-center',
                            'align-items-start',
                            !search && isMobile
                                ? 'text-base-000'
                                : 'text-base-1000',
                        )}>
                        {linkSubtitle && subtitle && (
                            <Link
                                {...linkSubtitle}
                                className={cls(
                                    styles.link,
                                    'd-none d-lg-block',
                                )}>
                                <Heading
                                    data-sal="slide-up"
                                    data-sal-delay="800"
                                    component="h2"
                                    font="h100"
                                    className={cls(
                                        'text-break',
                                        'mb-sm',
                                        isMobile
                                            ? 'text-primary-dark'
                                            : 'text-primary',
                                    )}>
                                    {subtitle}
                                </Heading>
                            </Link>
                        )}
                        {!linkSubtitle && subtitle && (
                            <Heading
                                data-sal="slide-up"
                                data-sal-delay="800"
                                component="h2"
                                font="h100"
                                className={cls(
                                    'text-break',
                                    'mb-sm',
                                    isMobile
                                        ? 'text-primary-dark'
                                        : 'text-primary',
                                )}>
                                {subtitle}
                            </Heading>
                        )}
                        <Heading
                            data-sal="slide-up"
                            data-sal-delay="1000"
                            component="h1"
                            font="h300"
                            className={cls('text-break', 'mb-sm')}>
                            {title}
                        </Heading>
                        {text && (
                            <Text
                                component="div"
                                font={isMobile ? 'b200' : 'b300'}
                                data-sal="slide-up"
                                data-sal-delay="1200"
                                className={cls(search ? 'mb-lg' : 'mb-sm')}>
                                {text}
                            </Text>
                        )}
                        {ctasMobile && (
                            <div
                                className={cls(
                                    'd-flex',
                                    'd-lg-none',
                                    'align-items-start',
                                    'justify-content-center',
                                    styles.ctaMobile,
                                )}>
                                {ctasMobile.map((cta, index) => {
                                    return (
                                        <Button
                                            {...cta}
                                            key={index}
                                            variant={getVariant(cta)}
                                            className={cls(styles.ctasButtons)}
                                        />
                                    );
                                })}
                            </div>
                        )}
                        {ctas && (
                            <div
                                className={cls(
                                    'd-none',
                                    'd-lg-flex',
                                    'align-items-start',
                                )}>
                                {ctas.map((cta, index) => {
                                    return (
                                        <Button
                                            {...cta}
                                            key={index}
                                            variant={getVariant(cta)}
                                            className={cls(styles.ctasButtons)}
                                        />
                                    );
                                })}
                            </div>
                        )}
                        {search && (
                            <InputSearch
                                links={searchLinks}
                                placeholder={
                                    searchPlaceholder || 'Search forms'
                                }
                                errorMessage={searchError || ''}
                            />
                        )}
                    </Col>
                    {block && !isMobile && (
                        <Col
                            col={{ lg: 5 }}
                            className={cls(
                                'd-flex justify-content-end align-items-end',
                            )}>
                            {block.map((review, i) => (
                                <ReviewBlock key={`stamp-${i}`} {...review} />
                            ))}
                        </Col>
                    )}
                </Row>
                {ctaVideo && video && (
                    <Row className={cls('mt-md', ctaClassName)}>
                        <Col
                            data-sal="slide-down"
                            data-sal-delay="2000"
                            data-sal-duration="200">
                            <VideoButton
                                button={ctaVideo}
                                video={video}
                                variant={
                                    !isMobile ? 'buttonIconLight' : undefined
                                }
                            />
                        </Col>
                    </Row>
                )}
            </Container>
            {isMobile && (
                <>
                    <div
                        className={cls(
                            'bg-primary-dark',
                            'text-base-1000',
                            'py-sm px-md mt-md',
                            'd-block',
                            'd-lg-none',
                            styles.plan,
                        )}>
                        <Heading font="h100" component="h3">
                            {titleMobile}
                        </Heading>
                    </div>
                    <div className={cls('position-relative', 'd-lg-none')}>
                        {mobileImage && (
                            <Picture
                                classes={{
                                    wrapper: styles.videoImage,
                                }}
                                image={mobileImage.responsiveImage}
                                layout="full"
                                height={241}
                                width={375}
                                objectFit="cover"
                            />
                        )}
                    </div>
                    <div className={styles.wrapperReview}>
                        <Container>
                            {block &&
                                isMobile &&
                                block.map((review, i) => (
                                    <ReviewBlock
                                        key={`stamp-${i}`}
                                        {...review}
                                    />
                                ))}
                        </Container>
                    </div>
                </>
            )}
        </Section>
    );
};

export default HeroSection;
