import React from 'react';

import { Container, Row, Col } from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';
import Heading from '@ds/components/shared/Heading';
import FaqsComponent, { FaqComponentProps } from '@ds/components/shared/Faqs';

export interface FaqsSectionProps {
    title: string;
    faqs: FaqComponentProps[];
    anchor?: string;
}

export default function FaqsSection({ title, faqs, anchor }: FaqsSectionProps) {
    return (
        <Section anchor={anchor}>
            <Container>
                <Row>
                    <Col col={{ xs: 12 }}>
                        <Heading component="h2" font="h300" className="mb-xg">
                            {title}
                        </Heading>
                    </Col>
                    <Col col={{ xs: 12, lg: 8 }} offset={{ lg: 2 }}>
                        <FaqsComponent items={faqs} />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}
