import React from 'react';

import {
    Container,
    Row,
    Col,
    cls,
    Image,
} from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';
import TitleSubtitle from '@ds/components/shared/TitleSubtitle';

import logo from '~logo-white';

import ContactForm, {
    ContactFormProps,
} from '@ds/components/shared/Forms/ContactForm';

import styles from './styles.module.scss';

interface ContactFormSectionProps {
    title: string;
    anchor?: string;
    contactForm: ContactFormProps;
}

const ContactFormSection = ({
    title,
    anchor,
    contactForm
}: ContactFormSectionProps) => {
    return (
        <Section anchor={anchor}>
            <Container>
                <div
                    data-theme="bg-primary-darker"
                    className={cls(
                        styles.contactFormContainer,
                        'p-grid-margin',
                    )}>
                    <Row>
                        <Col
                            col={{ lg: 6, xxl: 7 }}
                            className={cls(
                                'd-flex flex-column justify-content-between',
                            )}>
                            <TitleSubtitle
                                title={title}
                                className={cls('mb-xl')}
                            />
                            <Image
                                src={logo?.src ? logo.src : logo}
                                className={cls(
                                    styles.logo,
                                    'd-none d-lg-block',
                                )}
                            />
                        </Col>
                        <Col col={{ lg: 6, xxl: 5 }}>
                            <ContactForm {...contactForm} />
                        </Col>
                    </Row>
                </div>
            </Container>
        </Section>
    );
};

export default ContactFormSection;
