import React from 'react';

import { Container, Row, Col, cls } from '@solublestudio/soluto-design-system';

import Picture, { PictureProps } from 'Picture';

import Section from '@ds/components/shared/Section';
import TitleSubtitle, {
    TitleSubtitleProps,
} from '@ds/components/shared/TitleSubtitle';
import ProcessItem, { ProcessItemProps } from './Item';

import styles from './styles.module.scss';

export interface ProcessSectionProps extends Pick<TitleSubtitleProps, 'title' | 'subtitle'> {
    items: Omit<ProcessItemProps, 'indexNumber'>[];
    image: PictureProps;
    anchor?: string;
}

const ProcessSection = ({
    items,
    image,
    anchor,
    ...props
}: ProcessSectionProps) => {
    return (
        <Section anchor={anchor}>
            <Container>
                <TitleSubtitle
                    {...props}
                    subtitleFont="b300"
                    className={cls('mb-xg')}
                />
                <Row className={cls('align-items-lg-start')}>
                    <Col col={{ lg: 6, xl: 5 }}>
                        {items && (
                            items.map((item, i) => {
                                const formatedIndex = ("" + (i + 1)).padStart(2, "0");
                                return (
                                    <ProcessItem
                                        key={i}
                                        {...item}
                                        indexNumber={formatedIndex}
                                        className={cls(i !== 0 && 'mt-xg')}
                                    />
                                );
                            })
                        )}
                    </Col>
                    <Col col={{ lg: 6 }} offset={{ xl: 1 }}>
                        <Picture
                            image={image}
                            layout="full"
                            objectFit="cover"
                            classes={{
                                wrapper: cls(styles.imgWrapper, 'mt-xg'),
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default ProcessSection;
