import React, { useRef, useEffect, useState } from 'react';
import {
    Input,
    cls,
    Icon,
    Modal as ModalSoluto,
    ModalRef,
    ModalProps,
} from '@solublestudio/soluto-design-system';
import Text from '@ds/components/shared/Text';
import copy from 'copy-to-clipboard';
import style from './styles.module.scss';
import Button from '@ds/components/shared/Button';

export type { ModalRef };
export interface PopupSectionProps extends ModalProps {
    subtitle: string;
    title: string;
    text: string;
    labelCode: string;
    button: string;
    buttonText: string;
    tooltip: string;
    timeDelay: number;
    discountCode: string;
    showCode: boolean;
    onSubmit: (e: any) => Promise<string>;
    onClose: () => void;
}

const PopupSection = ({
    subtitle,
    title,
    text,
    labelCode,
    button,
    buttonText,
    tooltip,
    timeDelay,
    discountCode,
    showCode,
    onSubmit,
    onClose,
}: PopupSectionProps) => {
    const [visible, setVisible] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [message, setMessage] = useState('');

    const modalRef = useRef<ModalRef>(null);
    const emailRef = useRef<HTMLInputElement>();
    const delay = timeDelay * 1000;

    useEffect(() => {
        const timeout = setTimeout(() => {
            modalRef?.current?.toggle();
        }, delay);
        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);

    const handleSubmit = async (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        setSubmitting(true);
        try {
            const msg = await onSubmit(emailRef.current?.value);
            setMessage(msg);
            setSubmitting(false);
        } catch (error: stirng) {
            setMessage(error);
            setSubmitting(false);
        }
    };

    const closePopup = () => {
        modalRef?.current?.toggle();
        onClose && onClose();
    };

    const copyClipboard = () => {
        copy(discountCode);
        setVisible(true);
        setTimeout(function () {
            setVisible(false);
        }, 3000);
    };

    return (
        <ModalSoluto
            ref={modalRef}
            disableBackgroundClose={true}
            disabledScrollBlock={true}
            size={'md'}
            header={<span></span>}
            classes={{
                body: cls(style.body),
                content: cls(style.content),
                closeButton: cls('d-none'),
            }}>
            <div className={cls(style.modal, 'pt-md pb-xg px-xl text-center')}>
                <div className={cls(style.wrapperIcon, 'text-right')}>
                    <Button
                        component="button"
                        variant="buttonIconSingle"
                        type="button"
                        iconName="close"
                        onClick={() => closePopup()}
                    />
                </div>
                <Text
                    component="p"
                    font="h100"
                    className={cls('font-weight-semi-bold')}>
                    {subtitle}
                </Text>
                <Text
                    component="p"
                    font="h300"
                    className={cls('font-weight-semi-bold')}>
                    {title}
                </Text>
                <Text component="p" font="b300" className={cls('mt-xs')}>
                    {text}
                </Text>
                {showCode ? (
                    <div className={cls('text-left mt-md')}>
                        <label htmlFor="promocode" className={style.label}>
                            <strong>{labelCode}</strong>
                        </label>
                        <div className={cls('position-relative')}>
                            <Input
                                id="promocode"
                                type="text"
                                name="code"
                                value={discountCode}
                                readOnly
                                size="xl"
                                classes={{
                                    wrapper: cls('mb-xs'),
                                    element: cls(style.code),
                                }}
                                onClick={copyClipboard}
                            />
                            <div
                                className={cls(
                                    style.wrapperCopy,
                                    'position-absolute',
                                )}>
                                {visible && (
                                    <Text
                                        font="b200"
                                        className={cls(
                                            style.tooltip,
                                            'position-relative',
                                        )}>
                                        {tooltip}
                                    </Text>
                                )}
                                <Icon name="copy" size={24} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <form
                            action=""
                            className={cls('my-md')}
                            onSubmit={(e) => handleSubmit(e)}>
                            {submitting ? (
                                <Icon
                                    name="loading"
                                    className={cls('mb-lg', style.loading)}
                                />
                            ) : (
                                <Input
                                    ref={emailRef}
                                    name="email"
                                    type="email"
                                    placeholder={'Email'}
                                    required
                                    size="xl"
                                    classes={{
                                        wrapper: cls('mb-xs'),
                                    }}
                                    {...(!!message && { error: message })}
                                />
                            )}
                            <Button
                                component="button"
                                type="submit"
                                label={button}
                                disabled={submitting}
                                variant="primaryLarge"
                                className={cls(style.button)}
                            />
                        </form>
                        <Button
                            component="button"
                            type="button"
                            label={buttonText}
                            variant="linkSmall"
                            onClick={() => closePopup()}
                            className={cls(style.buttonLink)}
                        />
                    </>
                )}
            </div>
        </ModalSoluto>
    );
};

export default PopupSection;
