import React from 'react';
import { cls, Icon, useIsMobile } from '@solublestudio/soluto-design-system';
import styles from './styles.module.scss';
import Picture, { PictureProps } from 'Picture';
import Button, { ButtonProps } from '@ds/components/shared/Button';
import Text from '@ds/components/shared/Text';
import RatingSection from '@ds/components/organisms/Rating';

export interface ReviewBlockProps {
    image?: PictureProps;
    title?: string;
    ratingTitle?: string;
    rating?: number;
    cta?: ButtonProps;
}

const ReviewBlock = ({
    image,
    title,
    cta,
    ratingTitle,
    rating,
}: ReviewBlockProps) => {
    const isMobile = useIsMobile();
    return (
        <div className={cls(isMobile && 'd-flex py-md')}>
            {image && (
                <Picture
                    classes={{ wrapper: styles.image }}
                    image={image}
                    alt={title}
                />
            )}
            <div className={cls(isMobile ? 'base-000' : 'text-base-1000')}>
                {title && (
                    <Text component="p" font="b100 font-weight-semi-bold">
                        {title}
                    </Text>
                )}
                {cta && (
                    <Button
                        {...cta}
                        className={cls(
                            'mb-sm',
                            isMobile ? 'base-000' : 'text-base-1000',
                            styles.underline,
                        )}
                        variant="linkSmall"
                    />
                )}
                {ratingTitle && (
                    <Text component="p" font="b100">
                        {ratingTitle}
                    </Text>
                )}
                {rating && (
                    <div className={cls('d-flex align-items-center')}>
                        <RatingSection rating={rating} bgColor="transparent" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReviewBlock;
