import React from 'react';

import {
    Container,
    cls,
    Text,
    useIsMobile,
} from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

import Picture, { PictureProps } from 'Picture';
import Rating from '../../Rating';

export interface TestimonialItemProps {
    title?: string;
    name: string;
    testimonial: string;
    origin: string;
    rating?: number;
    image: PictureProps;
}

const TestimonialItem = ({
    title,
    name,
    testimonial,
    origin,
    rating,
    image,
}: TestimonialItemProps) => {
    const isMobile = useIsMobile();

    return (
        <Container>
            <div className={cls(styles.wrapperContent)}>
                {image && (
                    <div className={cls(styles.wrapperImage)}>
                        <Picture
                            layout="full"
                            {...image.responsiveImage}
                            classes={{
                                wrapper: cls(styles.wrapper),
                                picture: cls(styles.cover),
                            }}
                        />
                    </div>
                )}
                <div
                    className={cls(
                        styles.wrapperInfo,
                        'p-grid-padding text-base-1000 bg-primary-dark',
                    )}>
                    <div className={cls(isMobile && 'mb-xxl')}>
                        {title && (
                            <Text
                                component="p"
                                className={cls(
                                    isMobile ? 'b100' : 'b300',
                                    'mb-xs',
                                )}>
                                {title}
                            </Text>
                        )}
                        <Text
                            component="div"
                            className={cls(
                                isMobile ? 'b100' : 'b400',
                                'font-weight-semi-bold mb-xxl',
                            )}>
                            {testimonial}
                        </Text>
                    </div>
                    <div>
                        {name && (
                            <Text
                                component="p"
                                className={cls(
                                    isMobile ? 'b100' : 'b300',
                                    'font-weight-semi-bold',
                                )}>
                                {name}
                            </Text>
                        )}
                        {origin && (
                            <Text
                                component="p"
                                className={cls(
                                    isMobile ? 'b100' : 'b200',
                                    'mb-tiny',
                                )}>
                                {origin}
                            </Text>
                        )}
                        {rating && <Rating rating={rating} />}
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default TestimonialItem;
