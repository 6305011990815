const CONSTANTS = {
    genericMessage: 'This field is required.',
    mailError: 'Please, enter a valid email.',
    phoneWrong: 'Please, enter a valid phone number.',
    checkbox: 'Please, check this field if you want to proceed.'
}

const validateText = (value:any) => {
    const response = {
        error: false,
        message: ''
    }
    if(!value || value.length > 100) {
        response.error = true;
        response.message = CONSTANTS.genericMessage
    }
    return response;
}

const validateEmail = (value: any) => {
    const response = {
        error: false,
        message: ''
    }
    if(!value){
        response.error = true;
        response.message = CONSTANTS.genericMessage;
    } else if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        response.error = true;
        response.message = CONSTANTS.mailError;
    }
    
    return response;

}

const validatePhone = (value: any, required:any) => {
    const response = {
        error: false,
        message: ''
    }

    const isInvalid = !/^[2-9]\d{2}[2-9]\d{2}\d{4}$/.test(value);

    if(required) {
        if(value.length !== 10 || isInvalid) {
            response.error = true;
            response.message = value.length === 10 ? CONSTANTS.genericMessage : CONSTANTS.phoneWrong
        }
    } else if (value.length !== 0 ) {
        if(isInvalid) {
            response.error = true;
            response.message = CONSTANTS.phoneWrong
        }
    }

    return response;
}

const validatePostalCode = (value: any) => {
    const response = {
        error: false,
        message: ''
    }
    if(!value || value.length != 5) {
        response.error = true;
        response.message = CONSTANTS.genericMessage
    }
    return response;
}

const validateCheckout = (value: any) => {
    const response = {
        error: false,
        message: ''
    }

    if(!value) {
        response.error = true;
        response.message = CONSTANTS.checkbox
    }
    return response;
}

const validateForm = {
    formCreateUser: (fieldError:any) => {

        let isValid = true;

        const newFields = JSON.parse(JSON.stringify(fieldError));

        for (let index = 0; index < fieldError.length; index++) {
            const { type, required, value } = fieldError[index];

            switch (type) {
                case 'text':
                    newFields[index].error = validateText(value);
                    if(newFields[index].error.error) isValid = false;
                    break;
                case 'mail':
                    newFields[index].error = validateEmail(value);
                    if(newFields[index].error.error) isValid = false;
                    break;
                case 'phone':
                    newFields[index].error = validatePhone(value, required);
                    if(newFields[index].error.error) isValid = false;
                    break;
                case 'postalCode':
                    newFields[index].error = validatePostalCode(value);
                    if(newFields[index].error.error) isValid = false;
                    break;
                case 'checkbox':
                    newFields[index].error = validateCheckout(value);
                    if(newFields[index].error.error) isValid = false;
                    break;
                default:
                    break;
            }
        }

        return { newFields, isValid };
    }
}

export default validateForm;