import React, { useEffect, useState } from 'react';

import states from '../BaseForm/statesUS';
import countries from '../BaseForm/countryCodes';

import validateForm from '../BaseForm/ValidatorForm';

import styles from './../BaseForm/styles.module.scss';

export interface BaseFormProps {
    className?: string;
    formId: string;
}

interface PersonalInformationFormProps {
    hiddenFields: any;
    onSubmit: (newData, data) => Promise<void>;
}
const PersonalInformationForm = ({
    hiddenFields,
    onSubmit,
}: PersonalInformationFormProps) => {
    const getData = (key: string) => {
        if (hiddenFields) {
            const hasValue = hiddenFields.find(
                (field: any) => field.key === key,
            );
            return hasValue ? hasValue.value : '';
        } else {
            return '';
        }
    };

    const initSelectModals = {
        country_2: false,
        country: false,
        state: false,
    };

    const [selectsModals, setSelectsModals] = useState({ ...initSelectModals });

    const [loading, setLoading] = useState(false);

    const [fieldsError, setFieldsError] = useState([
        {
            fieldName: 'name',
            type: 'text',
            required: true,
            options: { min: 1, max: 100 },
            value: getData('name'),
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'lastName',
            type: 'text',
            required: true,
            options: { min: 1, max: 100 },
            value: getData('lastName'),
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'phone',
            type: 'phone',
            required: true,
            options: { digit: 10, countrySupport: ['US'] },
            value: getData('phone').replace('+1', ''),
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'phone_2',
            type: 'phone',
            required: false,
            options: { digit: 10, countrySupport: ['US'] },
            value: getData('phone_2').replace('+1', ''),
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'address',
            type: 'text',
            required: true,
            options: { min: 1, max: 100 },
            value: getData('address'),
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'city',
            type: 'text',
            required: true,
            options: { min: 1, max: 100 },
            value: getData('city'),
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'state',
            type: 'text',
            required: true,
            options: { min: 1, max: 100 },
            value: getData('state'),
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'zipcode',
            type: 'postalCode',
            required: true,
            options: { digit: 5 },
            value: getData('zipcode'),
            error: {
                error: false,
                message: 'error',
            },
        },
    ]);

    const [form] = useState({
        name: getData('name'),
        lastName: getData('lastName'),
        phone: getData('phone').replace('+1', ''),
        initialPhone: getData('phone').replace('+1', ''),
        phone_2: getData('phone_2').replace('+1', ''),
        address: getData('address'),
        city: getData('city'),
        state: getData('state'),
        zipcode: getData('zipcode'),
    });

    const [errorGlobal, setErrorGlobal] = useState({
        error: false,
        message: '',
    });

    const country = countries.findIndex((c) => c.code == 'US');

    const [countrySetter, setCountrySetter] = useState(country);
    const [countriesShow, setCountriesShow] = useState(countries);

    useEffect(() => {
        const updateModals = () => {
            setSelectsModals({
                country_2: false,
                country: false,
                state: false,
            });
        };
        document.addEventListener('click', (e) => {
            updateModals();
        });

        return function cleanup() {
            document.removeEventListener('click', (e) => {
                updateModals();
            });
        };
    }, []);

    const thisOnSubmit = async (ev) => {
        ev?.preventDefault();

        setLoading(true);
        const { newFields, isValid } = validateForm.formCreateUser(fieldsError);

        setFieldsError(newFields);

        if (!isValid) {
            setLoading(false);
            return;
        }

        try {
            await onSubmit(newFields, form);
        } catch (error) {
            setErrorGlobal({
                error: true,
                message: error?.message ?? 'User Already Exist',
            });
            setLoading(false);
        }
    };

    const getValueDefault = (name: any) => {
        const input = fieldsError.find((filed) => filed.fieldName === name);
        return input?.value;
    };

    const getFromError = (name: any) => {
        const input = fieldsError.find((filed) => filed.fieldName === name);
        return input?.error;
    };

    const cleanError = (nameImput: any, e: any) => {
        const index = fieldsError.findIndex(
            (filed) => filed.fieldName === nameImput,
        );

        fieldsError[index].value = e.target.value;
        fieldsError[index].error = { error: false, message: '' };

        setErrorGlobal({
            error: false,
            message: '',
        });

        setFieldsError(fieldsError);
    };

    const changeCountriesList = (value: any) => {
        setCountriesShow(
            countries.filter((c) => c.name.search(new RegExp(value, 'i')) > -1),
        );
    };

    const closeSelects = () => {
        setSelectsModals({
            country_2: false,
            country: false,
            state: false,
        });
    };

    const openModal = (e: any, key: String) => {
        e.stopPropagation();

        const newModalsState = { ...initSelectModals, [`${key}`]: true };
        setSelectsModals(newModalsState);
    };

    const changeCountry = (code: any) => {
        setCountrySetter(countries.findIndex((c) => c.code === code));
        setCountriesShow(countries);
        closeSelects();
    };

    const changeState = (e: any, i: any) => {
        e.stopPropagation();
        closeSelects();
        const index = fieldsError.findIndex(
            (field) => field.fieldName === 'state',
        );
        fieldsError[index].value = states[i].name;
        setFieldsError(fieldsError);
    };

    return (
        <form onSubmit={thisOnSubmit}>
            <div className={styles['lb-form-row']}>
                <div className={styles['lb-form-record']}>
                    <label className={styles['lb-form-label-editor']}>
                        Name*
                    </label>
                    <input
                        className={
                            styles[
                                `lb-input${
                                    getFromError('name')?.error
                                        ? '-error-border'
                                        : ''
                                }`
                            ]
                        }
                        onChange={(e) => cleanError('name', e)}
                        defaultValue={getValueDefault('name')}
                        type="text"
                        name="name"
                        placeholder="First Name"
                    />
                    {getFromError('name')?.error && (
                        <p className={styles['lb-input-error']}>
                            {getFromError('name')?.message}
                        </p>
                    )}
                </div>
                <div className={styles['lb-form-record']}>
                    <label className={styles['lb-form-label-editor']}>
                        Last Name*
                    </label>
                    <input
                        className={
                            styles[
                                `lb-input${
                                    getFromError('lastName')?.error
                                        ? '-error-border'
                                        : ''
                                }`
                            ]
                        }
                        onChange={(e) => cleanError('lastName', e)}
                        defaultValue={getValueDefault('lastName')}
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                    />
                    {getFromError('lastName')?.error && (
                        <p className={styles['lb-input-error']}>
                            {getFromError('lastName')?.message}
                        </p>
                    )}
                </div>
            </div>
            <div className={styles['lb-form-row']}>
                <div className={styles['lb-form-record']}>
                    <label className={styles['lb-form-label-editor']}>
                        Telephone 1*
                    </label>
                    {selectsModals.country && (
                        <div className={styles['lb-input-select-options']}>
                            <div
                                className={
                                    styles['lb-input-select-options-search']
                                }>
                                <input
                                    onClick={(e) => {
                                        openModal(e, 'country');
                                    }}
                                    onChange={(e) =>
                                        changeCountriesList(e.target.value)
                                    }
                                    placeholder="Type name of country"
                                    className={
                                        styles['lb-input-select-options-input']
                                    }
                                    type="text"
                                />
                            </div>
                            {countriesShow.map(function (object, i) {
                                return (
                                    <div
                                        key={i}
                                        onClick={() =>
                                            changeCountry(object.code)
                                        }
                                        className={
                                            styles[
                                                'lb-input-select-options-row'
                                            ]
                                        }>
                                        <p>
                                            {object.emoji}{' '}
                                            <span>{object.name}</span>
                                        </p>
                                        <p>{object.dial_code}</p>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div
                        onClick={(e) => openModal(e, 'country')}
                        className={
                            styles[
                                `lb-input-select-box${
                                    getFromError('phone')?.error ? '-error' : ''
                                }`
                            ]
                        }>
                        <div
                            className={
                                styles['lb-input-select-container-flags']
                            }>
                            <p>{countries[countrySetter].emoji}</p>
                            <p>{countries[countrySetter].dial_code}</p>
                            <div
                                className={
                                    styles[
                                        'lb-input-select-container-flags-icon'
                                    ]
                                }></div>
                        </div>
                    </div>
                    <input
                        className={
                            styles[
                                `lb-input-select${
                                    getFromError('phone')?.error ? '-error' : ''
                                }`
                            ]
                        }
                        onChange={(e) => cleanError('phone', e)}
                        defaultValue={getValueDefault('phone')}
                        type="tel"
                        name="phone"
                        placeholder="(000) 000-0000"
                        autoComplete="tel"
                    />
                    {getFromError('phone')?.error && (
                        <p className={styles['lb-input-error']}>
                            {getFromError('phone')?.message}
                        </p>
                    )}
                </div>
                <div className={styles['lb-form-record']}>
                    <label className={styles['lb-form-label-editor']}>
                        Telephone 2
                    </label>
                    {selectsModals.country_2 && (
                        <div className={styles['lb-input-select-options']}>
                            <div
                                className={
                                    styles['lb-input-select-options-search']
                                }>
                                <input
                                    onClick={(e) => {
                                        openModal(e, 'country_2');
                                    }}
                                    onChange={(e) =>
                                        changeCountriesList(e.target.value)
                                    }
                                    placeholder="Type name of country"
                                    className={
                                        styles['lb-input-select-options-input']
                                    }
                                    type="text"
                                />
                            </div>
                            {countriesShow.map(function (object, i) {
                                return (
                                    <div
                                        key={i}
                                        onClick={() =>
                                            changeCountry(object.code)
                                        }
                                        className={
                                            styles[
                                                'lb-input-select-options-row'
                                            ]
                                        }>
                                        <p>
                                            {object.emoji}{' '}
                                            <span>{object.name}</span>
                                        </p>
                                        <p>{object.dial_code}</p>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div
                        onClick={(e) => openModal(e, 'country_2')}
                        className={
                            styles[
                                `lb-input-select-box${
                                    getFromError('phone_2')?.error
                                        ? '-error'
                                        : ''
                                }`
                            ]
                        }>
                        <div
                            className={
                                styles['lb-input-select-container-flags']
                            }>
                            <p>{countries[countrySetter].emoji}</p>
                            <p>{countries[countrySetter].dial_code}</p>
                            <div
                                className={
                                    styles[
                                        'lb-input-select-container-flags-icon'
                                    ]
                                }></div>
                        </div>
                    </div>
                    <input
                        className={
                            styles[
                                `lb-input-select${
                                    getFromError('phone_2')?.error
                                        ? '-error'
                                        : ''
                                }`
                            ]
                        }
                        onChange={(e) => cleanError('phone_2', e)}
                        defaultValue={getValueDefault('phone_2')}
                        type="tel"
                        name="phone"
                        placeholder="(000) 000-0000"
                        autoComplete="tel"
                    />
                    {getFromError('phone_2')?.error && (
                        <p className={styles['lb-input-error']}>
                            {getFromError('phone_2')?.message}
                        </p>
                    )}
                </div>
            </div>
            <div className={styles['lb-form-row']}>
                <div className={styles['lb-form-record']}>
                    <label className={styles['lb-form-label-editor']}>
                        Address*
                    </label>
                    <input
                        className={
                            styles[
                                `lb-input${
                                    getFromError('address')?.error
                                        ? '-error-border'
                                        : ''
                                }`
                            ]
                        }
                        onChange={(e) => cleanError('address', e)}
                        defaultValue={getValueDefault('address')}
                        type="text"
                        name="address"
                        placeholder="Address"
                    />
                    {getFromError('address')?.error && (
                        <p className={styles['lb-input-error']}>
                            {getFromError('address')?.message}
                        </p>
                    )}
                </div>
                <div className={styles['lb-form-record']}>
                    <label className={styles['lb-form-label-editor']}>
                        City*
                    </label>
                    <input
                        className={
                            styles[
                                `lb-input${
                                    getFromError('city')?.error
                                        ? '-error-border'
                                        : ''
                                }`
                            ]
                        }
                        onChange={(e) => cleanError('city', e)}
                        defaultValue={getValueDefault('city')}
                        type="text"
                        name="city"
                        placeholder="City"
                    />
                    {getFromError('city')?.error && (
                        <p className={styles['lb-input-error']}>
                            {getFromError('city')?.message}
                        </p>
                    )}
                </div>
            </div>
            <div className={styles['lb-form-row']}>
                <div className={styles['lb-form-record']}>
                    <label className={styles['lb-form-label-editor']}>
                        State*
                    </label>
                    <div
                        onClick={(e) => openModal(e, 'state')}
                        className={
                            styles[
                                `lb-input${
                                    getFromError('state')?.error
                                        ? '-error-border'
                                        : ''
                                }`
                            ]
                        }>
                        {selectsModals.state && (
                            <div className={styles['lb-input-select-options']}>
                                {states.map(function (state, i) {
                                    return (
                                        <div
                                            key={i}
                                            onClick={(e) => changeState(e, i)}
                                            className={
                                                styles[
                                                    'lb-input-select-options-row'
                                                ]
                                            }>
                                            <p>{state.name}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        <p className={styles['lb-input-select-states-text']}>
                            {getValueDefault('state')}
                        </p>
                    </div>
                    {getFromError('state')?.error && (
                        <p className={styles['lb-input-error']}>
                            {getFromError('state')?.message}
                        </p>
                    )}
                </div>
                <div className={styles['lb-form-record']}>
                    <label className={styles['lb-form-label-editor']}>
                        ZIP*
                    </label>
                    <input
                        className={
                            styles[
                                `lb-input${
                                    getFromError('zipcode')?.error
                                        ? '-error-border'
                                        : ''
                                }`
                            ]
                        }
                        onChange={(e) => cleanError('zipcode', e)}
                        defaultValue={getValueDefault('zipcode')}
                        type="text"
                        name="zipcode"
                        inputMode="decimal"
                        placeholder="ZIP"
                        max="99999"
                        autoComplete="off"
                    />
                    {getFromError('zipcode')?.error && (
                        <p className={styles['lb-input-error']}>
                            {getFromError('zipcode')?.message}
                        </p>
                    )}
                </div>
            </div>
            <div className={styles['lb-form-row-submit']}>
                {errorGlobal.error && (
                    <p className={styles['lb-form-btn-s-error']}>
                        {errorGlobal.message}
                    </p>
                )}
                <button
                    disabled={loading}
                    className={styles['lb-form-btn']}
                    type="submit">
                    <span className={styles['lb-form-btn-s']}>Submit</span>
                </button>
            </div>
        </form>
    );
};

export default PersonalInformationForm;
