import React, { useMemo, useRef } from 'react';
import {
    Header as SolutoHeader,
    HeaderRef,
    cls,
    Picture,
} from '@solublestudio/soluto-design-system';
import type { HeaderProps as SolutoHeaderProps } from '@solublestudio/soluto-design-system';
import type { NavigationItemProps } from '@solublestudio/soluto-design-system';

import Logo from '~logo';
import LogoInverse from '~logo-inverse';

import CallMeForm, { CallMeFormProps } from '../Forms/CallMeForm';
import Button, { ButtonProps } from '../Button';
import SocialIcons, { SocialIconsProps } from '../SocialIcons';
import MegaMenu from '../MegaMenu';

import styles from './styles.module.scss';

export interface HeaderProps
    extends Omit<
        SolutoHeaderProps,
        | 'kind'
        | 'logo'
        | 'sticky'
        | 'navs'
        | 'preNavContent'
        | 'forceCollapseStyle'
        | 'classes'
    > {
    nav?: NavigationItemProps[];
    telButton?: ButtonProps[];
    getStartedButton?: ButtonProps[];
    userProps?: ButtonProps & { avatar: string };
    callMeFormOnSubmit?: CallMeFormProps['onSubmit'];
    callMeFormTexts?: CallMeFormProps['texts'];
    socialIcons?: SocialIconsProps['icons'];
    languageLinks?: ButtonProps[];
}

function Header({
    nav,
    userProps,
    callMeFormOnSubmit,
    callMeFormTexts,
    socialIcons,
    telButton,
    getStartedButton,
    languageLinks = [],
    ...props
}: HeaderProps) {
    const ref = useRef<HeaderRef>(null);

    const itemsWithOnClick = nav?.map((n) => ({
        ...n,
        className: n.items && n.items?.length > 0 ? styles.withChildren : '',
        onClick: () => {
            if (!n.items || n.items?.length === 0) {
                ref.current?.close();
            }
        },
        items: n.items?.map((i) => ({
            ...i,
            className:
                i.items && i.items?.length > 0 ? styles.withChildren : '',
            onClick: () => {
                if (!i.items || i.items?.length === 0) {
                    ref.current?.close();
                }
            },
        })),
    }));

    const navs = useMemo(() => {
        return [
            nav && {
                align: 'center',
                classes: { wrapper: styles['nav-main'] },
                items: itemsWithOnClick,
            },
            (telButton || getStartedButton) && {
                align: 'right',
                classes: {
                    wrapper: cls(styles['nav-buttons'], 'd-none d-lg-flex'),
                },
                items: [
                    telButton && {
                        childrenReplace: (
                            <Button
                                variant="secondarySmall"
                                iconName="telephone"
                                {...telButton}
                            />
                        ),
                    },
                    getStartedButton && {
                        childrenReplace: (
                            <Button
                                variant="primarySmall"
                                {...getStartedButton}
                            />
                        ),
                    },
                ].filter((i) => !!i),
            },
        ].filter((i) => !!i);
    }, [nav, telButton, getStartedButton, itemsWithOnClick]);

    return (
        <SolutoHeader
            {...props}
            ref={ref}
            kind="default"
            logo={{
                src: {
                    white: LogoInverse?.src ? LogoInverse.src : LogoInverse,
                    black: Logo?.src ? Logo.src : Logo,
                },
                link: {
                    href: '/',
                    title: 'LegalBrave',
                },
            }}
            sticky="top"
            navs={navs}
            classes={{
                root: cls(styles.root),
                footerMobile: styles['footer-mobile'],
                navigationListItem: styles['navigation-list-item'],
                navigationItem: styles['navigation-item'],
                navigationItemActive: styles['navigation-item-active'],
                navigationItemParent: styles['navigation-item-active'],
                collapse: styles['collapse'],
                collapseContent: styles['collapse-content'],
                dropdownItem: cls('b300', styles.megamenuItem),
            }}
            megaMenu={true}
            customMegaMenu={MegaMenu}
            dropdownIcon="dropdownDown"
            preNavContent={
                <>
                    {!!telButton && (
                        <Button
                            {...telButton}
                            label={null}
                            variant="buttonIconSingle"
                            iconName="telephone"
                            className={cls(styles.btnIcon, 'd-lg-none')}
                        />
                    )}
                    {!!userProps &&
                        (userProps.avatar ? (
                            <a className={styles.avatar} {...userProps}>
                                <Picture
                                    image={userProps.avatar}
                                    layout="full"
                                    alt="avatar"
                                />
                            </a>
                        ) : (
                            <Button
                                variant="buttonIconSingle"
                                iconName="user"
                                className={styles.btnIcon}
                                onClick={() => ref.current?.close()}
                                {...userProps}
                            />
                        ))}
                    <CallMeForm
                        {...(!!callMeFormOnSubmit && {
                            onSubmit: callMeFormOnSubmit,
                        })}
                        className={styles.callMeForm}
                        texts={callMeFormTexts}
                    />
                </>
            }
            {...(!!socialIcons?.length && {
                footerMobile: <SocialIcons icons={socialIcons} />,
            })}
        />
    );
}

export default Header;
