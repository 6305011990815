import React from 'react';

import {
    Row,
    Col,
    cls,
    Container,
    Text,
    Image,
} from '@solublestudio/soluto-design-system';

import logoBlack from '~logo';
import logoWhite from '~logo-white';

import Link, { LinkProps } from 'Link';

import SocialIcons from '@ds/components/shared/SocialIcons';

import type { SocialIconsProps } from '@ds/components/shared/SocialIcons';

import styles from './styles.module.scss';

export interface FooterProps {
    text?: string;
    socialIcons: SocialIconsProps['icons'];
    locales?: string[];
    locale?: string;
    navs: LinkProps[];
    theme: 'bg-dark' | 'bg-gray';
    languageNav?: {
        label: string;
        children: LinkProps[];
    };
}

const Footer: React.FC<FooterProps> = ({
    text,
    socialIcons,
    navs,
    theme = 'bg-gray',
    languageNav,
    ...props
}) => {
    const logo = theme === 'bg-dark' ? logoWhite : logoBlack;

    return (
        <footer
            {...props}
            className={cls(styles.footer, 'py-grid-margin')}
            data-theme={theme}>
            <Container>
                <Row>
                    <Col
                        col={{ lg: 6 }}
                        order={{ lg: 2 }}
                        className={cls(styles.colWrapper, 'my-xl', 'pb-xl')}>
                        <ul
                            className={cls(
                                styles.nav,
                                'list-unstyled',
                                'mb-none',
                                'b200',
                            )}>
                            {navs.map((nav, i) => (
                                <li key={i} className={cls('mb-lg')}>
                                    {nav.children ? (
                                        <>
                                            <span
                                                className={cls(
                                                    'font-weight-semi-bold',
                                                )}>
                                                {nav.label}
                                            </span>
                                            <ul
                                                className={cls(
                                                    'list-unstyled',
                                                )}>
                                                {nav.children.map(
                                                    (link: any, ii: number) => (
                                                        <li
                                                            key={`${i}-${ii}`}
                                                            className={cls(
                                                                'mt-xs',
                                                            )}>
                                                            <Link {...link}>
                                                                {link.label}
                                                            </Link>
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        </>
                                    ) : (
                                        <Link
                                            {...nav}
                                            className={cls(
                                                'font-weight-semi-bold',
                                            )}>
                                            {nav.label}
                                        </Link>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </Col>
                    <Col
                        className={cls(
                            styles.social,
                            'align-items-center',
                            'text-lg-right',
                            'mb-lg',
                        )}
                        col={{ lg: 5 }}
                        order={{ lg: 5 }}>
                        <SocialIcons icons={socialIcons} />
                    </Col>
                    <Col col={{ lg: 6 }} order={{ lg: 1 }}>
                        <Image
                            src={logo?.src ?? logo}
                            width={140}
                            className={cls(styles.logo, 'mb-lg')}
                        />
                    </Col>
                    <Col
                        col={{ lg: 7 }}
                        order={{ lg: 4 }}
                        className={cls(styles.copyright)}>
                        {text && (
                            <Text
                                className={cls('b-100', styles.text)}
                                component="div">
                                {text}
                            </Text>
                        )}
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
