import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import HeroSection, {
    HeroSectionProps,
} from '@ds/components/organisms/HeroSection';
import { PictureProps } from 'Picture';

import FeaturedPlan, { FeaturedPlanProps } from '../../PointsSection/Features';

export interface HeroFeaturedProps
    extends HeroSectionProps,
        Omit<FeaturedPlanProps, 'title'> {
    titlePlan: FeaturedPlanProps['title'];
    imageMobile: PictureProps;
    mobileImage: PictureProps;
    titleMobile: string;
}

const HeroFeatured = ({
    background,
    mobileImage,
    ctaVideo,
    title,
    titleMobile,
    titlePlan,
    description,
    video,
    items,
    linkPlan,
    imageMobile,
    ...props
}: HeroFeaturedProps) => {
    return (
        <HeroSection
            {...props}
            ctaVideo={ctaVideo}
            background={background}
            fullscreen={false}
            ctaClassName={cls('d-none', 'd-lg-block')}
            video={video}
            title={title}
            titleMobile={titleMobile}
            mobileImage={mobileImage}
        />
    );
};

export default HeroFeatured;
