import React from 'react';

import {
    SocialIcons as SocialIconsSoluto,
    cls,
} from '@solublestudio/soluto-design-system';

import type { SocialIconsProps as SocialIconsSolutoProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface SocialIconsProps extends SocialIconsSolutoProps {
    size?: SocialIconsSolutoProps['size'];
    gap?: SocialIconsSolutoProps['gap'];
}

const SocialIcons = ({ size = 24, gap = 'md', ...props }: SocialIconsProps) => {
    return (
        <SocialIconsSoluto
            {...props}
            size={size}
            gap={gap}
            iconsColor="currentColor"
            classes={{
                link: cls(styles.link),
            }}
        />
    );
};

export default SocialIcons;
