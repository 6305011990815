import React, { useEffect, useRef } from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import HTMLComponent from './..';

import type { HTMLComponentProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';
import useTooltips from '@ds/hooks/useTooltips';

export interface HTMLCheckListComponentProps extends HTMLComponentProps {
    classes?: {
        wrapper?: string;
        element?: string;
    };
}

const HTMLCheckListComponent: React.FC<HTMLCheckListComponentProps> = ({
    classes,
    text,
    children,
    ...props
}) => {
    const htmlRef = useTooltips();

    return (
        <div ref={htmlRef} className={cls(classes?.wrapper)}>
            <HTMLComponent
                {...props}
                text={text}
                children={children}
                className={cls(styles.htmlWrapper, classes?.element)}
            />
        </div>
    );
};

export default HTMLCheckListComponent;
