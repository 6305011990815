import React from 'react';

import { Section, Row, Col, cls } from '@solublestudio/soluto-design-system';

import BaseLayout, { BaseLayoutProps } from '..';

export interface LayoutPrivateProps extends BaseLayoutProps {
    children: React.ReactNode;
    menu?: React.ReactNode;
}

const LayoutPrivate: React.FC<LayoutPrivateProps> = ({
    children,
    menu,
    ...props
}) => {
    return (
        <BaseLayout {...props}>
            <Row>
                {!!menu && (
                    <Col
                        col={{ xs: 12, lg: 3 }}
                        className={cls('d-none', 'd-lg-block')}>
                        <Section>{menu}</Section>
                    </Col>
                )}
                <Col col={{ xs: 12, lg: menu ? 9 : 12 }}>{children}</Col>
            </Row>
        </BaseLayout>
    );
};

export default LayoutPrivate;
