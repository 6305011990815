import React, { useRef, useCallback } from 'react';

import { ModalRef } from '@solublestudio/soluto-design-system';

import Button, { ButtonProps } from '@ds/components/shared/Button';
import ModalVideo, { VideoProps } from '@ds/components/shared/Modal/Video';

export interface VideoButtonProps {
    button: ButtonProps;
    variant?: ButtonProps['variant'];
    className?: string;
    video: VideoProps['video'];
}

const VideoButton = ({
    button,
    variant = 'buttonIcon',
    className,
    video,
}: VideoButtonProps) => {
    const modalRef = useRef<ModalRef>(null);

    const openModal = useCallback(
        (ev) => {
            ev?.preventDefault();

            if (!modalRef?.current) {
                return;
            }

            modalRef.current.toggle();
        },
        [modalRef],
    );

    return (
        <>
            <ModalVideo ref={modalRef} video={video} />
            <Button
                {...button}
                className={className}
                component="button"
                variant={variant}
                iconName="play"
                children={button?.label && button.label}
                onClick={openModal}
            />
        </>
    );
};

export default VideoButton;
