import { Config } from 'api-base/lib/types';
import { UserPlan } from 'api-base/lib/types/User';
import { states } from './states';

const defaultEmailAttachments = [
    {
        type: 'application/pdf',
        filename: 'LEGALBRAVE Plan Member Agreement.pdf',
        path: {
            folder: 'email-attachments',
            filename: 'Legal Plan Member Agreement.pdf',
        },
    },
];

const GLOBAL: Config = {
    crm: {
        country: {
            name: 'United States',
            code: 'US',
            states,
        },
        payment: {
            initial: 'Primera mensualidad',
            blocked: 'Proceso de impago',
            upgrade: 'Proceso de upgrade',
        },
        email: {
            from_email: 'no-reply@legalbrave.com',
            from_name: 'LegalBrave',
            subject_welcome: 'Welcome to LegalBrave',
            template_welcome:
                Number(process.env.MJ_WELCOME_EMAIL_TEMPLATE_ID) || 3772457,
            attachments_welcome: {
                [UserPlan.ESSENTIAL]: defaultEmailAttachments,
                [UserPlan.ESSENTIAL_SEMESTRAL]: defaultEmailAttachments,
                [UserPlan.PREMIUM]: defaultEmailAttachments,
                [UserPlan.PREMIUM_SEMESTRAL]: defaultEmailAttachments,
                [UserPlan.UNIQUE_PLAN]: [
                    {
                        type: 'application/pdf',
                        filename: 'LEGALBRAVE Plan Member Agreement.pdf',
                        path: {
                            folder: 'email-attachments',
                            filename:
                                'LEGALBRAVE Plan Member Agreement PROMO.pdf',
                        },
                    },
                ],
            },
            prices: {
                [UserPlan.ESSENTIAL]: '$24.99/month',
                [UserPlan.ESSENTIAL_SEMESTRAL]: '$124.95/semi-annual',
                [UserPlan.PREMIUM]: '$39.99/month',
                [UserPlan.PREMIUM_SEMESTRAL]: '$199.95/semi-annual',
                [UserPlan.UNIQUE_PLAN]: '$95 + $24.99/month',
            },
        },
        prices: {
            [UserPlan.ESSENTIAL]: 24.99,
            [UserPlan.ESSENTIAL_SEMESTRAL]: 124.95,
            [UserPlan.PREMIUM]: 39.99,
            [UserPlan.PREMIUM_SEMESTRAL]: 199.95,
            [UserPlan.UNIQUE_PLAN]: 119.99,
        },
        currency: 'USD',
        lead: {
            email: 'no-reply@legalbrave.com',
        },
    },
    web: {
        phone_prefix: '+1',
        forms: {
            personal_information: '164760854316387674',
            payment_information:
                process.env.NEXT_PUBLIC_ARENGU_PAYMENT_FORM_ID ||
                '164785159213760546',
        },
        prices: {
            essential: '$24.99',
            essential_semestral: '$124.95',
            premium: '$39.99',
            premium_semestral: '$199.95',
            unique_plan: '$95 + $24.99/month',
        },
        chat: true,
        cookies: {
            utms: 'AppUtmsData',
            popup: 'AppPopupHidden',
        },
        versions: {
            DEFAULT: 'DEFAULT',
            PROMO: 'PROMO',
        },
    },
};

export default GLOBAL;
