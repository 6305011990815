import { useUser } from 'api-base/auth';
import {
    Menu as DSMenu,
    ModalRef,
    ModalSmall,
} from '@solublestudio/legalbrave-design-system';
import { useRef, useMemo } from 'react';

import { navigate } from 'Link';
import { useTranslate } from '../../hooks/useTranslate';

const Menu = ({ menu }) => {
    const { user, isLoading } = useUser();

    const ref = useRef<ModalRef>(null);

    const [t] = useTranslate();

    const items = useMemo(
        () =>
            menu?.length > 0
                ? menu.map((item: any) => {
                      const { href } = item;

                      if (href === '/api/auth/logout') {
                          return {
                              ...item,
                              href: '/#',
                              onClick: (ev) => {
                                  ev?.preventDefault();

                                  ref?.current?.toggle();
                              },
                          };
                      }
                      return item;
                  })
                : [],
        [menu],
    );

    return (
        <>
            <DSMenu
                items={items}
                title={t('user.menu.title', { name: user?.profile?.name })}
                image={user?.picture ?? ''}
            />
            <ModalSmall
                ref={ref}
                title={t('modal.logout.title')}
                description={t('modal.logout.description')}
                buttons={[
                    {
                        label: t('modal.logout.logout'),
                        onClick: (ev) => {
                            ev?.preventDefault();
                            navigate('/api/auth/logout');
                        },
                        variant: 'secondaryLarge',
                    },
                    {
                        label: t('modal.logout.cancel'),
                        variant: 'primaryLarge',
                        onClick: (ev) => {
                            ev?.preventDefault();
                            ev?.stopPropagation();
                            ref?.current?.toggle();
                        },
                    },
                ]}
            />
        </>
    );
};

export default Menu;
