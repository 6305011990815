import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Section, { SectionProps } from '@ds/components/shared/Section';
import Summary, { SummaryProps } from '@ds/components/shared/Summary';

import styles from '../styles.module.scss';
import { ButtonProps } from '@ds/components/shared/Button';

export interface HeroSecondaryProps extends Omit<SummaryProps, 'alignImage'> {
    variant?: 'default' | 'intense' | 'cold';
    anchor?: string;
    pretitle?: string;
    fullContainer?: boolean;
    titleMobile?: string;
    ctas: ButtonProps[];
    onlyText?: boolean;
}

const themes = {
    default: 'light',
    intense: 'bg-primary',
    cold: 'bg-cold',
};
const variantsButtons = {
    default: ['primaryLarge', 'link'],
    intense: ['secondaryLargeWithOutBorder', 'link'],
    cold: ['primaryLarge', 'link'],
};

const HeroSecondary = ({
    anchor,
    ctas,
    fullContainer,
    titleMobile,
    pretitle,
    variant = 'default',
    onlyText = false,
    ...props
}: HeroSecondaryProps) => {
    const ctasWithVariant = ctas?.map((button, i) => ({
        ...button,
        variant: variantsButtons[variant][i],
    }));
    return (
        <Section
            id="hero-section"
            anchor={anchor}
            className={styles.heroSecondary}
            onlyText={onlyText}
            fullContainer={fullContainer}
            data-theme={themes[variant] as SectionProps['dataTheme']}>
            <Summary
                {...props}
                ctas={ctasWithVariant}
                alignImage="start"
                titleFont="h300"
                fullContainer={fullContainer}
                pretitle={pretitle}
                onlyText={onlyText}
                titleMobile={titleMobile}
            />
        </Section>
    );
};

export default HeroSecondary;
