import React, { useEffect, useMemo } from 'react';
import { useTranslate } from '../hooks/useTranslate';
import Script from 'next/script';
import { useUser, UserContext } from 'api-base/auth';
import './chat.scss';
import { Config } from 'api-base/lib/types';
import { useUtms } from '../hooks/useUtms';

const paramsClient = {
    name: 'Service_Chat_Team',
    buttonId: '5735f000000lTTf',
    type: 'Support',
};

const paramsDefault = {
    name: 'Sales_Chat_Team',
    buttonId: '5735f000000lTTa',
    type: 'Sales',
};

const Chat = ({ globalConfig }: { globalConfig: Config }) => {
    const { user, isLoading }: UserContext = useUser();
    const [_, locale] = useTranslate();
    const logoUrl = `${process.env.BASE_URL}/isotipo-inverse.svg`;
    const [utms] = useUtms(globalConfig.web.cookies.utms);

    const utmsParsed = useMemo(() => {
        if (!utms) return null;
        try {
            return JSON.parse(utms);
        }
        catch (e) {
            return null;
        }
    }, [utms]);

    const params = useMemo(
        () => (isLoading ? null : user ? paramsClient : paramsDefault),
        [user, isLoading],
    );

    const type =
        process.env.APP_VERSION === globalConfig.web.versions?.PROMO
            ? 'Promo'
            : 'Sales';

    const userInfo = useMemo(
        () =>
            user
                ? `
                embedded_svc.settings.extraPrechatInfo = [{
                    "entityName": "Account",
                    "showOnCreate": true,
                    "entityFieldMaps": [{ "isExactMatch": false, "Id": "${user?.profile?.accountId}", "doCreate": false, "doFind": true, "label": "Origin" }]
                }];
                embedded_svc.settings.extraPrechatFormDetails = [ 
                        {'label':'FirstName','value':'${user?.profile?.name}','displayToAgent':true},
                        {'label':'LastName','value':'${user?.profile?.lastName}','displayToAgent':true}, {
                        'label': 'Contact Data',
                        'value': '${user?.email}',
                        'transcriptFields':[ 'EmailChat__c' ],
                        'displayToAgent': true
                    }, {
                        'label': 'Contact Id',
                        'value': '${user?.profile?.accountId}',
                        'transcriptFields':[ 'AccountId' ],
                        'displayToAgent': true
                    },{
                        'label': 'Type',
                        'value': 'Support',
                        'transcriptFields':[ 'Type__c' ],
                        'displayToAgent': true
                    }
                ];`
                : `embedded_svc.settings.extraPrechatFormDetails = [{
                    'label': 'Type',
                    'value': '${type}',
                    'transcriptFields': ['Type__c'],
                    'displayToAgent': true
                },{
                    'label': 'First Name',
                    'transcriptFields': [ 'Prechat_FirstName__c' ]
                },{
                    'label': 'Last Name',
                    'transcriptFields': [ 'Prechat_LastName__c' ]
                },{
                    'label': 'Email',
                    'transcriptFields': [ 'Prechat_Email__c' ]
                },{
                     'label': 'Mobile',
                    'transcriptFields': [ 'Prechat_Mobile__c' ]
                },
                {
                    'label': 'UTM Campaign',
                    'value': '${utmsParsed?.utm_campaign ?? ''}',
                    'transcriptFields': ['UTM_Campaign__c'],
                    'displayToAgent': false
                },{
                    'label': 'UTM Medium',
                    'value': '${utmsParsed?.utm_medium ?? ''}',
                    'transcriptFields': ['UTM_Medium__c'],
                    'displayToAgent': false
                },{
                    'label': 'UTM Source',
                    'value': '${utmsParsed?.utm_source ?? ''}',
                    'transcriptFields': ['UTM_Source__c'],
                    'displayToAgent': false
                },{
                    'label': 'GCLID',
                    'value': '${utmsParsed?.gclid ?? ''}',
                    'transcriptFields': ['GCLID__c'],
                    'displayToAgent': false
                },
            ];`,
        [user, utmsParsed],
    );

    const scriptContent = useMemo(() => {
        if (isLoading) return '';
        return `
        var initESW = function (gslbBaseURL) {
          embedded_svc.settings.displayHelpButton = true; //Or false
          embedded_svc.settings.language = "${locale}"; //For example, enter 'en' or 'en-US'
          embedded_svc.settings.defaultMinimizedText = "Chat with us"; //(Defaults to Chat with an Expert)
          //embedded_svc.settings.disabledMinimizedText = "Agent Offline"; //(Defaults to Agent Offline)
          //embedded_svc.settings.loadingText = "Loading"; //(Defaults to Loading)
          //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
          // Settings for Chat
          //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
          // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
          // Returns a valid button ID.
          //};
          //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
          //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
          //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
          //embedded_svc.settings.smallCompanyLogoImgURL = '${logoUrl}';
          embedded_svc.settings.enabledFeatures = ['LiveAgent'];
          embedded_svc.settings.entryFeature = 'LiveAgent';
          embedded_svc.init(
            'https://protegitassf.my.salesforce.com',
            'https://protegitassf.force.com',
            gslbBaseURL,
            '00D5f000004bM3t',
              '${params?.name}',
              {
                baseLiveAgentContentURL: 'https://c.la4-c1-ia5.salesforceliveagent.com/content',
                deploymentId: '5725f000000lSk2',
                buttonId: '${params?.buttonId}',
                baseLiveAgentURL: 'https://d.la4-c1-ia5.salesforceliveagent.com/chat',
                eswLiveAgentDevName: '${params?.name}',
                isOfflineSupportEnabled: false
              }
          );
          ${userInfo}
      };
      if (!document.querySelector('.embeddedServiceHelpButton')) {
        if (!window.embedded_svc) {
          var s = document.createElement('script');
          s.setAttribute('src', 'https://protegitassf.my.salesforce.com/embeddedservice/5.0/esw.min.js');
          s.onload = function () {
              initESW(null);
          };
          document.body.appendChild(s);
      } else {
          initESW('https://service.force.com');
      }
    }`;
    }, [params, userInfo]);

    useEffect(() => {
        if (isLoading) return;
        const openChat = () => {
            document
                .querySelector('.embeddedServiceHelpButton button')
                ?.click();
        };
        const triggers = document.querySelectorAll('a[href*="#chat-opened"]');
        triggers.forEach((t) => t.addEventListener('click', openChat));

        return () => {
            triggers.forEach((t) => t.removeEventListener('click', openChat));
        };
    }, [isLoading]);

    return isLoading ? null : (
        <Script
            id="sfchatcode"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
                __html: scriptContent,
            }}
        />
    );
};

export default Chat;
