import React, { useState } from 'react';

import { cls, Icon } from '@solublestudio/soluto-design-system';
import Picture, { PictureProps } from 'Picture';
import HTMLCheckListComponent from '@ds/components/shared/HTMLComponent/CheckList';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import Button, { ButtonProps } from '@ds/components/shared/Button';

import type { HTMLComponentProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface CardPlanFeaturesProps extends HTMLComponentProps {
    image: PictureProps;
    className?: string;
    title: string;
    text?: string;
    showFeatures?: boolean;
    textFeatures?: string;
    features?: { check: boolean; text: string }[];
    description?: string;
    price: string;
    ctas?: (Omit<ButtonProps, 'variant'> & {
        variant?: ButtonProps['variant'];
    })[]; // Inherit button props but variant should be optional
    variant?: 'default' | 'intense';
    recommended?: string;
}
const variantsButtons: {
    [key in CardPlanFeaturesProps['variant'] as string]: ButtonProps['variant'][];
} = {
    intense: ['primaryLarge', 'link'],
    default: ['secondaryLargeWithOutBorder', 'link'],
};
const CardPlanFeatures = ({
    image,
    title,
    textFeatures,
    features,
    description,
    price,
    ctas = [],
    variant = 'default',
    recommended,
    className,
}: CardPlanFeaturesProps) => {
    const [view, setView] = useState(false);

    return (
        <div
            className={cls(
                'bg-base-1000',
                'd-flex',
                'flex-column',
                'position-relative',
                styles.card,
                className,
            )}>
            <Picture
                image={image}
                layout="full"
                objectFit="cover"
                classes={{
                    wrapper: cls(styles.picture),
                }}
            />
            <div
                className={cls(
                    'px-grid-padding',
                    'pb-lg',
                    'd-flex',
                    'flex-column',
                    'align-items-start',
                    styles.wrapper,
                )}>
                {recommended && (
                    <Text
                        font="b200"
                        className={cls(
                            styles.recommended,
                            'py-modulor',
                            'px-xxs',
                            'bg-primary-dark',
                            'text-base-1000',
                        )}>
                        {recommended}
                    </Text>
                )}
                <div
                    className={cls(
                        styles.resume,
                        styles.resumeFeatureList,
                        'pt-xs',
                        'mb-md',
                        'pb-xs',
                        'position-relative',
                        'align-items-lg-center',
                        description && styles.withDescription,
                    )}>
                    <Heading
                        className={cls(styles.title)}
                        font="b400"
                        component="h3">
                        {title}
                    </Heading>
                    {textFeatures && (
                        <HTMLCheckListComponent
                            text={textFeatures}
                            classes={{
                                wrapper: cls('mb-xxs d-block d-md-none'),
                            }}
                        />
                    )}
                    <div
                        role="button"
                        tabIndex={0}
                        className={cls(
                            styles.price,
                            'text-lg-right d-flex d-md-block justify-content-between align-items-center',
                        )}
                        onClick={() => setView(!view)}>
                        <Text font="b300">{price}</Text>
                        <Icon
                            name={view ? 'dropdownUp' : 'dropdownDown'}
                            size={24}
                            classes={{
                                icon: cls('d-md-none'),
                            }}
                        />
                    </div>

                    {description && (
                        <Text
                            font="b200"
                            component="div"
                            className={cls(styles.description)}>
                            {description}
                        </Text>
                    )}
                </div>

                {textFeatures && (
                    <HTMLCheckListComponent
                        text={textFeatures}
                        classes={{
                            wrapper: cls('mb-lg d-none d-md-block'),
                        }}
                    />
                )}

                {features && view && (
                    <>
                        <div className={cls(styles.wrapperMobile)}>
                            {features &&
                                features.map((feature, index) => (
                                    <div
                                        key={`icon-${index}`}
                                        className={cls(
                                            'mb-xs d-flex d-md-none',
                                            styles.wrapperItem,
                                        )}>
                                        <Icon
                                            name={
                                                feature.check
                                                    ? 'check'
                                                    : 'close-filled'
                                            }
                                            size={32}
                                            classes={{
                                                icon: cls(
                                                    'mr-tiny',
                                                    styles.icon,
                                                    feature.check
                                                        ? styles.yes
                                                        : styles.no,
                                                ),
                                            }}
                                        />
                                        <Text
                                            font="b300"
                                            className={styles.text}>
                                            {feature.text}
                                        </Text>
                                    </div>
                                ))}
                        </div>
                        {ctas && (
                            <div
                                className={cls(
                                    styles.ctas,
                                    styles.ctaCenter,
                                    'd-md-none',
                                )}>
                                {ctas.map((cta, index) => (
                                    <Button
                                        key={`summary-cta-${index}`}
                                        {...cta}
                                        variant={
                                            variantsButtons?.[variant]?.[
                                                index
                                            ] ?? 'primaryLarge'
                                        }
                                        className="b300"
                                    />
                                ))}
                            </div>
                        )}
                    </>
                )}

                {features &&
                    features.map((feature, index) => (
                        <div
                            key={`icon-${index}`}
                            className={cls(
                                'mb-xs',
                                'd-none d-md-flex',
                                styles.wrapperItem,
                            )}>
                            <Icon
                                name={feature.check ? 'check' : 'close-filled'}
                                size={32}
                                classes={{
                                    icon: cls(
                                        'mr-tiny',
                                        feature.check ? styles.yes : styles.no,
                                    ),
                                }}
                            />
                            <Text font="b300" className={cls(styles.text)}>
                                {feature.text}
                            </Text>
                        </div>
                    ))}

                {ctas && (
                    <div
                        className={cls(
                            styles.ctas,
                            styles.ctaCenter,
                            'd-none d-md-flex',
                        )}>
                        {ctas.map((cta, index) => (
                            <Button
                                key={`summary-cta-${index}`}
                                {...cta}
                                variant={
                                    variantsButtons?.[variant]?.[index] ??
                                    'primaryLarge'
                                }
                                className="b300"
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardPlanFeatures;
