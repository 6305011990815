import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';

interface ItemProps {
    title: string;
    text?: string;
    component?: React.ReactNode;
}

export interface InfoDetailsProps {
    items: ItemProps[];
}

const InfoDetails = ({ items = [] }: InfoDetailsProps) => {
    return (
        <div className={styles.itemsContainer}>
            {items.map((item, index) => {
                return (
                    <div key={index}>
                        <Heading component="h4" font="b100" className="mb-tiny">
                            {item.title}
                        </Heading>
                        {item.text && (
                            <Text component="p" font="b300" data-skeleton="true">
                                {item.text}
                            </Text>
                        )}
                        {item.component && item.component}
                    </div>
                );
            })}
        </div>
    );
};

export default InfoDetails;
