import React, { useState } from 'react';
import {
    useElements,
    CardCvcElement,
    CardNumberElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js';

import styles from './../BaseForm/styles.module.scss';

export interface BaseFormProps {
    className?: string;
    formId: string;
}

interface PaymentInformationFormProps {
    onSubmit: (newData) => Promise<void>;
}

const PaymentInformationForm = ({ onSubmit }: PaymentInformationFormProps) => {
    const elements = useElements();

    const [loading, setLoading] = useState(false);

    const [cardErrors, setCardErrors] = useState({
        card: false,
        exp: false,
        cvc: false,
    });

    const [errorGlobal, setErrorGlobal] = useState({
        error: false,
        message: '',
    });

    const thisOnSubmit = async (ev) => {

        ev?.preventDefault()

        setLoading(true);

        const element = elements?.getElement('cardNumber');
        const isValidCard = !(element._empty || element._invalid);

        const exp = elements?.getElement('cardExpiry');
        const isValidExp = !(exp._invalid || exp._empty);

        const cvc = elements?.getElement('cardCvc');
        const isValidCVC = !(cvc._invalid || cvc._empty);

        setCardErrors({
            card: !isValidCard,
            exp: !isValidExp,
            cvc: !isValidCVC,
        });

        if (isValidCard || isValidExp || isValidCVC) {
            setLoading(false);
        }


        try {
            await onSubmit(element);
        } catch (e) {
            setLoading(false);
            setErrorGlobal({
                error: true,
                message: e?.message ?? 'Charge declined by card issuer.',
            });
        }
    };

    return (
        <form onSubmit={thisOnSubmit}>
            <div className={styles['lb-form-row']}>
                <p className={styles['lb-form-payment-titles']}>
                    Credit or debit card *
                </p>
                <CardNumberElement
                    className={
                        styles[
                            `lb-input-custom-stripe${
                                cardErrors.card ? '-error' : ''
                            }`
                        ]
                    }
                />
                {cardErrors.card && (
                    <p className={styles['lb-input-error']}>
                        This field is required.
                    </p>
                )}
            </div>
            <div className={styles['lb-form-row']}>
                <div className={styles['lb-form-record']}>
                    <p className={styles['lb-form-payment-titles']}>
                        Expiration date *
                    </p>
                    <CardExpiryElement
                        className={
                            styles[
                                `lb-input-custom-stripe${
                                    cardErrors.exp ? '-error' : ''
                                }`
                            ]
                        }
                    />
                    {cardErrors.exp && (
                        <p className={styles['lb-input-error']}>
                            This field is required.
                        </p>
                    )}
                </div>
                <div className={styles['lb-form-record']}>
                    <p className={styles['lb-form-payment-titles']}>
                        Security code *
                    </p>
                    <CardCvcElement
                        className={
                            styles[
                                `lb-input-custom-stripe${
                                    cardErrors.cvc ? '-error' : ''
                                }`
                            ]
                        }
                    />
                    {cardErrors.cvc && (
                        <p className={styles['lb-input-error']}>
                            This field is required.
                        </p>
                    )}
                </div>
            </div>
            <div className={styles['lb-form-row-submit']}>
                {errorGlobal.error && (
                    <p className={styles['lb-form-btn-s-error']}>
                        {errorGlobal.message}
                    </p>
                )}
                <button
                    disabled={loading}
                    className={styles['lb-form-btn']}
                    type="submit">
                    <span className={styles['lb-form-btn-s']}>Save</span>
                </button>
            </div>
        </form>
    );
};

export default PaymentInformationForm;
