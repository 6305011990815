import React, { useMemo } from 'react';

import {
    Footer as FooterDS,
    FooterProps,
} from '@solublestudio/legalbrave-design-system';

import { LinkProps } from 'Link';
import { useTranslate } from '../../hooks/useTranslate';

const Footer = ({
    items,
    social,
    withCancelText = false,
    alternateLanguages,
    ...props
}: {
    items?: LinkProps & {
        items: LinkProps[];
    };
    social?: LinkProps[];
    withCancelText?: boolean;
    theme: FooterProps['theme'];
    alternateLanguages?: LinkProps[];
}) => {
    const [t] = useTranslate();

    const [navigationLinks] = useMemo(
        () =>
            [(items && items[1]?.items) || []].map((nav) => {
                return nav.map((item: LinkProps) => {
                    return {
                        label: item.label,
                        href: item.href,
                        children: item.children,
                    };
                });
            }),
        [items],
    );

    const languageNav = useMemo(
        () => ({
            label: t('languages.title'),
            children:
                alternateLanguages?.map((alternate) => ({
                    ...alternate,
                    label: t('languages.' + alternate.locale),
                })) ?? [],
        }),
        [alternateLanguages],
    );

    const text = withCancelText ? [t('footer.cancelPlan')] : [];

    text.push(t('footer.copyright'), t('footer.disclaimer'));

    return (
        <FooterDS
            navs={navigationLinks}
            socialIcons={social}
            text={text.map((t) => `<p>${t}</p>`).join('')}
            languageNav={languageNav}
            {...props}
        />
    );
};

export default Footer;
