import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Text from '@ds/components/shared/Text';
import Button, { ButtonProps } from '@ds/components/shared/Button';
import Heading from '@ds/components/shared/Heading';

import styles from './styles.module.scss';

export interface BannerLargeProps {
    title: string;
    text: string;
    cta: ButtonProps;
}

const BannerLarge: React.FC<BannerLargeProps> = ({ title, text, cta }) => {
    return (
        <div
            className={cls(styles.wrapper, 'py-xl', 'px-grid-padding')}>
            <Heading component="h2" font="h300">
                {title}
            </Heading>
            <div
                className={cls(
                    'd-flex',
                    'flex-column',
                    'flex-lg-row',
                    'align-items-start',
                    'align-items-lg-center',
                    'justify-content-between',
                )}>
                <Text
                    component="div"
                    font="b300"
                    className={cls('mt-xg', styles.text)}>
                    {text}
                </Text>
                <Button
                    {...cta}
                    variant="secondaryLargeWithOutBorder"
                    className={cls(styles.button, 'mt-xg')}
                    iconName="telephone"
                />
            </div>
        </div>
    );
};

export default BannerLarge;
