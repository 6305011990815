import React from 'react';

import {
    Icon,
    cls,
    SliderControlProps as SliderControlSolutoProps,
} from '@solublestudio/soluto-design-system';

import styles from './style.module.scss';

export interface SliderControlsProps extends SliderControlSolutoProps {
    size: 'small' | 'large';
    iconPrev?: string;
    iconNext?: string;
    className?: string;
    align?: 'left' | 'right' | 'center';
}

const controlsAlign = {
    left: 'start',
    right: 'end',
    center: 'center',
};

const iconSizes = {
    small: '20',
    large: '24',
};

const SliderControls = function SliderControls({
    size = 'large',
    iconPrev = 'chevronLeft',
    iconNext = 'chevronRight',
    className,
    align = 'center',
    onClickPrev,
    onClickNext,
    isFirst,
    isLast,
}: SliderControlsProps): JSX.Element {
    return (
        <div
            className={cls(
                styles.controls,
                'd-flex',
                `justify-content-${controlsAlign[align]}`,
                className,
            )}>
            <button
                onClick={onClickPrev}
                className={cls(
                    styles.button,
                    styles[size],
                    'd-flex',
                    'align-items-center',
                    'justify-content-center',
                    'mr-sm',
                )}
                disabled={isFirst}>
                <Icon
                    name={iconPrev}
                    classes={{ icon: cls(styles.icon) }}
                    size={iconSizes[size]}
                />
            </button>
            <button
                onClick={onClickNext}
                className={cls(
                    styles.button,
                    styles[size],
                    'd-flex',
                    'align-items-center',
                    'justify-content-center',
                )}
                disabled={isLast}>
                <Icon
                    name={iconNext}
                    classes={{ icon: cls(styles.icon) }}
                    size={iconSizes[size]}
                />
            </button>
        </div>
    );
};

export default SliderControls;
