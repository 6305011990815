import React, { useMemo } from 'react';

import {
    GridContainer,
    GridBox,
    Container,
    Row,
    Col,
    cls,
} from '@solublestudio/soluto-design-system';

import Picture, { PictureProps } from 'Picture';

import Heading from '@ds/components/shared/Heading';
import Text, { TextProps } from '@ds/components/shared/Text';
import Button, { ButtonProps } from '@ds/components/shared/Button';
import { CardPhotoProps } from '@ds/components/shared/Cards/Photo';
import CarouselCardsSection from '@ds/components/organisms/Carousel/CardsSection';

import styles from './styles.module.scss';

export interface SummaryProps {
    title: string;
    titleFont?: TextProps['font'];
    text: string;
    ctas?: Omit<ButtonProps, 'variant'> &
        { variant?: ButtonProps['variant'] }[]; // Inherit button props but variant should be optional
    image?: PictureProps;
    cards?: CardPhotoProps[];
    alignImage: 'start' | 'end';
    className?: string;
    ctaAboveImage: boolean;
    fullContainer?: boolean;
    pretitle?: string;
    titleMobile?: string;
    onlyText?: boolean;
}

const Summary = ({
    title,
    titleFont = 'h200',
    text,
    ctas,
    image,
    cards = [],
    alignImage = 'start',
    className,
    ctaAboveImage = false,
    fullContainer,
    pretitle,
    titleMobile,
    onlyText = false,
}: SummaryProps) => {
    const withCards = !!(!image && cards);
    const imageLast = alignImage === 'end';

    const contentHtml = useMemo(
        () => (
            <>
                {pretitle && (
                    <Text
                        component="div"
                        font="h100"
                        className={cls(
                            'mb-md d-none d-md-block text-primary-dark font-weight-semi-bold',
                            styles.pretitle
                        )}>
                        {pretitle.replace('\n', '')}
                    </Text>
                )}
                <Heading
                    component="h2"
                    font={titleFont}
                    className={cls(
                        'mb-md',
                        !onlyText && 'd-none',
                        'd-md-block',
                    )}>
                    {title}
                </Heading>
                <Text
                    component="div"
                    font={onlyText ? 'b400' : 'b300'}
                    className={cls('mb-lg mt-md', styles.text)}>
                    {text}
                </Text>
                {ctas && (
                    <div className={styles.ctas}>
                        {ctas.map((cta, index) => (
                            <Button
                                key={`summary-cta-${index}`}
                                {...cta}
                                variant={cta.variant ?? 'primaryLarge'}
                            />
                        ))}
                    </div>
                )}
            </>
        ),
        [pretitle, title, text, ctas, titleFont],
    );

    const headingHtmlMobile = useMemo(
        () => (
            <div
                className={cls(
                    fullContainer || titleMobile ? styles.paddingContainer : '',
                )}>
                {!titleMobile && (
                    <Heading
                        component="h3"
                        font="h100"
                        className={cls(
                            'mb-md d-block d-md-none text-primary-dark',
                        )}>
                        {pretitle}
                    </Heading>
                )}
                <Heading
                    component="h2"
                    font={titleFont}
                    className={cls('mb-md d-block d-md-none')}>
                    {title}
                </Heading>
                {ctaAboveImage && (
                    <div className={cls('d-md-none mb-xl')}>{contentHtml}</div>
                )}
            </div>
        ),
        [
            titleMobile,
            pretitle,
            title,
            titleFont,
            contentHtml,
            ctaAboveImage,
            fullContainer,
        ],
    );

    return (
        <>
            {onlyText ? (
                <Container>
                    <Row className={className}>
                        <Col
                            className={cls(
                                'd-md-flex flex-md-column justify-content-md-center align-items-md-start',
                            )}>
                            <div
                                className={
                                    onlyText && styles.paddingContainerText
                                }>
                                {contentHtml}
                            </div>
                        </Col>
                    </Row>
                </Container>
            ) : withCards ? (
                <GridContainer className={className}>
                    <GridBox
                        component="div"
                        order={{ md: imageLast && 2 }}
                        className={
                            imageLast
                                ? styles.overflowRight
                                : styles.overflowLeft
                        }>
                        {headingHtmlMobile}
                        <CarouselCardsSection
                            items={cards}
                            classes={{
                                wrapper: styles.carousel,
                                controls: styles.controls,
                            }}
                            alignControls={imageLast ? 'left' : 'right'}
                            reverse={!imageLast}
                            component="div"
                        />
                    </GridBox>
                    <GridBox
                        component="div"
                        order={{ md: !imageLast && 1 }}
                        className={cls(
                            'd-md-flex flex-md-column justify-content-md-center align-items-md-start',
                            styles.wrapper,
                            imageLast
                                ? styles.contentLeft
                                : styles.contentRight,
                        )}>
                        {contentHtml}
                    </GridBox>
                </GridContainer>
            ) : (
                <Container isFluid={Boolean(fullContainer)}>
                    <Row className={className}>
                        <Col col={{ md: 6 }} order={{ md: imageLast && 2 }}>
                            {headingHtmlMobile}
                            {titleMobile && (
                                <div
                                    className={cls(
                                        'bg-primary-dark',
                                        'text-base-1000',
                                        'py-sm px-md',
                                        'd-block',
                                        'd-md-none',
                                        styles.plan,
                                    )}>
                                    <Heading font="h100" component="h3">
                                        {titleMobile}
                                    </Heading>
                                </div>
                            )}
                            {image && (
                                <div
                                    className={cls(
                                        styles.imageContainer,
                                        typeof fullContainer === 'boolean'
                                            ? fullContainer
                                                ? styles.imageNoRadius
                                                : styles.imageNoRadiusMobile
                                            : '',
                                    )}>
                                    <Picture
                                        image={image}
                                        alt={title}
                                        layout="background"
                                        objectFit="cover"
                                    />
                                </div>
                            )}
                        </Col>

                        <Col
                            col={{ md: 6 }}
                            order={{ md: !imageLast && 1 }}
                            className={cls(
                                'd-md-flex flex-md-column justify-content-md-center align-items-md-start',
                                ctaAboveImage && 'd-none d-md-block',
                            )}>
                            {contentHtml}
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default Summary;
