import React from 'react';

import { Container, Row, Col, cls } from '@solublestudio/soluto-design-system';

import Button, { ButtonProps } from '@ds/components/shared/Button';
import Section from '@ds/components/shared/Section';
import TitleSubtitle from '@ds/components/shared/TitleSubtitle';

import Heading from '@ds/components/shared/Heading';
import HTMLCheckListComponent from '@ds/components/shared/HTMLComponent/CheckList';

import styles from './styles.module.scss';

type ColumnProps = {
    title: string;
    description: string;
};

export interface ColumnsSectionProps {
    title: string;
    subtitle: string;
    columns: ColumnProps[];
    anchor?: string;
    cta?: Omit<ButtonProps, 'variant'>;
}

const PointsSection = ({
    title,
    subtitle,
    columns,
    anchor,
    cta,
}: ColumnsSectionProps) => {
    return (
        <Section anchor={anchor}>
            <Container>
                <TitleSubtitle
                    title={title}
                    subtitle={subtitle}
                    subtitleFont="b300"
                    className={cls('mb-xg')}
                />
                <Row className="mb-lg">
                    {columns.map(({ title, description }, index) => (
                        <Col
                            col={{ lg: columns.length === 2 ? 6 : 4 }}
                            key={`columns-section-${index}`}
                            className={styles.mb}>
                            {title && (
                                <Heading
                                    component="h4"
                                    font="b400"
                                    className={`mb-sm ${styles.mt}`}>
                                    {title}
                                </Heading>
                            )}

                            <HTMLCheckListComponent text={description} />
                        </Col>
                    ))}
                </Row>

                {cta && (
                    <div className={styles.cta}>
                        <Button variant="primaryLarge" {...cta} />
                    </div>
                )}
            </Container>
        </Section>
    );
};

export default PointsSection;
