import React from 'react';

import { HTMLComponent as HTMLComponentSoluto } from '@solublestudio/soluto-design-system';

import type { HTMLComponentProps } from '@solublestudio/soluto-design-system';

const HTMLComponent: React.FC<HTMLComponentProps> = ({ ...props }) => {
    return <HTMLComponentSoluto {...props} />;
};

export default HTMLComponent;
