import React, { useCallback, useState, useEffect, useRef } from 'react';

import { Input, cls } from '@solublestudio/soluto-design-system';
import Button from '../../Button';

import styles from './styles.module.scss';

interface CallMeFormPropsResponse {
    status: boolean;
    message: string;
}

interface CallMeFormTextProps {
    placeholder: string;
    loading: string;
    cta: string;
}

export interface CallMeFormProps {
    onSubmit?: (phone: string) => Promise<CallMeFormPropsResponse>;
    className?: string;
    texts?: CallMeFormTextProps;
}

function CallMeForm({ onSubmit, className, texts }: CallMeFormProps) {
    const phoneRef = useRef<HTMLInputElement>();
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmitForm = useCallback(async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const phone = formData.get('phone');

        setLoading(true);

        try {
            const { status, message } = await onSubmit(phone as string);

            if (status) {
                setSuccess(message);
            } else {
                setError(message);
            }
        } catch (e) {
            setError(e as string);
        }
    }, []);

    const onInputChange = useCallback(async (e) => {
       setError(null);
    }, []);

    useEffect(() => {
        if (success) {
            setError(null);
            (phoneRef.current as HTMLInputElement).value = '';

            setTimeout(() => {
                setSuccess(null);
            }, 5000);
        } else if (error) {
            setSuccess(null);
        }

        setLoading(false);
    }, [success, error, phoneRef]);

    return (
        <form className={cls(className)} id="call_me" onSubmit={onSubmitForm}>
            <Input
                ref={phoneRef}
                type="tel"
                name="phone"
                placeholder={texts?.placeholder || "Your phone number"}
                {...(!!error && { error })}
                {...(!!loading
                    ? { success: texts?.loading || 'We are sending your number…' }
                    : !!success && { success })}
                size="sm"
                required
                onChange={onInputChange}
                laterContent={
                    <Button
                        component="button"
                        type="submit"
                        variant="primarySmall"
                        iconName="telephone"
                        label={texts?.cta || "Call me"}
                        className={cls('position-absolute', styles.btn)}
                    />
                }
                classes={{
                    wrapper: cls('position-relative'),
                    element: cls(
                        styles.input,
                        (success || loading) && styles.inputDefaultBorder,
                    ),
                    validator: cls(
                        'position-absolute',
                        styles.inputValidator,
                        loading && styles.inputValidatorLoading,
                    ),
                }}
            />
        </form>
    );
}

export default CallMeForm;