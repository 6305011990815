import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import Picture, { PictureProps } from 'Picture';

import styles from './styles.module.scss';

export interface CardPhotoProps {
    title: string;
    text: string;
    image: PictureProps;
}

const CardPhoto: React.FC<CardPhotoProps> = ({
    title,
    text,
    image,
    ...props
}) => {
    return (
        <div
            className={cls(
                styles.card,
                'd-flex flex-column justify-content-end position-relative text-base-1000',
            )}
            {...props}>
            {image && (
                <Picture image={image} layout="background" objectFit="cover" />
            )}
            <div className={cls('d-flex flex-column justify-content-end position-relative p-grid-small', styles.content)}>
                <Heading
                    component="h4"
                    font="h100"
                    className={cls('position-relative mb-tiny')}>
                    {title}
                </Heading>
                <Text
                    component="div"
                    font="b200"
                    className={cls('position-relative')}>
                    {text}
                </Text>
            </div>
        </div>
    );
};

export default CardPhoto;
