import React, { useState } from 'react';
import validateForm from './../BaseForm/ValidatorForm';
import Link from 'next/link';

import styles from './../BaseForm/styles.module.scss';
import { Icon, cls } from '@solublestudio/soluto-design-system';

interface ContactFormProps {
    errorGlobal?: {
        error: boolean;
        message: string;
    };
    onSubmit: (data: any) => Promise<void>;
}
const ContactForm = ({ onSubmit }: ContactFormProps) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [errorGlobal, setErrorGlobal] = useState({
        error: false,
        message: '',
    });
    const [fields, setFields] = useState([
        {
            fieldName: 'name',
            type: 'text',
            required: true,
            options: { min: 1, max: 100 },
            value: '',
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'phone',
            type: 'phone',
            required: false,
            options: { digit: 10, countrySupport: ['US'] },
            value: '',
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'email',
            type: 'mail',
            required: true,
            options: {},
            value: '',
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'message',
            type: 'text',
            required: true,
            options: {},
            value: '',
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'legal_cycR',
            type: 'checkbox',
            required: true,
            options: {},
            value: false,
            error: {
                error: false,
                message: 'error',
            },
        },
    ]);

    const getValueDefault = (name: any) => {
        const input = fields.find((field) => field.fieldName === name);
        return input?.value;
    };

    const getFromError = (name: any) => {
        const input = fields.find((filed) => filed.fieldName === name);
        return input?.error;
    };

    const submitContact = async (ev) => {
        ev?.preventDefault();

        const { newFields, isValid } = validateForm.formCreateUser(fields);
        setFields(newFields);

        setIsSending(true);

        if (!isValid) {
            setIsSending(false);

            return;
        }

        let data = {
            source: 'Formulario Web',
            lastName: null,
        };

        for (let i = 0; i < newFields.length; i++) {
            const { value, fieldName } = newFields[i];
            if (fieldName !== 'legal_cycR') {
                data = { ...data, [`${fieldName}`]: value };
            }
        }

        try {
            await onSubmit(data);

            setIsSending(false);
            setIsSuccess(true);
        } catch (e) {
            setErrorGlobal({
                error: true,
                message: e?.message,
            });
            setIsSending(false);
        }
    };

    const onChange = (nameImput: any, e: any) => {
        const updatedFields = fields.map((field) => {
            if (field.fieldName === nameImput) {
                return {
                    ...field,
                    value: e.target.value,
                    error: { error: false, message: '' },
                };
            }
            return field;
        });

        setFields(updatedFields);
    };

    if (isSuccess) {
        return (
            <div className={styles['lb-form-row']}>
                <p className={styles['lb-form-contact-title']}>
                    We have received your message. We will contact you as soon
                    as possible!
                </p>
            </div>
        );
    }

    return (
        <form onSubmit={submitContact}>
            <div className={styles['lb-form-row']}>
                <p className={styles['lb-form-contact-title']}>
                    Complete the form and we'll contact you.
                </p>
            </div>
            <div className={styles['lb-form-row']}>
                <input
                    onChange={(e) => onChange('name', e)}
                    defaultValue={getValueDefault('name')}
                    className={
                        styles[
                            `lb-input${
                                getFromError('name')?.error
                                    ? '-error-border'
                                    : ''
                            }`
                        ]
                    }
                    type="text"
                    name="name"
                    placeholder="Name"
                />
                {getFromError('name')?.error && (
                    <p className={styles['lb-input-error']}>
                        {getFromError('name')?.message}
                    </p>
                )}
            </div>
            <div className={styles['lb-form-row']}>
                <input
                    onChange={(e) => onChange('phone', e)}
                    defaultValue={getValueDefault('phone')}
                    className={
                        styles[
                            `lb-input${
                                getFromError('phone')?.error
                                    ? '-error-border'
                                    : ''
                            }`
                        ]
                    }
                    type="tel"
                    name="phone"
                    placeholder="Phone"
                />
                {getFromError('phone')?.error && (
                    <p className={styles['lb-input-error']}>
                        {getFromError('phone')?.message}
                    </p>
                )}
            </div>
            <div className={styles['lb-form-row']}>
                <input
                    onChange={(e) => onChange('email', e)}
                    defaultValue={getValueDefault('email')}
                    className={
                        styles[
                            `lb-input${
                                getFromError('email')?.error
                                    ? '-error-border'
                                    : ''
                            }`
                        ]
                    }
                    type="email"
                    name="email"
                    placeholder="Your Email"
                />
                {getFromError('email')?.error && (
                    <p className={styles['lb-input-error']}>
                        {getFromError('email')?.message}
                    </p>
                )}
            </div>
            <div className={styles['lb-form-row']}>
                <textarea
                    onChange={(e) => onChange('message', e)}
                    defaultValue={getValueDefault('message')}
                    className={
                        styles[
                            `lb-input-textarea${
                                getFromError('message')?.error
                                    ? '-error-border'
                                    : ''
                            }`
                        ]
                    }
                    name="message"
                    placeholder="Your Message Name"></textarea>
                {getFromError('message')?.error && (
                    <p className={styles['lb-input-error']}>
                        {getFromError('message')?.message}
                    </p>
                )}
            </div>
            <div className={styles['lb-form-row']}>
                <div className={styles['lb-form-contact-row-legals']}>
                    <div
                        onClick={(e) => {
                            onChange('legal_cycR', {
                                target: {
                                    value: !getValueDefault('legal_cycR'),
                                },
                            });
                        }}
                        className={
                            styles[
                                `lb-form-contact-row-legals-check${
                                    getValueDefault('legal_cycR')
                                        ? '-checked'
                                        : ''
                                }`
                            ]
                        }>
                        {getValueDefault('legal_cycR') && (
                            <div
                                className={
                                    styles[
                                        'lb-form-contact-row-legals-check-bg'
                                    ]
                                }
                            />
                        )}
                    </div>
                    <p
                        className={
                            styles['lb-form-contact-row-legals-check-privacity']
                        }>
                        I agree with{' '}
                        <Link href="/terms-of-use/"> Terms of Use</Link> and{' '}
                        <Link href="/privacy-policy/">Privacy Statement</Link> *
                    </p>
                </div>
                {getFromError('legal_cycR')?.error && (
                    <p className={styles['lb-input-error']}>
                        {getFromError('legal_cycR')?.message}
                    </p>
                )}
            </div>
            <div
                className={styles['lb-form-row']}
                style={{
                    justifyContent: 'right',
                }}>
                {errorGlobal?.error && (
                    <p className={styles['lb-input-error']}>
                        {errorGlobal?.message}
                    </p>
                )}
                <button
                    disabled={isSending}
                    type="submit"
                    className={styles['lb-form-btn']}>
                    {isSending && (
                        <Icon
                            name="loading"
                            size={24}
                            classes={{
                                icon: styles.loading,
                            }}
                        />
                    )}
                    <span
                        className={cls(
                            styles['lb-form-btn-s'],
                            isSending && styles.disabled,
                        )}>
                        Contact Us
                    </span>
                </button>
            </div>
        </form>
    );
};

export default ContactForm;
