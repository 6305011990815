import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Picture, { PictureProps } from 'Picture';
import HTMLCheckListComponent from '@ds/components/shared/HTMLComponent/CheckList';
import Text from '@ds/components/shared/Text';
import HTMLComponent from '@ds/components/shared/HTMLComponent';

import type { HTMLComponentProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface CardPlanSmallProps extends HTMLComponentProps {
    description: HTMLComponentProps['text'];
    price: string;
    image: PictureProps;
    text: HTMLComponentProps['text'];
    className?: string;
}

const CardPlanSmall = ({
    description,
    price,
    image,
    text,
    className,
}: CardPlanSmallProps) => {
    return (
        <div className={cls(styles.card, 'd-lg-flex', className)}>
            <Picture
                image={image}
                layout="full"
                objectFit="cover"
                classes={{
                    wrapper: cls(styles.picture),
                }}
            />
            <div
                className={cls(
                    styles.resume,
                    'bg-base-1000',
                    'px-grid-small',
                    'py-lg',
                    'mb-md',
                )}>
                <HTMLComponent text={description} className="mb-tiny" />
                <Text
                    font="b400"
                    className={cls(styles.price, 'font-weight-semi-bold')}>
                    {price}
                </Text>
            </div>
            <HTMLCheckListComponent
                text={text}
                classes={{
                    wrapper: cls(styles.text, 'd-flex', 'align-items-center'),
                }}
            />
        </div>
    );
};

export default CardPlanSmall;
