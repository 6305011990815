import { formatButton } from './link';
import { formatMedia } from './media';

export async function formatPage(
    item: { [key: string]: any },
    options: {
        button?: {
            onClick: () => void;
            [key: string]: any;
        };
        image?: {
            [key: string]: any;
        };
        [key: string]: any;
    } = {},
) {
    if (!item) {
        return null;
    }

    let formattedItem = typeof item === 'object' ? { ...item } : item;

    const keys = Object.keys(formattedItem) || [];

    for (const key of keys) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
            if (!item[key]) {
                continue;
            }

            if (key === 'color') {
                formattedItem = {
                    ...formattedItem,
                    [key]: item[key].hex || null,
                };
                continue;
            }

            if (item['typename'] === 'button') {
                formattedItem = formatButton(item, options['button']);
                continue;
            }

            if (
                ['image', 'background', 'imageMobile', 'video'].indexOf(key) !==
                -1
            ) {
                formattedItem = {
                    ...formattedItem,
                    [key]: await formatMedia(item[key], options['image']),
                };
                continue;
            }

            if (Array.isArray(item[key]) && typeof item[key] === 'object') {
                formattedItem = {
                    ...formattedItem,
                    [key]: await Promise.all(
                        item[key].map((subItem: any) =>
                            formatPage(subItem, options),
                        ),
                    ),
                };
                continue;
            }

            if (!Array.isArray(item[key]) && typeof item[key] === 'object') {
                formattedItem = {
                    ...formattedItem,
                    [key]: await formatPage(item[key], options),
                };
                continue;
            }

            if (typeof item[key] === 'string') {
                continue;
            }
        }
    }

    return formattedItem;
}
