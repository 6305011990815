import React, { useEffect, useMemo, useRef, useState } from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

export interface AdyenPaymentProps {
    session:
        | {
              id: string;
              sessionData: string;
          }
        | undefined;
    onSuccess: () => void;
    onError: (error) => void;
}

const ENABLE_STATUS_CODES = ['Authorised'];

type AdyenPaymentResult = {
    resultCode?: string;
};

export default function AdyenPayment({
    session,
    onError,
    onSuccess,
}: AdyenPaymentProps) {
    const paymentContainer = useRef(null);
    const [checkout, setCheckout] = useState<any>(null);
    const [submitted, setSubmitted] = useState<any>(false);

    const config = useMemo(() => {
        if (!session) return null;

        return {
            environment:
                process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
                    ? 'test'
                    : 'live',
            clientKey: process.env.NEXT_PUBLIC_ADYEN_CLIENT_KEY ?? '',
            session: {
                id: session.id,
                sessionData: session.sessionData,
            },
            onPaymentCompleted: (result: AdyenPaymentResult) => {
                if (
                    result.resultCode &&
                    ENABLE_STATUS_CODES.includes(result.resultCode)
                ) {
                    onSuccess();
                    setSubmitted(true);
                } else {
                    onError({
                        message: `There was an error with your payment. Code error: "${result.resultCode}"`,
                    });
                }
            },
            onError: (error) => {
                onError(error);
            },
        };
    }, [session, onError, onSuccess]);

    useEffect(() => {
        if (!config || submitted) return;

        let ignore = false;
        const createCheckout = async () => {
            const currentCheckout = await AdyenCheckout(config);

            // The 'ignore' flag is used to avoid double re-rendering caused by React 18 StrictMode
            // More about it here: https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data
            if (paymentContainer.current && !ignore) {
                currentCheckout
                    .create('dropin')
                    .mount(paymentContainer.current);
                setCheckout(currentCheckout);
            }
        };
        if (!checkout) {
            createCheckout();
        } else {
            checkout.update(config);
        }

        return () => {
            ignore = true;
        };
    }, [config]);
    return <div ref={paymentContainer} className="payment"></div>;
}
