import React from 'react';
import {
    Container,
    Section,
    Row,
    Col,
    cls,
    HTMLComponent,
    Heading,
} from '@solublestudio/soluto-design-system';

import type { HTMLComponentProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface HTMLSectionProps extends Pick<HTMLComponentProps, 'text'> {
    title: string;
    alignRight?: boolean;
    anchor?: string;
}

const HTMLSection: React.FC<HTMLSectionProps> = ({
    title,
    text,
    alignRight,
    anchor,
}) => {
    return (
        <Section className={cls('pt-xg pb-mega')} anchor={anchor}>
            <Container>
                <Row>
                    {title && (
                        <Col col={{ lg: 8, xxl: 6 }}>
                            <Heading
                                component="h1"
                                className="h300 mb-xg font-weight-semi-bold">
                                {title}
                            </Heading>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col col={{ lg: 8, xxl: 6 }}>
                        <HTMLComponent
                            text={text}
                            className={styles.htmlSection}
                        />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default HTMLSection;
