import React from 'react';
import {
    Container,
    Row,
    Col,
    cls,
    Icon,
} from '@solublestudio/soluto-design-system';
import Text from '@ds/components/shared/Text';
import Picture from 'Picture';
import Section from '@ds/components/shared/Section';
import Heading from '@ds/components/shared/Heading';
import style from './styles.module.scss';

interface Cell {
    text: string;
    check: boolean;
}

interface RowData {
    content: Cell[];
}

interface CompareTable {
    table: RowData[];
}

interface ComparatorSectionProps {
    title: string;
    text: string;
    image?: string;
    comparatorTable?: CompareTable[];
}

const ComparatorSection = ({
    title,
    text,
    image,
    comparatorTable,
}: ComparatorSectionProps): JSX.Element => {
    return (
        <Section>
            <Container>
                <Row className='align-items-start'>
                    <Col col={{ md: 6 }} className={cls('d-md-block d-none', style.sticky)}>
                        <Picture image={image} alt={title} />
                    </Col>
                    <Col col={{ md: 6 }}>
                        <Heading component="h2" font="h100" className="mb-sm">
                            {title}
                        </Heading>
                        <Text font="b400" className="d-block mb-xg">
                            {text}
                        </Text>

                        {comparatorTable?.map((compareTable, index) => (
                            <table
                                key={`table-${index}`}
                                className={style.table}>
                                <thead className={style.headTable}>
                                    {compareTable.table.map(
                                        (tableRow, index) =>
                                            index === 0 && (
                                                <tr
                                                    key={`tr-head-${index}`}
                                                    className={cls(
                                                        style.row,
                                                        'b400',
                                                    )}>
                                                    {tableRow.content.map(
                                                        (contentRow, index) => (
                                                            <th
                                                                key={`th-${index}`}
                                                                className={
                                                                    style.cellHead
                                                                }>
                                                                {
                                                                    contentRow.text
                                                                }
                                                            </th>
                                                        ),
                                                    )}
                                                </tr>
                                            ),
                                    )}
                                </thead>
                                <tbody>
                                    {compareTable.table.map(
                                        (tableRow, index) =>
                                            index !== 0 && (
                                                <tr
                                                    key={`tr-body-${index}`}
                                                    className={cls(
                                                        style.row,
                                                        'b100',
                                                    )}>
                                                    {tableRow.content.map(
                                                        (contentRow, index) => (
                                                            <td
                                                                key={`td-${index}`}
                                                                className={
                                                                    style.cell
                                                                }>
                                                                <span
                                                                    className={cls(
                                                                        'd-lg-flex align-items-center',
                                                                    )}>
                                                                    <Icon
                                                                        name={
                                                                            contentRow.check
                                                                                ? 'check'
                                                                                : 'close-filled'
                                                                        }
                                                                        size={
                                                                            32
                                                                        }
                                                                        classes={{
                                                                            icon: cls(
                                                                                style.icon,
                                                                                contentRow.check
                                                                                    ? style.yes
                                                                                    : style.no,
                                                                            ),
                                                                        }}
                                                                    />
                                                                    <Text
                                                                        font="b100"
                                                                        className={cls(
                                                                            style.contentText,
                                                                        )}>
                                                                        {
                                                                            contentRow.text
                                                                        }
                                                                    </Text>
                                                                </span>
                                                            </td>
                                                        ),
                                                    )}
                                                </tr>
                                            ),
                                    )}
                                </tbody>
                            </table>
                        ))}
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};
export default ComparatorSection;
