import path, { join } from 'path';
import fs from 'fs';
import axios from 'axios';

const downloadVideo = async (fileURL: string) => {
    const filename = new URL(fileURL).pathname.split('/').pop() ?? 'video.mp4';

    const pathFolder = '/videos/';

    const relativePath = path.resolve(
        __dirname,
        join('../../../public/', pathFolder),
    );

    if (!fs.existsSync(relativePath)) {
        fs.mkdirSync(relativePath);
    }

    const pathFile = path.resolve(relativePath, `${filename}`);

    const response = await axios.get(fileURL, {
        responseType: 'stream',
    });

    const w = response.data.pipe(fs.createWriteStream(pathFile));

    return new Promise((resolve, reject) => {
        w.on('finish', () => {
            resolve(join(pathFolder, filename));
        });

        w.on('error', (err: any) => {
            reject(err);
        });
    });
};

export async function formatVideo(
    image: { [key: string]: any },
    extension: string,
) {
    if (image) {
        const publicURL = await downloadVideo(image.url);

        return {
            file: {
                publicURL: publicURL,
                extension,
            },
        };
    }

    return null;
}
