import React from 'react';

import { Row, Col } from '@solublestudio/soluto-design-system';

import BaseLayout from '..';

export interface LayoutCheckoutProps {
    children: React.ReactNode;
}

export default function LayoutCheckout({ children }: LayoutCheckoutProps) {
    return (
        <BaseLayout>
            <Row>
                <Col col={{ lg: 10 }} offset={{ lg: 1 }}>
                    {children}
                </Col>
            </Row>
        </BaseLayout>
    );
}
