// const STATES_SANDBOX = {
//     ['Alabama']: 'a023K000001YjfHQAS',
//     ['Alaska']: 'a023K000001YjfIQAS',
//     ['Arizona']: 'a023K000001YjeAQAS',
//     ['Arkansas']: 'a023K000001YjfMQAS',
//     ['California']: 'a023K000001YjfRQAS',
//     ['Colorado']: 'a023K000001YjfbQAC',
//     ['Connecticut']: 'a023K000001YjfcQAC',
//     ['Delaware']: 'a023K000001YjfqQAC',
//     ['Florida']: 'a023K000001YjfvQAC',
//     ['Georgia']: 'a023K000001YjfmQAC',
//     ['Hawaii']: 'a023K000001YjfhQAC',
//     ['Idaho']: 'a023K000001YjfwQAC',
//     ['Illinois']: 'a023K000001YjfiQAC',
//     ['Indiana']: 'a023K000001YjekQAC',
//     ['Iowa']: 'a023K000001Yjg0QAC',
//     ['Kansas']: 'a023K000001YjdvQAC',
//     ['Kentucky']: 'a023K000001Yjg5QAC',
//     ['Louisiana']: 'a023K000001YjgAQAS',
//     ['Maine']: 'a023K000001YjgFQAS',
//     ['Maryland']: 'a023K000001YjgBQAS',
//     ['Massachusetts']: 'a023K000001YjgKQAS',
//     ['Michigan']: 'a023K000001YjgPQAS',
//     ['Minnesota']: 'a023K000001YjgUQAS',
//     ['Mississippi']: 'a023K000001YjfdQAC',
//     ['Missouri']: 'a023K000001YjgZQAS',
//     ['Montana']: 'a023K000001YjgeQAC',
//     ['Nebraska']: 'a023K000001YjgVQAS',
//     ['Nevada']: 'a023K000001YjfrQAC',
//     ['New Hampshire']: 'a023K000001YjgQQAS',
//     ['New Jersey']: 'a023K000001YjgjQAC',
//     ['New Mexico']: 'a023K000001YjfxQAC',
//     ['New York']: 'a023K000001YjgkQAC',
//     ['North Carolina']: 'a023K000001YjeBQAS',
//     ['North Dakota']: 'a023K000001YjfgQAC',
//     ['Ohio']: 'a023K000001Yjg1QAC',
//     ['Oklahoma']: 'a023K000001YjgoQAC',
//     ['Oregon']: 'a023K000001YjgWQAS',
//     ['Pennsylvania']: 'a023K000001YjgXQAS',
//     ['Rhode Island']: 'a023K000001YjgpQAC',
//     ['South Carolina']: 'a023K000001YjfWQAS',
//     ['South Dakota']: 'a023K000001YjflQAC',
//     ['Tennessee']: 'a023K000001YjfsQAC',
//     ['Texas']: 'a023K000001YjgtQAC',
//     ['Utah']: 'a023K000001YjgRQAS',
//     ['Vermont']: 'a023K000001YjftQAC',
//     ['Virginia']: 'a023K000001YjgqQAC',
//     ['Washington']: 'a023K000001YjgyQAC',
//     ['West Virginia']: 'a023K000001YjfuQAC',
//     ['Wisconsin']: 'a023K000001YjgrQAC',
//     ['Wyoming']: 'a023K000001Yjh3QAC',
// };

// const STATES_PRODUCTION = {
//     ['Alabama']: 'a035f000002xNJMAA2',
//     ['Alaska']: 'a035f000002xNJRAA2',
//     ['Arizona']: 'a035f000002xNJNAA2',
//     ['Arkansas']: 'a035f000002xNJIAA2',
//     ['California']: 'a035f000002xNJJAA2',
//     ['Colorado']: 'a035f000002xNJqAAM',
//     ['Connecticut']: 'a035f000002xNJrAAM',
//     ['Delaware']: 'a035f000002xNJQAA2',
//     ['Florida']: 'a035f000005uNsdAAE',
//     ['Georgia']: 'a035f000002xNJfAAM',
//     ['Hawaii']: 'a035f000002xNJkAAM',
//     ['Idaho']: 'a035f000002xNJLAA2',
//     ['Illinois']: 'a035f000002xNO3AAM',
//     ['Indiana']: 'a035f000002xNO8AAM',
//     ['Iowa']: 'a035f000002xNODAA2',
//     ['Kansas']: 'a035f000002xNOFAA2',
//     ['Kentucky']: 'a035f000002xNO4AAM',
//     ['Louisiana']: 'a035f000002xNONAA2',
//     ['Maine']: 'a035f000002xNO5AAM',
//     ['Maryland']: 'a035f000002xNOGAA2',
//     ['Massachusetts']: 'a035f000002xNO9AAM',
//     ['Michigan']: 'a035f000002xNOcAAM',
//     ['Minnesota']: 'a035f000002xNJtAAM',
//     ['Mississippi']: 'a035f000002xNOOAA2',
//     ['Missouri']: 'a035f000002xNJuAAM',
//     ['Montana']: 'a035f000002xNO6AAM',
//     ['Nebraska']: 'a035f000002xNPBAA2',
//     ['Nevada']: 'a035f000002xNPGAA2',
//     ['New Hampshire']: 'a035f000002xNPMAA2',
//     ['New Jersey']: 'a035f000002xNPCAA2',
//     ['New Mexico']: 'a035f000002xNPNAA2',
//     ['New York']: 'a035f000002xNPLAA2',
//     ['North Carolina']: 'a035f000002xNJKAA2',
//     ['North Dakota']: 'a035f000002xNJcAAM',
//     ['Ohio']: 'a035f000002xNPHAA2',
//     ['Oklahoma']: 'a035f000002xNOfAAM',
//     ['Oregon']: 'a035f000002xNPIAA2',
//     ['Pennsylvania']: 'a035f000002xNOQAA2',
//     ['Rhode Island']: 'a035f000002xNORAA2',
//     ['South Carolina']: 'a035f000002xNJgAAM',
//     ['South Dakota']: 'a035f000002xNJjAAM',
//     ['Tennessee']: 'a035f000002xNPyAAM',
//     ['Texas']: 'a035f000002xNPzAAM',
//     ['Utah']: 'a035f000002xNQ3AAM',
//     ['Vermont']: 'a035f000002xNPYAA2',
//     ['Virginia']: 'a035f000002xNQ8AAM',
//     ['Washington']: 'a035f000002xNQ4AAM',
//     ['West Virginia']: 'a035f000002xNPZAA2',
//     ['Wisconsin']: 'a035f000002xNQIAA2',
//     ['Wyoming']: 'a035f000002xNQJAA2',
// };

// export const STATES_IDS =
//     process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
//         ? STATES_PRODUCTION
//         : STATES_SANDBOX;

export const states = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    'District Of Columbia': 'DC',
    'Federated States Of Micronesia': 'FM',
    Florida: 'FL',
    Georgia: 'GA',
    Guam: 'GU',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    'Marshall Islands': 'MH',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Northern Mariana Islands': 'MP',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Palau: 'PW',
    Pennsylvania: 'PA',
    'Puerto Rico': 'PR',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    'Virgin Islands': 'VI',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
};
