import React, { useState, useCallback } from 'react';

import styles from './styles.module.scss';
import { cls, Container, NavigationProps, Row, Col, Icon } from '@solublestudio/soluto-design-system';
import Link from 'Link';
import Picture from 'Picture';

export type MegaMenuProps = NavigationProps

const MegaMenu = ({
    items,
    onWrapperMouseEnter,
    onWrapperMouseLeave,
}: MegaMenuProps) => {
    const [image, setImage] = useState(null);
    const [imageAlt, setImageAlt] = useState('');
    const onMouseEnter = useCallback(
        (image, alt) => {
            setImage(image);
            setImageAlt(alt);
        },
        [],
    );
    const onMouseLeave = useCallback(
        () => {
            setImage(null);
            setImageAlt('');
        },
        [],
    );
    return (
        <div
            {...(!!onWrapperMouseEnter && {
                onMouseEnter: onWrapperMouseEnter,
            })}
            {...(!!onWrapperMouseLeave && {
                onMouseLeave: onWrapperMouseLeave,
            })}>
            <Container className='py-lg'>
                <Row>
                    <Col col={{ lg: 9 }}>
                        <ul className={styles.wrapper}>
                            {items.map(({ children, description, image, items, ...linkProps }, index) => (
                                <li key={`megamenu-item-${index}`}>
                                    <Link
                                        {...linkProps}
                                        display="block"
                                        onMouseEnter={() => onMouseEnter(image, children)}
                                        onMouseLeave={onMouseLeave}
                                        className={cls(
                                            'h100 font-weight-semi-bold pr-xxl',
                                            styles.link,
                                            linkProps.className
                                        )}
                                        classes={{
                                            active: styles.active
                                        }}>
                                        {children}
                                        <Icon name="arrowRight" size="24"/>
                                    </Link>
                                    <span className={cls('b100 d-block')}>
                                        {description}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </Col>
                    <Col col={{ lg: 3 }}>
                        <div className={styles.imageContainer}>
                            {image && (
                                <Picture
                                image={image}
                                alt={imageAlt}
                                layout="background"
                                objectFit="cover" />
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MegaMenu;
