import React from 'react';

import Button, { ButtonProps } from '@ds/components/shared/Button';

import styles from './styles.module.scss';

export interface MenuItemProps extends ButtonProps {
    active: boolean;
    underline: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ label, iconName, ...props }) => {
    return (
        <Button
            {...props}
            iconName={iconName}
            label={label}
            variant="menuItem"
            classes={{
                active: styles.active,
                parent: styles.active,
            }}
        />
    );
};

export default MenuItem;
