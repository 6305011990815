import React, { forwardRef } from 'react';

import {
    Button as ButtonSoluto,
    Icon,
    cls,
} from '@solublestudio/soluto-design-system';

import type { ButtonProps as ButtonSolutoProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

type VariantButton =
    | 'primaryLarge'
    | 'primarySmall'
    | 'secondaryLarge'
    | 'secondarySmall'
    | 'secondaryLargeWithOutBorder'
    | 'buttonIcon'
    | 'buttonIconLight'
    | 'buttonIconBorder'
    | 'buttonIconSingle'
    | 'link'
    | 'linkSmall'
    | 'menuItem';

export interface ButtonProps extends ButtonSolutoProps {
    variant: VariantButton;
    iconName?: string;
    className?: string;
    label?: string;
    detached?: boolean;
    hasRightAlignment?: boolean;
}

const variantsIconSize: { [key in VariantButton]: number } = {
    primaryLarge: 24,
    primarySmall: 16,
    secondaryLarge: 24,
    secondaryLargeWithOutBorder: 24,
    secondarySmall: 16,
    buttonIcon: 32,
    buttonIconLight: 32,
    buttonIconBorder: 24,
    buttonIconSingle: 32,
    link: 24,
    linkSmall: 24,
    menuItem: 24,
};

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
    function Button(
        {
            className,
            iconName,
            variant,
            label,
            detached = false,
            hasRightAlignment = false,
            ...props
        },
        ref,
    ) {
        return (
            <ButtonSoluto
                {...props}
                variant={variant}
                ref={ref}
                className={cls(
                    styles['button'],
                    'd-inline-flex',
                    'align-items-center',
                    variant !== 'buttonIconBorder' && 'justify-content-center',
                    styles[variant as string],
                    className,
                    hasRightAlignment && styles['right'],
                )}
                beforeContent={
                    iconName ? (
                        <Icon
                            name={iconName}
                            size={variantsIconSize[variant]}
                            classes={{
                                icon: cls(
                                    styles.icon,
                                    'd-flex',
                                    'align-items-center',
                                    'justify-content-center',
                                    !['buttonIconSingle'].includes(variant) &&
                                        'mr-tiny',
                                ),
                            }}
                        />
                    ) : undefined
                }>
                {label}
            </ButtonSoluto>
        );
    },
);

export default Button;
