import React, { useImperativeHandle, useMemo, useRef, useState } from 'react';

import {
    useElements,
    CardCvcElement,
    CardNumberElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js';

import formStyles from '../../../BaseForm/styles.module.scss';

export type PaymentCardRef = {
    setCard: (card: any) => void;
};

interface PaymentCardProps {
    errorGlobal: any;
    hiddenFields: any;
    onMakePurchase: (element: any, data: any, name: any) => Promise<void>;
    onValidatePromo?: (card: any) => Promise<void>;
}

const PaymentCard = React.forwardRef<PaymentCardRef, PaymentCardProps>(
    (
        {
            errorGlobal,
            hiddenFields,
            onMakePurchase,
            onValidatePromo,
            ...props
        },
        ref,
    ) => {
        const elements = useElements();

        const [dataCard, setDataCard] = useState({
            fullNameCard: '',
            codePromo: '',
            nameIfExist: '',
            codeApplied: false,
            idCode: '',
        });

        useImperativeHandle(
            ref,
            () => ({
                setCard(card: any) {
                    setDataCard(card);
                },
            }),
            [],
        );

        const [errorMessageCard, setErrorMessageCard] = useState({
            fullNameCard: false,
            errorCardDigits: false,
            errorExp: false,
            errorCVC: false,
        });

        const [error, setError] = useState({
            error: false,
            message: '',
        });

        const thisOnSubmit = async (ev) => {
            ev?.preventDefault();

            const element = elements?.getElement('cardNumber');
            const isValidCard = !(element._empty || element._invalid);

            const exp = elements?.getElement('cardExpiry');
            const isValidExp = !(exp._invalid || exp._empty);

            const cvc = elements?.getElement('cardCvc');
            const isValidCVC = !(cvc._invalid || cvc._empty);

            const { fullNameCard } = dataCard;

            setErrorMessageCard({
                fullNameCard: fullNameCard === '',
                errorCardDigits: !isValidCard,
                errorExp: !isValidExp,
                errorCVC: !isValidCVC,
            });

            if (!isValidCard || !isValidExp || !isValidCVC || !fullNameCard) {
                setError({
                    error: true,
                    message:
                        'One or more fields are not valid. Please, check errors and try again.',
                });
            } else {
                setError({
                    error: false,
                    message: '',
                });
            }

            await onMakePurchase(
                element,
                {
                    codeApplied: dataCard.codeApplied,
                    codePromo: dataCard.codePromo,
                    idCode: dataCard.idCode,
                },
                fullNameCard,
            );
        };

        const checkPromo = async () => {
            setDataCard({
                ...dataCard,
                nameIfExist: '',
                codeApplied: false,
            });

            if (dataCard.codePromo.length === 0) {
                setDataCard({
                    ...dataCard,
                    nameIfExist: 'Please enter a valid code.',
                    codeApplied: false,
                });
                return;
            }

            try {
                await onValidatePromo(dataCard);
            } catch (error) {
                setError({
                    error: true,
                    message: error?.message,
                });
            }
        };

        return (
            <>
                <form onSubmit={thisOnSubmit}>
                    <div className={formStyles['lb-form-row']}>
                        <div className={formStyles['lb-form-row']}>
                            <div className={formStyles['lb-form-record']}>
                                <input
                                    className={formStyles['lb-input']}
                                    type="text"
                                    name="nameCard"
                                    placeholder="Full Name"
                                    onChange={(e) => {
                                        setDataCard({
                                            ...dataCard,
                                            fullNameCard: e.target.value,
                                        });
                                        setErrorMessageCard({
                                            ...errorMessageCard,
                                            fullNameCard: e.target.value === '',
                                        });
                                        setError({ error: false, message: '' });
                                    }}
                                />
                                {errorMessageCard.fullNameCard && (
                                    <p className={formStyles['lb-input-error']}>
                                        This field is required.
                                    </p>
                                )}
                            </div>
                            <div className={formStyles['lb-form-record']}>
                                <CardNumberElement
                                    className={
                                        formStyles[
                                            `lb-input-custom-stripe${
                                                errorMessageCard.errorCardDigits
                                                    ? '-error'
                                                    : ''
                                            }`
                                        ]
                                    }
                                />
                                {errorMessageCard.errorCardDigits && (
                                    <p className={formStyles['lb-input-error']}>
                                        This field is required.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={formStyles['lb-form-row']}>
                            <div className={formStyles['lb-form-record']}>
                                <CardExpiryElement
                                    className={
                                        formStyles[
                                            `lb-input-custom-stripe${
                                                errorMessageCard.errorExp
                                                    ? '-error'
                                                    : ''
                                            }`
                                        ]
                                    }
                                />
                                {errorMessageCard.errorExp && (
                                    <p className={formStyles['lb-input-error']}>
                                        This field is required.
                                    </p>
                                )}
                            </div>
                            <div className={formStyles['lb-form-record']}>
                                <CardCvcElement
                                    className={
                                        formStyles[
                                            `lb-input-custom-stripe${
                                                errorMessageCard.errorCVC
                                                    ? '-error'
                                                    : ''
                                            }`
                                        ]
                                    }
                                />
                                {errorMessageCard.errorCVC && (
                                    <p className={formStyles['lb-input-error']}>
                                        This field is required.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={formStyles['lb-form-row']}>
                            <div className={formStyles['lb-form-textCard']}>
                                <p
                                    className={
                                        formStyles['lb-form-textCard-text']
                                    }>
                                    Promotional Code
                                </p>
                            </div>
                        </div>
                        <div className={formStyles['lb-form-row']}>
                            <div className={formStyles['lb-form-record']}>
                                <input
                                    className={formStyles['lb-input']}
                                    onChange={(e) =>
                                        setDataCard({
                                            ...dataCard,
                                            codePromo: e.target.value,
                                        })
                                    }
                                    type="text"
                                    name="promoCode"
                                    placeholder="Promotional Code"
                                />
                                <button
                                    onClick={() => {
                                        checkPromo();
                                    }}
                                    className={formStyles['lb-form-btn-apply']}>
                                    Apply Code
                                </button>
                                {dataCard.nameIfExist.length > 0 && (
                                    <p
                                        className={
                                            formStyles[
                                                `lb-input-${
                                                    dataCard.codeApplied
                                                        ? 'valid'
                                                        : 'error'
                                                }`
                                            ]
                                        }>
                                        {dataCard.codeApplied
                                            ? `Promo ${dataCard.nameIfExist} Applied!`
                                            : 'Please enter a valid code.'}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div
                            className={`${formStyles['lb-form-row-submit']} ${formStyles['lb-mt-100']}`}>
                            {errorGlobal.error && (
                                <p
                                    className={
                                        formStyles['lb-form-btn-s-error']
                                    }>
                                    {errorGlobal.message}
                                </p>
                            )}
                            {error.error && (
                                <p
                                    className={
                                        formStyles['lb-form-btn-s-error']
                                    }>
                                    {error.message}
                                </p>
                            )}
                            <button
                                disabled={props.loading}
                                className={formStyles['lb-form-btn']}
                                type="submit">
                                <span className={formStyles['lb-form-btn-s']}>
                                    Purchase
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </>
        );
    },
);

export default PaymentCard;
