import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Picture, { PictureProps } from 'Picture';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import HTMLCheckListComponent from '@ds/components/shared/HTMLComponent/CheckList';

import styles from './styles.module.scss';

export interface CardPlanLargeProps {
    image: PictureProps;
    text: string;
    subtitle: string;
    title: string;
}

const CardPlanLarge = ({
    image,
    text,
    subtitle,
    title,
}: CardPlanLargeProps) => {
    return (
        <div className={cls(styles.card, 'd-flex', 'flex-column-reverse')}>
            <div
                className={cls(
                    'bg-base-1000',
                    'px-grid-padding',
                    'py-xg',
                    'd-flex',
                    'flex-column',
                    styles.info,
                )}>
                <Text font="b100" component="p" className={cls('mb-modulor')}>
                    {subtitle}
                </Text>
                <Heading font="h100" component="h4" className={cls('mb-md')}>
                    {title}
                </Heading>
                <HTMLCheckListComponent text={text} />
            </div>
            <div className={cls('position-relative', styles.image)}>
                <Picture
                    image={image}
                    alt={subtitle}
                    layout="background"
                    objectFit="cover"
                />
            </div>
        </div>
    );
};

export default CardPlanLarge;
