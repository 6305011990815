import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import ProgressBar from '@ds/components/shared/ProgressBar';

import styles from './styles.module.scss';

import SubscriptionForm, {
    SubscriptionFormProps,
} from '@ds/components/shared/Forms/SubscriptionForm';

export interface SubscriptionFormSectionProps extends SubscriptionFormProps {}

const SubscriptionFormSection = ({
    currentStep = 1,
    hiddenFields,
    ...props
}: SubscriptionFormSectionProps) => {
    return (
        <section className={cls('bg-base-1000 mb-huge', styles.wrapper)}>
            <ProgressBar
                total={2}
                current={currentStep}
                label="Subscription steps"
            />
            <div className={cls('pt-xg', styles.content)}>
                <SubscriptionForm
                    {...props}
                    currentStep={currentStep}
                    hiddenFields={hiddenFields}
                />
            </div>
        </section>
    );
};

export default SubscriptionFormSection;
