import React from 'react';

import { cls, Icon } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

export interface StepItemProps {
    icon: string;
    title: string;
    text: string;
    className?: string;
}

const StepItem = ({ icon, title, text, className }: StepItemProps) => {
    return (
        <div className={cls(className)}>
            <Icon
                name={icon}
                size={64}
                classes={{
                    icon: cls('text-primary-dark'),
                }}
            />
            <Heading component="h3" font="b400" className={cls('my-xs')}>
                {title}
            </Heading>
            <Text component="div" font="b300">
                {text}
            </Text>
        </div>
    );
};

export default StepItem;
