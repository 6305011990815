import React, { useState, useEffect, RefObject } from 'react';
import { isValidPhoneNumber, CountryCode } from 'libphonenumber-js';
import { Icon, Text, cls } from '@solublestudio/soluto-design-system';

import countries from '../BaseForm/countryCodes';
import formStyles from '../BaseForm/styles.module.scss';

import PaymentCard, { PaymentCardRef } from './components/PaymentCard';
import { PayPalButtons } from '@paypal/react-paypal-js';
import AdyenPayment from './components/AdyenPayment';

import PaymentCardsImage from '../../../../img/checkout_payment-cards.png';

type Plan = {
    stripePrice: string;
    price: string;
    plan: string;
    extraCharge: string;
};
export interface SubscriptionFormProps {
    onSubmit: (form: any) => Promise<void>;
    userBlocked: boolean;
    hiddenFields: any;
    currentStep?: number;
    typeForm: 'default' | 'promo';
    plans: Record<'annual' | 'monthly' | 'promo', Plan>;
    paymentCardRef: RefObject<PaymentCardRef>;
    client: 'paypal' | 'stripe' | 'adyen';
    paypal?: {
        createSubscription: (
            data: any,
            actions: any,
            form: any,
        ) => Promise<any>;
        onApprove: (data: any, actions: any, form: any) => Promise<any>;
        onError: (err: any) => Promise<any>;
        onCancel: (data: any) => Promise<any>;
    };
    stripe?: {
        onMakePurchase: (data: any, form: any) => Promise<void>;
        onValidatePromo: (promo: string) => Promise<void>;
    };
    adyen?: {
        onLoad: (
            plan: string,
            email: string,
        ) => Promise<{ sessionData: string; id: string }>;
        onApprove: (form: any) => Promise<any>;
    };
    states: {
        name: string;
    }[];
    disclaimerText: (price: string, plan: string) => string;
}

const SubscriptionForm = ({
    onSubmit,
    hiddenFields,
    states,
    currentStep = 1,
    typeForm = 'default',
    plans,
    paymentCardRef,
    client = 'paypal',
    stripe,
    paypal,
    disclaimerText,
    adyen,
}: SubscriptionFormProps) => {
    const { promo, annual, monthly } = plans;

    const initSelectModals = {
        country: false,
        state: false,
    };

    const [planSelected, setPlanSelected] = useState(
        typeForm === 'promo' ? { ...promo } : { ...annual },
    );

    const [selectsModals, setSelectsModals] = useState({ ...initSelectModals });

    const [loading, setLoading] = useState(false);
    const [fieldError, setFieldError] = useState({
        name: {
            error: false,
            type: 'text',
        },
        lastName: {
            error: false,
            type: 'text',
        },
        email: {
            error: false,
            type: 'mail',
        },
        phone: {
            error: false,
            type: 'phone',
        },
        address: {
            error: false,
            type: 'text',
        },
        city: {
            error: false,
            type: 'text',
        },
        state: {
            error: false,
            type: 'text',
        },
        zipcode: {
            error: false,
            type: 'postalCode',
        },
    });

    const [form, setForm] = useState({
        name: '',
        email: '',
        lastName: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        plan: planSelected.plan,
        price: planSelected.price,
        LeadSource: 'Purchase path',
        source: 'Purchase path',
    });

    const [errorGlobal, setErrorGlobal] = useState({
        error: false,
        message: '',
    });

    const country = countries.findIndex((c) => c.code == 'US');

    const [countrySetter, setCountrySetter] = useState(country);
    const [countriesShow, setCountriesShow] = useState(countries);
    const [stateSelected, setStateSelected] = useState(-1);

    // Adyen
    const [session, setSession] = useState<
        | {
              sessionData: string;
              id: string;
          }
        | undefined
    >();

    useEffect(() => {
        if (currentStep === 1) return;
        if (client !== 'adyen') return;
        const onLoad = async () => {
            try {
                const session = await adyen?.onLoad(form.plan, form.email);
                setSession(session);
            } catch (error) {
                let message = '';
                if (error instanceof Error) message = error.message;
                else message = String(error);
                setErrorGlobal({
                    error: true,
                    message,
                });
            }
        };
        onLoad();
    }, [currentStep, client, adyen, form.plan]);

    const cleanError = (nameImput: any, e: any) => {
        const error = fieldError[`${nameImput}`];
        error.error = false;
        setErrorGlobal({
            error: false,
            message: '',
        });
        setFieldError({ ...fieldError, [`${nameImput}`]: error });
        setForm({ ...form, [`${nameImput}`]: e.target.value });
    };

    const changeCountriesList = (value: any) => {
        setCountriesShow(
            countries.filter((c) => c.name.search(new RegExp(value, 'i')) > -1),
        );
    };

    const closeSelects = () => {
        setSelectsModals({
            country: false,
            state: false,
        });
    };

    const closeModal = () => {
        setSelectsModals({
            country: false,
            state: false,
        });
        window.removeEventListener('click', closeModal);
    };

    const openModal = (e: any, key: String) => {
        e.stopPropagation();

        const newModalsState = { ...initSelectModals, [`${key}`]: true };
        setSelectsModals(newModalsState);
        window.addEventListener('click', closeModal);
    };

    const changeCountry = (code: any) => {
        setCountrySetter(countries.findIndex((c) => c.code === code));
        setCountriesShow(countries);
        closeSelects();
    };

    const changeState = (e: any, i: any) => {
        e.stopPropagation();
        closeSelects();
        setFieldError({
            ...fieldError,
            state: {
                error: false,
                type: 'text',
            },
        });
        setStateSelected(i);
        setForm({ ...form, state: states[i].name });
    };

    const changePlan = (typePlan: any) => {
        setForm({ ...form, plan: typePlan.plan, price: typePlan.price });
        setPlanSelected({ ...typePlan });
    };

    const validateText = (nameInput: any) => {
        const value = form[`${nameInput}`];
        return !value;
    };

    const validateEmail = (nameInput: any) => {
        return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            form[`${nameInput}`],
        );
    };

    const validatePhone = (nameInput: any) => {
        const phone = form[`${nameInput}`];
        const countryCode =
            (countries[countrySetter]?.code as CountryCode) ?? 'US';

        return !isValidPhoneNumber(phone, countryCode);
    };

    const validatePostalCode = (nameInput: any) => {
        return form[`${nameInput}`].length != 5;
    };

    const validateForm = () => {
        const fields = JSON.parse(JSON.stringify(fieldError));

        for (let key in fields) {
            if (fields.hasOwnProperty(key)) {
                switch (fields[key].type) {
                    case 'text':
                        fields[key].error = validateText(key);
                        break;
                    case 'mail':
                        fields[key].error = validateEmail(key);
                        break;
                    case 'phone':
                        fields[key].error = validatePhone(key);
                        break;
                    case 'postalCode':
                        fields[key].error = validatePostalCode(key);
                        break;
                    default:
                        break;
                }
            }
        }

        const hasErrors = Object.entries(fields).findIndex(
            (array) => !fields[`${array[0]}`].error === false,
        );
        setFieldError({
            ...fields,
        });

        return hasErrors > -1;
    };

    const thisOnSubmit = async (e: any) => {
        e.preventDefault();

        const isinValid = validateForm();

        if (isinValid) {
            setErrorGlobal({
                error: true,
                message:
                    'One or more fields are not valid. Please, check errors and try again.',
            });
            return;
        }

        setLoading(true);

        try {
            await onSubmit(form);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setErrorGlobal({
                error: true,
                message: e.message,
            });
            setLoading(false);
        }
    };

    const thisMakePurchase = async (
        element: any,
        dataCard: any,
        fullNameCard: any,
    ) => {
        setErrorGlobal({
            error: false,
            message: '',
        });

        setLoading(true);

        try {
            await stripe?.onMakePurchase(
                {
                    element,
                    dataCard,
                    fullNameCard,
                },
                form,
            );
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrorGlobal({
                error: true,
                message: error.message,
            });
            setLoading(false);
        }
    };

    const thisCreateSubscription = async (data, actions) => {
        setErrorGlobal({
            error: false,
            message: '',
        });

        setLoading(true);

        try {
            return await paypal?.createSubscription(data, actions, form);
        } catch (error) {
            setErrorGlobal({
                error: true,
                message: error.message,
            });
        } finally {
            setLoading(false);
        }
    };

    const thisOnError = async (data) => {
        setErrorGlobal({
            error: false,
            message: '',
        });

        try {
            return await paypal?.onError(data);
        } catch (error) {
            setErrorGlobal({
                error: true,
                message: error.message,
            });
        }
    };

    const thisOnCancel = async (data, actions) => {
        setErrorGlobal({
            error: false,
            message: '',
        });

        try {
            return await paypal?.onCancel(data);
        } catch (error) {
            setErrorGlobal({
                error: true,
                message: error.message,
            });
        }
    };

    const thisOnApprovePaypal = async (data, actions) => {
        setErrorGlobal({
            error: false,
            message: '',
        });

        try {
            return await paypal?.onApprove(data, actions, form);
        } catch (error) {
            let message = '';
            if (error instanceof Error) message = error.message;
            else message = String(error);
            setErrorGlobal({
                error: true,
                message,
            });
        }
    };

    const thisOnApproveAdyen = async () => {
        setErrorGlobal({
            error: false,
            message: '',
        });

        try {
            return await adyen?.onApprove(form);
        } catch (error) {
            let message = '';
            if (error instanceof Error) message = error.message;
            else message = String(error);
            setErrorGlobal({
                error: true,
                message,
            });
        }
    };

    return (
        <>
            {currentStep <= 1 ? (
                <h1 className={formStyles['lb-form-title']}>
                    <span className={formStyles['lb-form-title-currentStep']}>
                        01
                    </span>{' '}
                    Personal Information
                </h1>
            ) : (
                <h1 className={formStyles['lb-form-title']}>
                    <span className={formStyles['lb-form-title-currentStep']}>
                        02
                    </span>{' '}
                    Subscription
                </h1>
            )}
            {currentStep > 1 ? (
                <div>
                    {typeForm === 'promo' ? (
                        <div className={formStyles['lb-form-row']}>
                            <div className={formStyles['lb-form-record']}>
                                <div
                                    className={
                                        formStyles[
                                            'lb-form-messagge-limted-time'
                                        ]
                                    }>
                                    <p
                                        className={
                                            formStyles[
                                                'lb-form-messagge-limted-time-title'
                                            ]
                                        }>
                                        Limited time offer
                                    </p>
                                    <div
                                        className={
                                            formStyles[
                                                'lb-form-messagge-limted-time-table'
                                            ]
                                        }>
                                        <div
                                            className={
                                                formStyles[
                                                    'lb-form-messagge-limted-time-table-row'
                                                ]
                                            }>
                                            <span
                                                className={
                                                    formStyles[
                                                        'lb-form-messagge-limted-time-table-text-underline'
                                                    ]
                                                }>
                                                Monthly fee
                                            </span>
                                            <span
                                                className={
                                                    formStyles[
                                                        'lb-form-messagge-limted-time-table-text-underline'
                                                    ]
                                                }>
                                                $39.99
                                            </span>
                                        </div>
                                        <div
                                            className={
                                                formStyles[
                                                    'lb-form-messagge-limted-time-table-row'
                                                ]
                                            }>
                                            <span
                                                className={
                                                    formStyles[
                                                        'lb-form-messagge-limted-time-table-text'
                                                    ]
                                                }>
                                                Monthly fee
                                            </span>
                                            <span
                                                className={
                                                    formStyles[
                                                        'lb-form-messagge-limted-time-table-text'
                                                    ]
                                                }>
                                                $24.99
                                            </span>
                                        </div>
                                        <div
                                            className={
                                                formStyles[
                                                    'lb-form-messagge-limted-time-table-row'
                                                ]
                                            }>
                                            <span
                                                className={
                                                    formStyles[
                                                        'lb-form-messagge-limted-time-table-one'
                                                    ]
                                                }>
                                                Enrollment fee
                                            </span>
                                            <span
                                                className={
                                                    formStyles[
                                                        'lb-form-messagge-limted-time-table-one'
                                                    ]
                                                }>
                                                (just once) $95.00
                                            </span>
                                        </div>
                                        <div
                                            className={
                                                formStyles[
                                                    'lb-form-messagge-limted-time-table-space'
                                                ]
                                            }></div>
                                        <div
                                            className={
                                                formStyles[
                                                    'lb-form-messagge-limted-time-table-row'
                                                ]
                                            }>
                                            <span
                                                className={
                                                    formStyles[
                                                        'lb-form-messagge-limted-time-table-one'
                                                    ]
                                                }>
                                                Total Due Today
                                            </span>
                                            <span
                                                className={
                                                    formStyles[
                                                        'lb-form-messagge-limted-time-table-one'
                                                    ]
                                                }>
                                                $119.99
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={formStyles['lb-form-record']}>
                                <div className={formStyles['lb-form-review']}>
                                    <div
                                        className={
                                            formStyles[
                                                'lb-form-review-container'
                                            ]
                                        }>
                                        <div>
                                            <p
                                                className={
                                                    formStyles[
                                                        'lb-form-review-comment'
                                                    ]
                                                }>
                                                “Great Service with Customer
                                                Support Team!! Great Attorneys!!
                                                I can't say enough about the
                                                wealth of value, information and
                                                support. It's one of the best
                                                things I've signed up for!”
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                formStyles[
                                                    'lb-form-review-down'
                                                ]
                                            }>
                                            <p
                                                className={
                                                    formStyles[
                                                        'lb-form-review-comment'
                                                    ]
                                                }>
                                                Dee U.
                                            </p>
                                            <p
                                                className={
                                                    formStyles[
                                                        'lb-form-review-member'
                                                    ]
                                                }>
                                                LegalBrave Member, CA
                                            </p>
                                            <div
                                                className={
                                                    formStyles[
                                                        'lb-form-review-icons-container'
                                                    ]
                                                }>
                                                <i
                                                    className={
                                                        formStyles[
                                                            'lb-form-review-icons'
                                                        ]
                                                    }></i>
                                                <i
                                                    className={
                                                        formStyles[
                                                            'lb-form-review-icons'
                                                        ]
                                                    }></i>
                                                <i
                                                    className={
                                                        formStyles[
                                                            'lb-form-review-icons'
                                                        ]
                                                    }></i>
                                                <i
                                                    className={
                                                        formStyles[
                                                            'lb-form-review-icons'
                                                        ]
                                                    }></i>
                                                <i
                                                    className={
                                                        formStyles[
                                                            'lb-form-review-icons'
                                                        ]
                                                    }></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            formStyles[
                                                'lb-form-review-container'
                                            ]
                                        }>
                                        <div>
                                            <p
                                                className={
                                                    formStyles[
                                                        'lb-form-review-comment'
                                                    ]
                                                }>
                                                "I'm just very grateful and
                                                appreciative of becoming a
                                                member 🙏 🙌 God Bless"
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                formStyles[
                                                    'lb-form-review-down'
                                                ]
                                            }>
                                            <p
                                                className={
                                                    formStyles[
                                                        'lb-form-review-comment'
                                                    ]
                                                }>
                                                Jamie Williams
                                            </p>
                                            <p
                                                className={
                                                    formStyles[
                                                        'lb-form-review-member'
                                                    ]
                                                }>
                                                LegalBrave Member, CA
                                            </p>
                                            <div
                                                className={
                                                    formStyles[
                                                        'lb-form-review-icons-container'
                                                    ]
                                                }>
                                                <i
                                                    className={
                                                        formStyles[
                                                            'lb-form-review-icons'
                                                        ]
                                                    }></i>
                                                <i
                                                    className={
                                                        formStyles[
                                                            'lb-form-review-icons'
                                                        ]
                                                    }></i>
                                                <i
                                                    className={
                                                        formStyles[
                                                            'lb-form-review-icons'
                                                        ]
                                                    }></i>
                                                <i
                                                    className={
                                                        formStyles[
                                                            'lb-form-review-icons'
                                                        ]
                                                    }></i>
                                                <i
                                                    className={
                                                        formStyles[
                                                            'lb-form-review-icons'
                                                        ]
                                                    }></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={formStyles['lb-form-row']}>
                            <div className={formStyles['lb-form-record']}>
                                <div
                                    onClick={() => {
                                        changePlan(annual);
                                    }}
                                    className={formStyles['lb-form-btn-subs']}>
                                    <div
                                        className={
                                            formStyles['lb-form-btn-subs-tag']
                                        }>
                                        <p
                                            className={
                                                formStyles[
                                                    'lb-form-btn-subs-tag-text'
                                                ]
                                            }>
                                            Get a free month
                                        </p>
                                    </div>
                                    <div
                                        className={
                                            formStyles[
                                                `lb-form-btn-subs-radius${
                                                    planSelected.stripePrice ===
                                                    annual.stripePrice
                                                        ? '-active'
                                                        : ''
                                                }`
                                            ]
                                        }></div>
                                    <div
                                        className={
                                            formStyles['lb-form-btn-subs-text']
                                        }>
                                        <p
                                            className={
                                                formStyles[
                                                    'lb-form-btn-subs-text-title'
                                                ]
                                            }>
                                            Semi-annual
                                        </p>
                                        <p>
                                            <span
                                                className={
                                                    formStyles[
                                                        'lb-form-btn-subs-text-price'
                                                    ]
                                                }>
                                                {annual.price}/
                                            </span>
                                            semi-annual
                                        </p>
                                        <p
                                            className={
                                                formStyles[
                                                    'lb-form-btn-subs-text-save'
                                                ]
                                            }>
                                            Save {monthly.price}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={formStyles['lb-form-record']}>
                                <div
                                    onClick={() => {
                                        changePlan(monthly);
                                    }}
                                    className={formStyles['lb-form-btn-subs']}>
                                    <div
                                        className={
                                            formStyles[
                                                `lb-form-btn-subs-radius${
                                                    planSelected.stripePrice ===
                                                    monthly.stripePrice
                                                        ? '-active'
                                                        : ''
                                                }`
                                            ]
                                        }></div>
                                    <div
                                        className={
                                            formStyles['lb-form-btn-subs-text']
                                        }>
                                        <p
                                            className={
                                                formStyles[
                                                    'lb-form-btn-subs-text-title'
                                                ]
                                            }>
                                            Monthly
                                        </p>
                                        <p>
                                            <span
                                                className={
                                                    formStyles[
                                                        'lb-form-btn-subs-text-price'
                                                    ]
                                                }>
                                                {monthly.price}/
                                            </span>
                                            month
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={formStyles['lb-form-row']}>
                        <div className={formStyles['lb-form-textCard']}>
                            <p className={formStyles['lb-form-textCard-text']}>
                                Pay with Credit/Debit card
                            </p>
                            <img
                                className={formStyles['lb-form-textCard-img']}
                                {...PaymentCardsImage}
                            />
                        </div>
                    </div>
                    <div className={formStyles['lb-form-row']}>
                        {client === 'stripe' && (
                            <PaymentCard
                                ref={paymentCardRef}
                                errorGlobal={errorGlobal}
                                loading={loading}
                                onValidatePromo={stripe?.onValidatePromo}
                                onMakePurchase={thisMakePurchase}
                                hiddenFields={hiddenFields}
                            />
                        )}
                        {client === 'paypal' && (
                            <>
                                <div
                                    className={
                                        formStyles['lb-form-row-submit']
                                    }>
                                    {errorGlobal.error && (
                                        <p
                                            className={
                                                formStyles[
                                                    'lb-form-btn-s-error'
                                                ]
                                            }>
                                            {errorGlobal.message}
                                        </p>
                                    )}
                                    <PayPalButtons
                                        createSubscription={
                                            thisCreateSubscription
                                        }
                                        onApprove={thisOnApprovePaypal}
                                        onError={thisOnError}
                                        onCancel={thisOnCancel}
                                        forceReRender={[form]}
                                    />
                                </div>
                            </>
                        )}
                        {client === 'adyen' && (
                            <div className={formStyles['lb-form-row-submit']}>
                                {errorGlobal.error && (
                                    <p
                                        className={
                                            formStyles['lb-form-btn-s-error']
                                        }>
                                        {errorGlobal.message}
                                    </p>
                                )}
                                <AdyenPayment
                                    session={session}
                                    onSuccess={thisOnApproveAdyen}
                                    onError={({ message }) =>
                                        setErrorGlobal({
                                            error: true,
                                            message,
                                        })
                                    }
                                />
                            </div>
                        )}
                        <div className={formStyles['lb-form-row']}>
                            <div
                                className={
                                    formStyles['lb-form-row-terms-container']
                                }>
                                <Text
                                    component="div"
                                    className={cls(
                                        'b050',
                                        formStyles['lb-form-row-terms-text'],
                                    )}>
                                    {disclaimerText(form.price, form.plan)}
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <form onSubmit={thisOnSubmit}>
                    <div className={formStyles['lb-form-row']}>
                        <div className={formStyles['lb-form-record']}>
                            <input
                                className={
                                    formStyles[
                                        `lb-input${
                                            fieldError.name.error
                                                ? '-error-border'
                                                : ''
                                        }`
                                    ]
                                }
                                onChange={(e) => cleanError('name', e)}
                                type="text"
                                name="name"
                                placeholder="First Name"
                            />
                            {fieldError.name.error && (
                                <p className={formStyles['lb-input-error']}>
                                    This field is required.
                                </p>
                            )}
                        </div>
                        <div className={formStyles['lb-form-record']}>
                            <input
                                className={
                                    formStyles[
                                        `lb-input${
                                            fieldError.lastName.error
                                                ? '-error-border'
                                                : ''
                                        }`
                                    ]
                                }
                                onChange={(e) => cleanError('lastName', e)}
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                            />
                            {fieldError.lastName.error && (
                                <p className={formStyles['lb-input-error']}>
                                    This field is required.
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={formStyles['lb-form-row']}>
                        <div className={formStyles['lb-form-record']}>
                            <input
                                className={
                                    formStyles[
                                        `lb-input${
                                            fieldError.email.error
                                                ? '-error-border'
                                                : ''
                                        }`
                                    ]
                                }
                                onChange={(e) => cleanError('email', e)}
                                type="email"
                                name="email"
                                inputMode="email"
                                placeholder="Email"
                                autoComplete="email"
                            />
                            {fieldError.email.error && (
                                <p className={formStyles['lb-input-error']}>
                                    {form.email === ''
                                        ? 'This field is required.'
                                        : 'Please, enter a valid email.'}
                                </p>
                            )}
                        </div>
                        <div className={formStyles['lb-form-record']}>
                            {selectsModals.country && (
                                <div
                                    className={
                                        formStyles['lb-input-select-options']
                                    }>
                                    <div
                                        className={
                                            formStyles[
                                                'lb-input-select-options-search'
                                            ]
                                        }>
                                        <input
                                            onClick={(e) => {
                                                openModal(e, 'country');
                                            }}
                                            onChange={(e) =>
                                                changeCountriesList(
                                                    e.target.value,
                                                )
                                            }
                                            placeholder="Type name of country"
                                            className={
                                                formStyles[
                                                    'lb-input-select-options-input'
                                                ]
                                            }
                                            type="text"
                                        />
                                    </div>
                                    {countriesShow.map(function (object, i) {
                                        return (
                                            <div
                                                key={i}
                                                onClick={() =>
                                                    changeCountry(object.code)
                                                }
                                                className={
                                                    formStyles[
                                                        'lb-input-select-options-row'
                                                    ]
                                                }>
                                                <p>
                                                    {object.emoji}{' '}
                                                    <span>{object.name}</span>
                                                </p>
                                                <p>{object.dial_code}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            <div
                                onClick={(e) => openModal(e, 'country')}
                                className={
                                    formStyles[
                                        `lb-input-select-box${
                                            fieldError.phone.error
                                                ? '-error'
                                                : ''
                                        }`
                                    ]
                                }>
                                <div
                                    className={
                                        formStyles[
                                            'lb-input-select-container-flags'
                                        ]
                                    }>
                                    <p>{countries[countrySetter].emoji}</p>
                                    <p>{countries[countrySetter].dial_code}</p>
                                    <div
                                        className={
                                            formStyles[
                                                'lb-input-select-container-flags-icon'
                                            ]
                                        }></div>
                                </div>
                            </div>
                            <input
                                className={
                                    formStyles[
                                        `lb-input-select${
                                            fieldError.phone.error
                                                ? '-error'
                                                : ''
                                        }`
                                    ]
                                }
                                onChange={(e) => cleanError('phone', e)}
                                type="tel"
                                name="phone"
                                placeholder="(000) 000-0000"
                                autoComplete="tel"
                            />
                            {fieldError.phone.error && (
                                <p className={formStyles['lb-input-error']}>
                                    {form.phone === ''
                                        ? 'This field is required.'
                                        : 'Please, enter a valid phone number.'}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={formStyles['lb-form-row']}>
                        <div className={formStyles['lb-form-record']}>
                            <input
                                className={
                                    formStyles[
                                        `lb-input${
                                            fieldError.address.error
                                                ? '-error-border'
                                                : ''
                                        }`
                                    ]
                                }
                                onChange={(e) => cleanError('address', e)}
                                type="text"
                                name="address"
                                placeholder="Address"
                            />
                            {fieldError.address.error && (
                                <p className={formStyles['lb-input-error']}>
                                    This field is required.
                                </p>
                            )}
                        </div>
                        <div className={formStyles['lb-form-record']}>
                            <input
                                className={
                                    formStyles[
                                        `lb-input${
                                            fieldError.city.error
                                                ? '-error-border'
                                                : ''
                                        }`
                                    ]
                                }
                                onChange={(e) => cleanError('city', e)}
                                type="text"
                                name="city"
                                placeholder="City"
                            />
                            {fieldError.city.error && (
                                <p className={formStyles['lb-input-error']}>
                                    This field is required.
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={formStyles['lb-form-row']}>
                        <div className={formStyles['lb-form-record']}>
                            <div
                                onClick={(e) => openModal(e, 'state')}
                                className={
                                    formStyles[
                                        `lb-input${
                                            fieldError.state.error
                                                ? '-error-border'
                                                : ''
                                        }`
                                    ]
                                }>
                                {selectsModals.state && (
                                    <div
                                        className={
                                            formStyles[
                                                'lb-input-select-options'
                                            ]
                                        }>
                                        {states.map(function (state, i) {
                                            return (
                                                <div
                                                    key={i}
                                                    onClick={(e) =>
                                                        changeState(e, i)
                                                    }
                                                    className={
                                                        formStyles[
                                                            'lb-input-select-options-row'
                                                        ]
                                                    }>
                                                    <p>{state.name}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                <p
                                    className={
                                        formStyles[
                                            'lb-input-select-states-text'
                                        ]
                                    }>
                                    {stateSelected >= 0
                                        ? states[stateSelected].name
                                        : 'Select your State'}
                                </p>
                            </div>
                            {fieldError.state.error && (
                                <p className={formStyles['lb-input-error']}>
                                    You have to select an option.
                                </p>
                            )}
                            {/* <input
className={formStyles['lb-input']}
onChange={e => setForm({ ...form, state: e.target.value })}
type="text" name="state" placeholder="State" /> */}
                        </div>
                        <div className={formStyles['lb-form-record']}>
                            <input
                                className={
                                    formStyles[
                                        `lb-input${
                                            fieldError.zipcode.error
                                                ? '-error-border'
                                                : ''
                                        }`
                                    ]
                                }
                                onChange={(e) => cleanError('zipcode', e)}
                                type="text"
                                name="zipcode"
                                inputMode="decimal"
                                placeholder="ZIP"
                                max="99999"
                                autoComplete="off"
                            />
                            {fieldError.zipcode.error && (
                                <p className={formStyles['lb-input-error']}>
                                    The zipcode should have 5 digits
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={formStyles['lb-form-row-submit']}>
                        {errorGlobal.error && (
                            <p className={formStyles['lb-form-btn-s-error']}>
                                {errorGlobal.message}
                            </p>
                        )}
                        <button
                            disabled={loading}
                            className={formStyles['lb-form-btn']}
                            type="submit">
                            {loading && (
                                <Icon
                                    name="loading"
                                    size={24}
                                    classes={{
                                        icon: formStyles.loading,
                                    }}
                                />
                            )}
                            <span
                                className={cls(
                                    formStyles['lb-form-btn-s'],
                                    loading && formStyles.disabled,
                                )}>
                                Continue
                            </span>
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};

export default SubscriptionForm;
