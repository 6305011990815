import React from 'react';
import {
    Container,
    cls,
} from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';

import CardIcon, {
    CardIconProps,
} from '@ds/components/shared/Cards/Icon';

import styles from './styles.module.scss';
import { Heading } from '../../../..';

export interface CardIconSectionProps {
    title: string;
    items: CardIconProps[];
    anchor?: string;
}

const CardIconSection: React.FC<CardIconSectionProps> = ({
    title,
    items = [],
    anchor,
}) => {
    return (
        <Section anchor={anchor}>
            <Container>
                <Heading component="h2" font="h200" className='mb-md'>{title}</Heading>
                <div
                    className={cls('d-flex', styles.wrapper)}>
                    {items.map((item, i) => {
                        return (
                            <CardIcon {...item} key={`contact-item-${i}`} />
                        );
                    })}
                </div>
            </Container>
        </Section>
    );
};

export default CardIconSection;
