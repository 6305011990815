import React, { useEffect, useState } from 'react';


import validateForm from './../BaseForm/ValidatorForm';
import countries from './../BaseForm/countryCodes';

import formStyles from './../BaseForm/styles.module.scss';

interface DownloadFormProps {
    onSubmit: (data: any) => Promise<void>;
}

const DownloadForm: React.FC<DownloadFormProps> = ({
    onSubmit,
}) => {
    const [countriesShow, setCountriesShow] = useState(countries);
    const [isSending, setIsSending] = useState(false);


    const [errorGlobal, setErrorGlobal] = useState({
        error: false,
        message: '',
    });


    const [fields, setFields] = useState([
        {
            fieldName: 'name',
            type: 'text',
            required: true,
            options: { min: 1, max: 100 },
            value: '',
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'phone',
            type: 'phone',
            required: true,
            options: { digit: 10, countrySupport: ['US'] },
            value: '',
            error: {
                error: false,
                message: 'error',
            },
        },
        {
            fieldName: 'email',
            type: 'mail',
            required: true,
            options: {},
            value: '',
            error: {
                error: false,
                message: 'error',
            },
        },
    ]);

    const initSelectModals = {
        country: false,
    };
    const [selectsModals, setSelectsModals] = useState({ ...initSelectModals });
    const country = countries.findIndex((c) => c.code === 'US');
    const [countrySetter, setCountrySetter] = useState(country);

    useEffect(() => {
        const updateModals = () => {
            setSelectsModals({
                country: false,
            });
        };

        document.addEventListener('click', (e) => {
            updateModals();
        });

        return function cleanup() {
            document.removeEventListener('click', (e) => {
                updateModals();
            });
        };
    }, []);

    const getValue = (name: any) => {
        const input = fields.find((field) => field.fieldName === name);
        return input?.value;
    };

    const getError = (name: any) => {
        const input = fields.find((filed) => filed.fieldName === name);
        return input?.error;
    };

    const submitContact = async (ev) => {
        ev?.preventDefault();

        const { newFields, isValid } = validateForm.formCreateUser(fields);

        setFields(newFields);
        setIsSending(true);

        if (!isValid) {
            setIsSending(false);
            return;
        }

        let data = {
            source: 'Documentos Legales',
            lastName: null,
            message: null,
        };

        for (let i = 0; i < newFields.length; i++) {
            const { value, fieldName } = newFields[i];
            data = { ...data, [`${fieldName}`]: value };
        }

        try {
            await onSubmit(data);

            setIsSending(false);
            
        } catch (e) {
            setErrorGlobal({
                error: true,
                message: e.message,
            });
            setIsSending(false);
        }

    };

    const onChange = (nameImput: any, e: any) => {
        const updatedFields = fields.map((field) => {
            if (field.fieldName === nameImput) {
                return {
                    ...field,
                    value: e.target.value,
                    error: { error: false, message: '' },
                };
            }
            return field;
        });

        setFields(updatedFields);
    };

    const changeCountriesList = (value: any) => {
        setCountriesShow(
            countries.filter((c) => c.name.search(new RegExp(value, 'i')) > -1),
        );
    };

    const openModal = (e: any, key: String) => {
        e.stopPropagation();

        const newModalsState = { ...initSelectModals, [`${key}`]: true };
        setSelectsModals(newModalsState);
    };

    const closeSelects = () => {
        setSelectsModals({
            country: false,
        });
    };

    const changeCountry = (code: any) => {
        setCountrySetter(countries.findIndex((c) => c.code === code));
        setCountriesShow(countries);
        closeSelects();
    };

    return (
        <form onSubmit={submitContact}>
            <div className={formStyles['lb-form-row']}>
                <label className={formStyles['lb-form-label-editor']}>
                    Name *
                </label>
                <input
                    onChange={(e) => onChange('name', e)}
                    defaultValue={getValue('name')}
                    className={
                        formStyles[
                            `lb-input${
                                getError('name')?.error ? '-error-border' : ''
                            }`
                        ]
                    }
                    type="text"
                    name="name"
                    placeholder="Full Name"
                />
                {getError('name')?.error && (
                    <p className={formStyles['lb-input-error']}>
                        {getError('name')?.message}
                    </p>
                )}
            </div>
            <div className={formStyles['lb-form-row']}>
                <label className={formStyles['lb-form-label-editor']}>
                    Telephone *
                </label>
                {selectsModals.country && (
                    <div className={formStyles['lb-input-select-options']}>
                        <div
                            className={
                                formStyles['lb-input-select-options-search']
                            }>
                            <input
                                onClick={(e) => {
                                    openModal(e, 'country');
                                }}
                                onChange={(e) =>
                                    changeCountriesList(e.target.value)
                                }
                                placeholder="Type name of country"
                                className={
                                    formStyles['lb-input-select-options-input']
                                }
                                type="text"
                            />
                        </div>
                        {countriesShow.map(function (object, i) {
                            return (
                                <div
                                    key={i}
                                    onClick={() => changeCountry(object.code)}
                                    className={
                                        formStyles[
                                            'lb-input-select-options-row'
                                        ]
                                    }>
                                    <p>
                                        {object.emoji}{' '}
                                        <span>{object.name}</span>
                                    </p>
                                    <p>{object.dial_code}</p>
                                </div>
                            );
                        })}
                    </div>
                )}
                <div
                    onClick={(e) => openModal(e, 'country')}
                    className={
                        formStyles[
                            `lb-input-select-box${
                                getError('phone')?.error ? '-error' : ''
                            }`
                        ]
                    }>
                    <div
                        className={
                            formStyles['lb-input-select-container-flags']
                        }>
                        <p>{countries[countrySetter].emoji}</p>
                        <p>{countries[countrySetter].dial_code}</p>
                        <div
                            className={
                                formStyles[
                                    'lb-input-select-container-flags-icon'
                                ]
                            }></div>
                    </div>
                </div>
                <input
                    className={
                        formStyles[
                            `lb-input-select${
                                getError('phone')?.error ? '-error' : ''
                            }`
                        ]
                    }
                    onChange={(e) => onChange('phone', e)}
                    defaultValue={getValue('phone')}
                    type="tel"
                    name="phone"
                    placeholder="(000) 000-0000"
                    autoComplete="tel"
                />
                {getError('phone')?.error && (
                    <p className={formStyles['lb-input-error']}>
                        {getError('phone')?.message}
                    </p>
                )}
            </div>
            <div className={formStyles['lb-form-row']}>
                <label className={formStyles['lb-form-label-editor']}>
                    Email *
                </label>
                <input
                    onChange={(e) => onChange('email', e)}
                    defaultValue={getValue('email')}
                    className={
                        formStyles[
                            `lb-input${
                                getError('email')?.error ? '-error-border' : ''
                            }`
                        ]
                    }
                    type="email"
                    name="email"
                    placeholder="Your Email"
                />
                {getError('email')?.error && (
                    <p className={formStyles['lb-input-error']}>
                        {getError('email')?.message}
                    </p>
                )}
                {errorGlobal.error && (
                    <p className={formStyles['lb-input-error']}>
                        {errorGlobal.message}
                    </p>
                )}
            </div>
            <div
                className={formStyles['lb-form-row']}
                style={{
                    justifyContent: 'right',
                }}>
                {errorGlobal.error && (
                    <p className={formStyles['lb-input-error']}>
                        {errorGlobal.message}
                    </p>
                )}
                <button
                    disabled={isSending}
                    className={formStyles['lb-form-btn-dwnld']}
                    type="submit">
                    <span className={formStyles['lb-form-btn-s']}>
                        Download
                    </span>
                </button>
            </div>
        </form>
    );
};

export default DownloadForm;
