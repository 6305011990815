import { useRef, useEffect } from 'react';

export default function useTooltips() {
    const htmlRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (typeof window !== 'undefined' && htmlRef?.current) {
            const ref = htmlRef?.current;
            const html = ref?.childNodes[0] as HTMLElement;

            const links = [
                ...(html.querySelectorAll('a[title], a[data-title]') ?? []),
            ];

            const onClick = (ev: Event) => {
                ev.preventDefault();
                ev.stopPropagation();
            };

            if (links?.length > 0) {
                for (const link of links) {
                    const title = link.getAttribute('title');

                    if (title) {
                        link.setAttribute('data-title', title);
                        link.removeAttribute('title');
                    }

                    link.addEventListener('click', onClick);
                }
            }

            return () => {
                if (links?.length > 0) {
                    for (const link of links) {
                        link.removeEventListener('click', onClick);
                    }
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [htmlRef?.current]);

    return htmlRef;
}
