import React from 'react';

import styles from './styles.module.scss';

export type StarTypes = 'empty' | 'filled' | 'half';

export interface RatingProps {
    fill: StarTypes;
}

const Star = ({ fill }: RatingProps) => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            className={styles.star}
            xmlns="http://www.w3.org/2000/svg">
            {fill === 'half' && (
                <defs className={styles.gradient}>
                    <linearGradient
                        id="star_svg__fill-rating"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%">
                        <stop offset="50%" stop-color="#FFBA08"></stop>
                        <stop offset="50%" stop-color="transparent"></stop>
                    </linearGradient>
                </defs>
            )}

            <path
                fill={
                    fill === 'half'
                        ? 'url(#star_svg__fill-rating)'
                        : fill === 'filled'
                        ? '#FFBA08'
                        : 'transparent'
                }
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.47641 1.29389L9.02041 4.39989C9.09774 4.55655 9.24708 4.66589 9.42041 4.69122L12.8771 5.19055C13.0171 5.20922 13.1431 5.28255 13.2291 5.39455C13.3904 5.60455 13.3657 5.90189 13.1724 6.08255L10.6671 8.50522C10.5397 8.62522 10.4831 8.80122 10.5164 8.97255L11.1164 12.3912C11.1584 12.6746 10.9651 12.9399 10.6817 12.9859C10.5644 13.0039 10.4444 12.9852 10.3377 12.9326L7.25908 11.3186C7.10441 11.2346 6.91908 11.2346 6.76441 11.3186L3.66308 12.9412C3.40374 13.0732 3.08641 12.9752 2.94508 12.7212C2.89108 12.6186 2.87241 12.5019 2.89108 12.3879L3.49108 8.96922C3.52108 8.79855 3.46441 8.62322 3.34041 8.50255L0.821745 6.08055C0.616411 5.87655 0.615078 5.54455 0.819745 5.33922C0.820411 5.33855 0.821078 5.33722 0.821745 5.33655C0.906411 5.25989 1.01041 5.20855 1.12308 5.18855L4.58041 4.68922C4.75308 4.66189 4.90174 4.55389 4.98041 4.39722L6.52308 1.29389C6.58508 1.16789 6.69508 1.07122 6.82841 1.02722C6.96241 0.982553 7.10908 0.99322 7.23508 1.05655C7.33841 1.10789 7.42308 1.19122 7.47641 1.29389Z"
                stroke="#FFBA08"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default Star;
