import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import Picture, { PictureProps } from 'Picture';
import { Icon } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface CardItemProps {
    title: string;
    image?: PictureProps;
    icon?: string;
    text: string;
}

const CardItem: React.FC<CardItemProps> = ({
    title,
    text,
    image,
    icon,
    ...props
}) => {
    return (
        (title || text) && (
            <div className={cls('bg-base-1000', styles.card)} {...props}>
                <Heading
                    component="h4"
                    font="b400"
                    className={cls(styles.title)}>
                    {title}
                </Heading>
                {image && (
                    <div className={cls('mb-md', styles.image)}>
                        <Picture image={image} />
                    </div>
                )}
                {icon && (
                    <Icon
                        name={icon}
                        classes={{
                            icon: cls('mb-md', styles.icon),
                        }}
                    />
                )}
                <Text component="div" font="b200" className={cls(styles.text)}>
                    {text}
                </Text>
            </div>
        )
    );
};

export default CardItem;
