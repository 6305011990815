import React, { useState, useEffect } from 'react';

import {
    Container,
    Row,
    Col,
    cls,
    useIsMobile,
} from '@solublestudio/soluto-design-system';

import Button, { ButtonProps } from '@ds/components/shared/Button';
import Section from '@ds/components/shared/Section';
import TitleSubtitle from '@ds/components/shared/TitleSubtitle';
import CardItem, { CardItemProps } from '@ds/components/shared/Cards/Item';

import styles from './styles.module.scss';

export interface CardItemSectionProps {
    title: string;
    cards: CardItemProps[];
    anchor?: string;
    cta?: Omit<ButtonProps, 'variant'>;
    viewLessText?: string;
    viewMoreText?: string;
}

export default function CardItemSection({
    title,
    cards,
    anchor,
    cta,
    viewLessText = 'View less',
    viewMoreText = 'View more',
}: CardItemSectionProps) {
    const isMobile = useIsMobile();
    const [more, setMore] = useState(false);

    const onClick = () => {
        setMore(!more);
    };

    return (
        <Section dataTheme={isMobile ? 'light' : 'bg-gray'} anchor={anchor}>
            <Container>
                <TitleSubtitle title={title} className={cls('mb-xxl')} />
                <Row className={styles.row}>
                    {cards.map((card, i) => {
                        return i < 3 || cards.length <= 6 ? (
                            <Col
                                key={i}
                                col={{ md: 6, lg: 4, xxl: 3 }}
                                className="card-item">
                                <CardItem {...card} key={i} />
                            </Col>
                        ) : (
                            <Col
                                key={i}
                                col={{ md: 6, lg: 4, xxl: 3 }}
                                className={
                                    !more
                                        ? cls(
                                              'd-none',
                                              'd-lg-block',
                                              'js-view-more',
                                          )
                                        : 'card-item'
                                }>
                                <CardItem {...card} key={i} />
                            </Col>
                        );
                    })}
                </Row>
                {cards.length > 6 && (
                    <div className={cls('mt-xl', styles.cta)}>
                        {!more ? (
                            <Button
                                component="button"
                                onClick={onClick}
                                iconName="plus"
                                label={viewMoreText}
                                type="button"
                                className={cls(
                                    'd-block',
                                    'd-lg-none',
                                )}
                                variant="secondarySmall">
                                {viewMoreText}
                            </Button>
                        ) : (
                            <Button
                                component="button"
                                onClick={onClick}
                                iconName="minus"
                                label={viewLessText}
                                type="button"
                                className={cls(
                                    'd-block',
                                    'd-lg-none',
                                )}
                                variant="secondarySmall">
                                {viewLessText}
                            </Button>
                        )}
                    </div>
                )}
                {cta && (
                    <div className={cls('mt-xl', styles.cta)}>
                        <Button variant="primaryLarge" {...cta} />
                    </div>
                )}
            </Container>
        </Section>
    );
}
