import React, { useState, useMemo } from 'react';

import { Icon } from '@solublestudio/soluto-design-system';

import { Input, cls, InputProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';
import Link, { LinkProps } from 'Link';

export interface InputSearchProps extends InputProps {
    links?: LinkProps;
    errorMessage: string;
}

const InputSearch = ({ links, errorMessage, ...props }: InputSearchProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const [term, setTerm] = useState('');

    const linksFiltered = useMemo(
        () =>
            links &&
            links.filter(({ label }: any) =>
                label.toLowerCase().includes(term.toLowerCase()),
            ),
        [term, links],
    );

    const onInput = (e: any) => {
        setTerm(e.target?.value?.trim());
    };
    return (
        <>
            <div className={cls(styles.wrapper, 'position-relative')}>
                <Input
                    {...props}
                    type="search"
                    name="search"
                    onInput={onInput}
                    onFocus={() => setIsVisible(true)}
                    onBlur={() => setTimeout(() => setIsVisible(false), 100)}
                    classes={{
                        wrapper: cls(styles.wrapper, 'position-relative'),
                        element: cls(styles.input),
                    }}
                    laterContent={
                        <Icon
                            name="search"
                            size={24}
                            classes={{
                                icon: cls(styles.icon),
                            }}
                        />
                    }
                />
                {isVisible && (
                    <div className={cls(styles.dropdown, 'py-xs px-sm')} onFocus={() => setIsVisible(true)}>
                        {linksFiltered && linksFiltered.length > 0 ? (
                            linksFiltered.map((link: LinkProps, i: number) => (
                                <>
                                    <Link
                                        key={`search-${i}`}
                                        {...link}
                                        className={cls(styles.link, i !== 0 && 'pt-xs')}>
                                        {link.label}
                                    </Link>
                                </>
                            ))
                        ) : (
                            <p className={cls('text-base-000')}>{errorMessage}</p>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default InputSearch;
