import React, { useMemo } from 'react';

import Button from '@ds/components/shared/Button';
import Heading from '@ds/components/shared/Heading';

import { cls } from '@solublestudio/soluto-design-system';

import type { LinkProps } from 'Link';

export interface ContactItemProps {
    title: string;
    link: LinkProps;
    iconName?: string;
}

const ContactItem: React.FC<ContactItemProps> = ({
    title,
    link,
    iconName = 'chat',
}) => {
    const iconNameByLink = useMemo(() => {
        const { href } = link;

        if (href) {
            if (href.startsWith('tel:')) {
                return 'telephone';
            }
            if (href.startsWith('mailto:')) {
                return 'email';
            }

            return iconName;
        }

        return iconName;
    }, [iconName, link]);

    return (
        <div
            className={cls(
                'py-grid-padding',
                'px-xxl',
                'd-flex',
                'flex-column',
                'justify-content-center',
                'bg-base-1000',
            )}>
            <Heading component="h4" font="h100">
                {title}
            </Heading>
            {link && (
                <Button
                    {...link}
                    iconName={iconNameByLink}
                    variant="buttonIconBorder"
                />
            )}
        </div>
    );
};

export default ContactItem;
