import React from 'react';

import { Container, Row, Col, cls } from '@solublestudio/soluto-design-system';

import Section, { SectionProps } from '@ds/components/shared/Section';
import TitleSubtitle from '@ds/components/shared/TitleSubtitle';
import Button, { ButtonProps } from '@ds/components/shared/Button';

import styles from './styles.module.scss';

export interface TitleButtonSectionProps {
    title: string;
    subtitle: string;
    cta: ButtonProps;
    anchor?: string;
    variant?: 'default'|'intense';
}

const TitleButtonSection = ({
    title,
    subtitle,
    cta,
    anchor,
    variant = 'default'
}: TitleButtonSectionProps) => {
    const themes = {
        default: 'bg-gray-primary-darker',
        intense: 'bg-primary'
    }
    const buttonVariant = {
        default: 'primaryLarge',
        intense: 'secondaryLargeWithOutBorder'
    }
    return (
        <Section dataTheme={themes[variant] as SectionProps['dataTheme']} anchor={anchor}>
            <Container>
                <Row>
                    <Col className={cls('d-lg-flex align-items-lg-center')}>
                        <TitleSubtitle
                            title={title}
                            subtitle={subtitle}
                            subtitleFont="b200"
                            className={cls('mb-lg', styles.text)}
                        />
                        <div className={styles.cta}>
                            <Button {...cta} variant={buttonVariant[variant] as ButtonProps['variant']} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default TitleButtonSection;
