import React from 'react';
import CardPlanLarge, {
    CardPlanLargeProps,
} from '@ds/components/shared/Cards/Plan/Large';

export interface CardPlanLargeSectionProps extends CardPlanLargeProps {}

const CardPlanLargeSection: React.FC<CardPlanLargeSectionProps> = ({
    image,
    text,
    subtitle,
    title,
    ...props
}) => {
    return (
        <section {...props}>
            <CardPlanLarge
                image={image}
                text={text}
                subtitle={subtitle}
                title={title}
            />
        </section>
    );
};

export default CardPlanLargeSection;
