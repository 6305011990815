import React, { useCallback, useRef, useState, useEffect } from 'react';

import {
    Section,
    SectionProps,
    Container,
    cls,
    animate,
} from '@solublestudio/soluto-design-system';

import SliderControls, {
    SliderControlsProps,
} from '@ds/components/shared/Slider/Controls';
import type { CardPhotoProps as Card } from '@ds/components/shared/Cards/Photo';

import styles from './styles.module.scss';

export interface CarouselBaseSectionProps {
    preContent?: React.ReactNode;
    postContent?: React.ReactNode;
    items: any[];
    classes?: {
        section?: string;
        wrapper?: string;
        item?: string;
        controls?: string;
    };
    showControls?: boolean;
    alignControls?: SliderControlsProps['align'];
    anchor?: string;
    reverse?: boolean;
    component?: SectionProps['component'];
}

function getItemsPosition(dom: any, reverse: boolean = false, modifier = 0) {
    const elements = dom.childNodes;
    const items = [...elements].map((element) => {
        const position = element.getBoundingClientRect();
        return (reverse ? position.right : position.left) + modifier;
    });

    return reverse ? items.reverse() : items;
}

const CarouselBaseSection: React.FC<CarouselBaseSectionProps> = ({
    preContent,
    postContent,
    classes,
    items,
    showControls,
    alignControls,
    reverse = false,
    component
}) => {
    const [startPhantomPosition, setStartPhantomPosition] = useState(null);

    const wrapperRef = useRef();
    const phantomRef = useRef();


    useEffect(() => {
        if (!phantomRef?.current || !showControls) {
            return;
        }

        const setup = () => {
            const position = phantomRef?.current.getBoundingClientRect();
            setStartPhantomPosition(reverse ? position.right : position.left);
        };

        setup();
        window.addEventListener('resize', setup);
        return () => {
            window.removeEventListener('resize', setup);
        };
    }, [phantomRef, setStartPhantomPosition, showControls]);

    const onClickNext = useCallback(() => {
        const itemsPosition = getItemsPosition(
            wrapperRef?.current,
            reverse,
            -startPhantomPosition,
        );
        const nextItem = itemsPosition.find((position) => position > 5);
        
        if (nextItem) {
            const currentPosition = wrapperRef?.current.scrollLeft || 0;
            const newPosition = nextItem + currentPosition;
            animate(
                wrapperRef.current,
                'scrollLeft',
                0,
                currentPosition,
                newPosition,
                Math.abs(nextItem) * 0.5,
                true,
            );
        }
    }, [wrapperRef, startPhantomPosition]);

    const onClickPrev = useCallback(() => {
        const itemsPosition = getItemsPosition(
            wrapperRef?.current,
            reverse,
            -startPhantomPosition,
        );
        const prevItem = itemsPosition
            .reverse()
            .find((position) => position < -5);

        if (prevItem) {
            const currentPosition = wrapperRef?.current.scrollLeft || 0;
            const newPosition = prevItem + currentPosition;
            animate(
                wrapperRef.current,
                'scrollLeft',
                0,
                currentPosition,
                newPosition,
                Math.abs(prevItem) * 0.5,
                true,
            );
        }
    }, [wrapperRef, startPhantomPosition]);

    return (
        <Section className={cls(classes?.section)} component={component}>
            {!!preContent && <Container>{preContent}</Container>}
            <div
                ref={wrapperRef}
                className={cls(styles.wrapper, classes?.wrapper, reverse ? styles.reverse : '')}
                data-sal='slide-left-children'>
                {items?.length > 0 &&
                    items.map((item: Card, i: number) => (
                        <div
                            className={cls(styles.item, classes?.item)}
                            key={`carousel-item-${i}`}
                            data-sal="slide"
                            data-sal-delay={i * 200}>
                            {item}
                        </div>
                    ))}
            </div>
            {!!showControls && (
                <div className={cls('d-none', 'd-lg-block', classes?.controls)}>
                    <Container>
                        <div className={cls('d-block')} ref={phantomRef}>
                            <SliderControls
                                className="mt-md"
                                onClickPrev={onClickPrev}
                                onClickNext={onClickNext}
                                align={alignControls}
                            />
                        </div>
                    </Container>
                </div>
            )}
            {!!postContent && <Container>{postContent}</Container>}
        </Section>
    );
};

export default CarouselBaseSection;
