import React from 'react';

import styles from './styles.module.scss';
import Star from './Star/Star';

export interface RatingProps {
    rating: number;
    bgColor?: string;
}

const RatingSection = ({ rating, bgColor }: RatingProps) => {
    return (
        <div className={styles.ratingWrapper}>
            {[...Array(5)].map((_, index) => {
                const i = index + 1;
                if (rating % 2 === 0) {
                    if (i <= Math.floor(rating / 2)) {
                        return <Star key={i} fill="filled" />;
                    } else {
                        return <Star key={i} fill="empty" />;
                    }
                }
                if (rating % 2 === 1) {
                    if (i <= Math.floor(rating / 2)) {
                        return <Star key={i} fill="filled" />;
                    } else if (i === Math.floor(rating / 2 + 1)) {
                        return <Star key={i} fill="half" />;
                    } else {
                        return <Star key={i} fill="empty" />;
                    }
                }
                return null;
            })}
        </div>
    );
};

export default RatingSection;
