import React, { useState } from 'react';
import {
    useElements,
    CardCvcElement,
    CardNumberElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js';

import styles from '../BaseForm/styles.module.scss';

interface PayForBlockedProps {
    hiddenFields: any;
    onSubmit: (element: any, data: any) => Promise<void>;
}

const PayForBlocked = ({ hiddenFields, onSubmit }: PayForBlockedProps) => {
    const priceId = hiddenFields.find((field: any) => field.key === 'priceId');
    const [price] = useState(priceId.value);

    const [loading, setLoading] = useState(false);

    const custimerId = hiddenFields.find(
        (field: any) => field.key === 'customerId',
    );
    const [custumer] = useState(custimerId.value);

    const elements = useElements();

    const [dataCard, setDataCard] = useState({
        fullNameCard: '',
        codePromo: '',
        nameIfExist: '',
        codeApplied: false,
    });

    const [errorMessageCard, setErrorMessageCard] = useState({
        fullNameCard: false,
        errorCardDigits: false,
        errorExp: false,
        errorCVC: false,
    });

    const [error, setError] = useState({
        error: false,
        message: '',
    });

    const thisOnSubmit = async (ev) => {
        ev?.preventDefault();
        setLoading(true);

        const element = elements?.getElement('cardNumber');
        const isValidCard = !(element._empty || element._invalid);

        const exp = elements?.getElement('cardExpiry');
        const isValidExp = !(exp._invalid || exp._empty);

        const cvc = elements?.getElement('cardCvc');
        const isValidCVC = !(cvc._invalid || cvc._empty);

        const { fullNameCard } = dataCard;

        setErrorMessageCard({
            fullNameCard: fullNameCard === '',
            errorCardDigits: !isValidCard,
            errorExp: !isValidExp,
            errorCVC: !isValidCVC,
        });

        if (!isValidCard || !isValidExp || !isValidCVC || !fullNameCard) {
            setLoading(false);
            setError({
                error: true,
                message:
                    'One or more fields are not valid. Please, check errors and try again.',
            });
        } else {
            setError({
                error: false,
                message: '',
            });
        }

        try {
            await onSubmit(element, {
                fullNameCard,
                price,
                custumer,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError({
                error: true,
                message: error?.message,
            });
        }
    };

    return (
        <form onSubmit={thisOnSubmit}>
            <div className={styles['lb-form-row']}>
                <div className={styles['lb-form-row']}>
                    <div className={styles['lb-form-record']}>
                        <input
                            className={styles['lb-input']}
                            type="text"
                            name="nameCard"
                            placeholder="Full Name"
                            onChange={(e) => {
                                setDataCard({
                                    ...dataCard,
                                    fullNameCard: e.target.value,
                                });
                                setErrorMessageCard({
                                    ...errorMessageCard,
                                    fullNameCard: e.target.value === '',
                                });
                                setError({ error: false, message: '' });
                            }}
                        />
                        {errorMessageCard.fullNameCard && (
                            <p className={styles['lb-input-error']}>
                                This field is required.
                            </p>
                        )}
                    </div>
                    <div className={styles['lb-form-record']}>
                        <CardNumberElement
                            className={
                                styles[
                                    `lb-input-custom-stripe${
                                        errorMessageCard.errorCardDigits
                                            ? '-error'
                                            : ''
                                    }`
                                ]
                            }
                        />
                        {errorMessageCard.errorCardDigits && (
                            <p className={styles['lb-input-error']}>
                                This field is required.
                            </p>
                        )}
                    </div>
                </div>
                <div className={styles['lb-form-row']}>
                    <div className={styles['lb-form-record']}>
                        <CardExpiryElement
                            className={
                                styles[
                                    `lb-input-custom-stripe${
                                        errorMessageCard.errorExp
                                            ? '-error'
                                            : ''
                                    }`
                                ]
                            }
                        />
                        {errorMessageCard.errorExp && (
                            <p className={styles['lb-input-error']}>
                                This field is required.
                            </p>
                        )}
                    </div>
                    <div className={styles['lb-form-record']}>
                        <CardCvcElement
                            className={
                                styles[
                                    `lb-input-custom-stripe${
                                        errorMessageCard.errorCVC
                                            ? '-error'
                                            : ''
                                    }`
                                ]
                            }
                        />
                        {errorMessageCard.errorCVC && (
                            <p className={styles['lb-input-error']}>
                                This field is required.
                            </p>
                        )}
                    </div>
                </div>
                <div className={`${styles['lb-form-row-submit']}`}>
                    {error.error && (
                        <p className={styles['lb-form-btn-s-error']}>
                            {error.message}
                        </p>
                    )}
                    <button
                        disabled={loading}
                        className={styles['lb-form-btn']}
                        type="submit">
                        <span className={styles['lb-form-btn-s']}>
                            Purchase
                        </span>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default PayForBlocked;
