import React, { useEffect, useRef, forwardRef, useState } from 'react';

import {
    Modal as ModalSoluto,
    ModalRef,
    ModalProps,
    Video,
    cls,
} from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface VideoProps {
    video: any;
}

const ModalVideo = forwardRef<ModalRef, VideoProps & ModalProps>(function (
    { video, ...props },
    ref,
) {
    const [showVideo, setShowVideo] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        if (wrapperRef?.current) {
            const videoElement = wrapperRef.current.querySelector('video');
            if (videoElement) {
                videoRef.current = videoElement;
            }
        }
    }, []);

    return (
        <ModalSoluto
            {...props}
            ref={ref}
            size="xl"
            header={<span></span>}
            onToggle={setShowVideo}
            classes={{
                body: cls(styles.body),
                dialog: cls(styles.dialog),
                content: cls(styles.content),
                closeButton: cls(styles.closeButton),
            }}>
            {showVideo && (
                <Video
                    ref={ref}
                    classes={{
                        video: cls(styles.video),
                    }}
                    sources={[
                        {
                            src: video.file.publicURL,
                            type: video.file.extension,
                        },
                    ]}
                    controls={true}
                />
            )}
        </ModalSoluto>
    );
});

export default ModalVideo;
