import React from 'react';

import Heading from '@ds/components/shared/Heading';
import Text, { TextProps } from '@ds/components/shared/Text';

import styles from './styles.module.scss';
import { cls } from '@solublestudio/soluto-design-system';

export interface TitleSubtitleProps {
    title: string;
    subtitle?: string;
    subtitleFont?: TextProps['font'];
    className?: string;
}

const TitleSubtitle = ({
    title,
    subtitle,
    subtitleFont = 'b400',
    className,
}: TitleSubtitleProps) => {
    return (
        <div className={cls(styles.wrapper, className)}>
            <Heading component="h2" font="h300">
                {title}
            </Heading>
            {subtitle && (
                <Text component="div" font={subtitleFont}>
                    {subtitle}
                </Text>
            )}
        </div>
    );
};

export default TitleSubtitle;
