import { formatImage } from './image';
import { formatVideo } from './video';

export function formatMedia(
    media: { [key: string]: any },
    options?: {
        [key: string]: any;
    },
) {
    if (media) {
        const [type, extension] = media?.mimeType?.split('/') ?? [
            'image',
            'webp',
        ];

        if (type === 'video') {
            return formatVideo(media, extension);
        }

        return formatImage(media, extension, options);
    }

    return null;
}
