import React from 'react';

import dynamic from 'next/dynamic';

export type Component = {
    typename: string;
    id?: string;
    originalId?: string;
    [key: string]: any;
};
const Components: { [key: string]: React.ElementType } = {
    banner_large_section: dynamic(() => import('./BannerLargeSection')),
    banner_small_section: dynamic(() => import('./BannerSmallSection')),
    card_icon_section: dynamic(() => import('./CardIconSection')),
    card_item_section: dynamic(() => import('./CardItemSection')),
    card_plan_large_section: dynamic(() => import('./CardPlanLargeSection')),
    carousel_cards_section: dynamic(() => import('./CarouselCardsSection')),
    checkout_intro_section: dynamic(() => import('./Checkout/IntroSection')),
    columns_section: dynamic(() => import('./ColumnsSection')),
    contact_form_section: dynamic(() => import('./ContactFormSection')),
    contact_section: dynamic(() => import('./ContactSection')),
    content_image_section: dynamic(() => import('./ContentImageSection')),
    document_detail_section: dynamic(() => import('./DocumentDetailSection')),
    documents_list_section: dynamic(() => import('./DocumentsListSection')),
    documents_section: dynamic(() => import('./DocumentsSection')),
    accordion_list_section: dynamic(() => import('./AccordionListSection')),
    error_section: dynamic(() => import('./ErrorSection')),
    faqs_section: dynamic(() => import('./FaqsSection')),
    hero_inner_section: dynamic(() => import('./HeroInnerSection')),
    hero_secondary_section: dynamic(() => import('./HeroSecondarySection')),
    hero_section: dynamic(() => import('./HeroSection')),
    hero_search_section: dynamic(() => import('./HeroSearchSection')),
    html_section: dynamic(() => import('./HTMLSection')),
    plans_section: dynamic(() => import('./PlansSection')),
    comparator_section: dynamic(() => import('./ComparatorSection')),
    points_section: dynamic(() => import('./PointsSection')),
    popup_section: dynamic(() => import('./PopupSection')),
    process_section: dynamic(() => import('./ProcessSection')),
    step_section: dynamic(() => import('./StepSection')),
    subscription_form_section: dynamic(
        () => import('./SubscriptionFormSection'),
    ),
    summary_section: dynamic(() => import('./SummarySection')),
    testimonials_section: dynamic(() => import('./TestimonialsSection')),
    testimonials_featured_section: dynamic(
        () => import('./TestimonialsFeaturedSection'),
    ),
    title_section: dynamic(() => import('./TitleSection')),
    title_button_section: dynamic(() => import('./TitleButtonSection')),
    legal_issues_section: dynamic(() => import('./LegalIssuesSection')),
    benefits_section: dynamic(() => import('./BenefitsSection')),
    lawyers_section: dynamic(() => import('./LawyersSection')),
    areas_section: dynamic(() => import('./AreasSection')),
};

const DynamicSection: React.FC<Component> = ({
    typename,
    id,
    originalId,
    ...props
}) => {
    if (typeof Components[typename] !== 'undefined') {
        const Component = Components[typename];

        return <Component {...props} key={id ?? originalId} />;
    }

    return (
        <code>
            The component <strong>{typename}</strong> has not been created yet.
        </code>
    );
};

export default DynamicSection;
