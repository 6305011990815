import React from 'react';
import { cls, Container } from '@solublestudio/soluto-design-system';

import { HeroSectionProps } from '@ds/components/organisms/HeroSection';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import Picture from 'Picture';
import Link from 'Link';

import type { LinkProps } from 'Link';

import styles from './styles.module.scss';
import { PointProps } from '@ds/components/shared/Point';
import FeatureItem from './Item';

export interface FeaturedPlanProps {
    title: string;
    description?: string;
    background: HeroSectionProps['background'];
    items?: PointProps[];
    linkPlan?: LinkProps;
}

const FeaturedPlan = ({
    title,
    description,
    items = [],
    linkPlan,
}: FeaturedPlanProps) => {
    return (
        <div>
            <Container className="d-none d-lg-block">
                <div className={cls(styles.itemsWrapper)}>
                    <Link
                        {...linkPlan}
                        className={cls(
                            'bg-primary-dark',
                            'text-base-1000',
                            'py-sm',
                            'px-md',
                            'd-none',
                            'd-lg-flex',
                            'flex-column',
                            'justify-content-center',
                            'text-left',
                            styles.plan,
                        )}>
                        <Heading font="h100" component="h3">
                            {title}
                        </Heading>
                        <Text
                            font="b200"
                            component="p"
                            className={cls(styles.description)}>
                            {description}
                        </Text>
                    </Link>
                    {items?.length > 0
                        ? items.map((item, index) => {
                              return <FeatureItem {...item} key={index} />;
                          })
                        : null}
                </div>
            </Container>
        </div>
    );
};

export default FeaturedPlan;
