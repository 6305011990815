import React from 'react';

import { cls, Icon } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';

import styles from './styles.module.scss';
import { PointProps } from '@ds/components/shared/Point';

const FeaturesItem = ({ icon, description }: PointProps) => {
    return (
        <div
            className={cls(
                styles.item,
                'd-flex',
                'p-sm',
                'bg-base-1000',
                'align-items-center',
                'flex-column',
                'flex-xl-row',
                'justify-content-xl-center',
            )}>
            {icon && <Icon
                name={icon}
                size={32}
                classes={{
                    icon: cls(styles.icon),
                }}
            />}
            <Heading
                component="h4"
                className={cls(styles.text)}
                font="b300">
                {description}
            </Heading>
        </div>
    );
};

export default FeaturesItem;
