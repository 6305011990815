import React, { useRef } from 'react';
import {
    HeaderSimple as DSHeaderSimple,
    HeaderRef,
} from '@solublestudio/legalbrave-design-system';

import type { LinkProps } from 'Link';

import Menu from '../Menu';
import { useUser } from 'api-base/auth';

import { useTranslate } from 'cms-base/hooks/useTranslate';

interface HeaderSimpleProps {
    items: LinkProps[];
    menu?: LinkProps[];
    alternateLanguages?: LinkProps[];
    theme?: any;
    altPhone?: LinkProps;
}

const HeaderSimple: React.FC<HeaderSimpleProps> = ({
    items = [],
    menu = null,
    alternateLanguages = [],
    theme,
    altPhone,
}) => {
    const { user } = useUser();
    const [t] = useTranslate();
    const ref = useRef<HeaderRef>(null);
    const menuWithOnClick = menu
        ?.filter(
            ({ hideFromColectivos }) =>
                !(user?.profile?.isColectivo && hideFromColectivos),
        )
        .map((m) => ({
            ...m,
            onClick: () => ref?.current?.close(),
        }));

    const languageLinks =
        alternateLanguages?.map(
            (alternate) =>
                ({
                    ...alternate,
                    label: t('languages.' + alternate.locale),
                } ?? []),
        ) ?? [];

    return (
        <DSHeaderSimple
            ref={ref}
            telButton={altPhone || items?.[0]}
            telText={items?.[1]?.label}
            collapseContent={menu ? <Menu menu={menuWithOnClick} /> : null}
            extraLogo={theme?.logo}
            languageLinks={languageLinks}
        />
    );
};

export default HeaderSimple;
