import React from 'react';

import { cls, Icon } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';

export interface PointProps {
    title: string;
    description: string;
    icon?: string;
}

const Point = ({ description, icon }: PointProps) => {
    return (
        <div className={cls('d-lg-block text-center', styles.point)}>
            {icon && <Icon
                name={icon}
                size={40}
                classes={{
                    icon: cls(styles.icon, 'text-primary-dark'),
                }}
            />}
            <Text component="div" font="b100" className="mt-tiny">
                {description}
            </Text>
        </div>
    );
};

export default Point;
