import React from 'react';
import {
    GridWrapper,
    Container,
    cls,
} from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';

import ContactItem, {
    ContactItemProps,
} from '@ds/components/shared/ContactItem';

import styles from './styles.module.scss';

export interface ContactSectionProps {
    items: ContactItemProps[];
    anchor?: string;
}

const ContactSection: React.FC<ContactSectionProps> = ({
    items = [],
    anchor,
}) => {
    const sizeGrid: { [key: string]: string } = {
        2: 'two',
        3: 'three',
    };

    return (
        <Section dataTheme="light" anchor={anchor}>
            <Container>
                <GridWrapper
                    className={cls(
                        styles.wrapper,
                        styles[sizeGrid?.[`${items?.length}` as string]],
                        'bg-base-300',
                    )}>
                    {items.map((item, i) => {
                        return (
                            <ContactItem {...item} key={`contact-item-${i}`} />
                        );
                    })}
                </GridWrapper>
            </Container>
        </Section>
    );
};

export default ContactSection;
