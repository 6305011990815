import React from 'react';

import { cls, Section } from '@solublestudio/soluto-design-system';

import TitleSubtitle from '@ds/components/shared/TitleSubtitle';
import CardPlanSmall, {
    CardPlanSmallProps,
} from '@ds/components/shared/Cards/Plan/Small';

export interface CheckoutIntroSectionProps extends CardPlanSmallProps {
    title: string;
}

export default function CheckoutIntroSection({
    title,
    ...props
}: CheckoutIntroSectionProps) {
    return (
        <Section>
            <TitleSubtitle title={title} className={cls('mb-xg')} />
            <CardPlanSmall {...props} />
        </Section>
    );
}
