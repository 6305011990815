import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Picture, { PictureProps } from 'Picture';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import HTMLCheckListComponent from '@ds/components/shared/HTMLComponent/CheckList';

import styles from './styles.module.scss';
import { CardPlanProps } from '../Default';
import useTooltips from '@ds/hooks/useTooltips';
import Button from '@ds/components/shared/Button';

const CardPlanAlone = ({
    image,
    text,
    description,
    title,
    recommended,
    preprice,
    price,
    postprice,
    ctas = [],
    variant = 'default',
}: CardPlanProps) => {
    const htmlRef = useTooltips();
    const variantsButtons: {
        [key in CardPlanProps['variant'] as string]: ButtonProps['variant'][];
    } = {
        intense: ['primaryLarge', 'link'],
        default: ['secondaryLargeWithOutBorder', 'link'],
    };

    return (
        <div className={cls(styles.card, 'd-flex', 'flex-column-reverse')}>
            <div
                className={cls(
                    'bg-base-1000',
                    'px-grid-padding',
                    'py-grid-small',
                    'd-flex',
                    'flex-column',
                    styles.info,
                )}>
                {recommended && (
                    <Text
                        font="b200"
                        className={cls(
                            styles.tag,
                            'py-modulor',
                            'px-xxs',
                            'bg-secondary',
                            'text-base-1000',
                        )}>
                        {recommended}
                    </Text>
                )}
                <Heading font="h100" component="h4">
                    {title}
                </Heading>
                {description && (
                    <Text
                        font="b300"
                        component="div"
                        className="mb-grid-small font-weight-semi-bold">
                        {description}
                    </Text>
                )}
                {preprice && (
                    <Text font="b200" component="div" className={styles.preprice}>
                        {preprice}
                    </Text>
                )}
                {price && (
                    <Text font="b300" className={styles.price}>
                        {price}
                    </Text>
                )}
                {postprice && (
                    <div ref={htmlRef}>
                        <Text
                            font="b100"
                            component="div"
                            className={styles.postprice}>
                            {postprice.replace('\n', '')}
                        </Text>
                    </div>
                )}
                <HTMLCheckListComponent
                    text={text}
                    classes={{ wrapper: 'mt-grid-small mb-lg' }}
                />
                {ctas && (
                    <div className={styles.ctas}>
                        {ctas.map((cta, index) => (
                            <Button
                                key={`summary-cta-${index}`}
                                {...cta}
                                variant={
                                    variantsButtons?.[variant]?.[index] ??
                                    'primaryLarge'
                                }
                                className="b300"
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className={cls('position-relative', styles.image)}>
                <Picture
                    image={image}
                    alt={title}
                    layout="background"
                    objectFit="cover"
                />
            </div>
        </div>
    );
};

export default CardPlanAlone;
