import React from 'react';

import { Accordion, cls } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface FaqComponentProps {
    title: string;
    body: string;
    show: boolean;
}
export interface FaqsComponentProps {
    items: FaqComponentProps[];
}

export default function FaqsComponent({ items = [] }: FaqsComponentProps) {
    return (
        <Accordion
            initialShow={-1}
            items={items?.length ? items : []}
            classes={{
                wrapper: styles.accordion,
                item: cls('px-xxl bg-base-400', styles.item),
                openItem: cls(styles.openItem),
                title: cls(
                    'b400 font-weight-semi-bold text-base-000',
                    styles.title,
                ),
                body: cls('py-sm b400', styles.body),
            }}
        />
    );
}
