import React from 'react';
import { Container, cls } from '@solublestudio/soluto-design-system';

import DynamicCarouselCard from './DynamicCarouselCard';

import CarouselBaseSection, {
    CarouselBaseSectionProps,
} from '@ds/components/organisms/Carousel';

import Section, { SectionProps } from '@ds/components/shared/Section';
import TitleSubtitle, {
    TitleSubtitleProps,
} from '@ds/components/shared/TitleSubtitle';

import { SliderControlsProps } from '@ds/components/shared/Slider/Controls';

import styles from './styles.module.scss';

export interface CarouselCardsSectionProps {
    size?: 'large' | 'medium' | 'small';
    items: any[];
    classes?: {
        wrapper?: string;
        section?: string;
        controls?: string;
    };
    showControls?: CarouselBaseSectionProps['showControls'];
    alignControls?: SliderControlsProps['align'];
    theme?: SectionProps['dataTheme'];
    title?: TitleSubtitleProps['title'];
    subtitle?: TitleSubtitleProps['subtitle'];
    anchor?: string;
    reverse?: CarouselBaseSectionProps['reverse'];
    component?: CarouselBaseSectionProps['component'];
}

const CarouselCardsSection: React.FC<CarouselCardsSectionProps> = ({
    title,
    subtitle,
    size = 'small',
    items,
    showControls = true,
    classes,
    alignControls,
    theme,
    anchor,
    reverse,
    component,
}) => {
    return (
        <Section
            dataTheme={theme}
            className={classes?.wrapper}
            anchor={anchor}
            component={component}>
            {title || subtitle ? (
                <Container>
                    <TitleSubtitle
                        title={title}
                        subtitle={subtitle}
                        className={cls('mb-xl')}
                    />
                </Container>
            ) : null}

            <CarouselBaseSection
                classes={{
                    section: cls(classes?.section),
                    wrapper: cls(styles.wrapper, styles[`${size}`]),
                    controls: cls(classes?.controls),
                }}
                items={
                    items?.length
                        ? items.map((item, i) => (
                              <DynamicCarouselCard {...item} key={i} />
                          ))
                        : []
                }
                showControls={
                    size === 'medium' && items.length <= 3
                        ? false
                        : showControls
                }
                alignControls={alignControls}
                anchor={anchor}
                reverse={reverse}
                component="div"
            />
        </Section>
    );
};

export default CarouselCardsSection;
