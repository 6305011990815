import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Picture, { PictureProps } from 'Picture';
import HTMLCheckListComponent from '@ds/components/shared/HTMLComponent/CheckList';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import Button, { ButtonProps } from '@ds/components/shared/Button';

import type { HTMLComponentProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface CardPlanProps extends HTMLComponentProps {
    image: PictureProps;
    className?: string;
    title: string;
    text?: string;
    description: string;
    preprice?: string;
    price: string;
    postprice?: string;
    ctas?: (Omit<ButtonProps, 'variant'> & {
        variant?: ButtonProps['variant'];
    })[]; // Inherit button props but variant should be optional
    variant?: 'default' | 'intense';
    recommended?: string;
}
const variantsButtons: {
    [key in CardPlanProps['variant'] as string]: ButtonProps['variant'][];
} = {
    intense: ['primaryLarge', 'link'],
    default: ['secondaryLargeWithOutBorder', 'link'],
};
const CardPlan = ({
    image,
    title,
    text,
    description,
    price,
    ctas = [],
    variant = 'default',
    recommended,
    className,
}: CardPlanProps) => {
    return (
        <div
            className={cls(
                'bg-base-1000',
                'd-flex',
                'flex-column',
                'position-relative',
                styles.card,
                className,
            )}>
            <Picture
                image={image}
                layout="full"
                objectFit="cover"
                classes={{
                    wrapper: cls(styles.picture),
                }}
            />
            <div
                className={cls(
                    'px-grid-padding',
                    'pb-lg',
                    'd-flex',
                    'flex-column',
                    'align-items-start',
                    styles.wrapper,
                )}>
                {recommended && (
                    <Text
                        font="b200"
                        className={cls(
                            styles.tag,
                            'py-modulor',
                            'px-xxs',
                            'bg-primary-dark',
                            'text-base-1000',
                        )}>
                        {recommended}
                    </Text>
                )}
                <div
                    className={cls(
                        styles.resume,
                        'pt-xs',
                        'mb-md',
                        'pb-xs',
                        'position-relative',
                        'align-items-lg-center',
                        description && styles.withDescription,
                    )}>
                    <Heading
                        className={cls(styles.title)}
                        font="b400"
                        component="h3">
                        {title}
                    </Heading>
                    <div
                        className={cls(
                            styles.price,
                            'text-lg-right d-flex d-md-block justify-content-between align-items-center',
                        )}>
                        <Text font="b300">{price}</Text>
                    </div>

                    {description && (
                        <Text
                            font="b200"
                            component="div"
                            className={cls(styles.description)}>
                            {description}
                        </Text>
                    )}
                </div>

                <HTMLCheckListComponent
                    text={text}
                    classes={{ wrapper: cls('mb-lg') }}
                />
                {ctas && (
                    <div className={cls(styles.ctas)}>
                        {ctas.map((cta, index) => (
                            <Button
                                key={`summary-cta-${index}`}
                                {...cta}
                                variant={
                                    variantsButtons?.[variant]?.[index] ??
                                    'primaryLarge'
                                }
                                className="b300"
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardPlan;
