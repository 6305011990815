import React from 'react';
import Head from 'next/head';
import { renderMetaTags, TitleMetaLinkTag } from 'react-datocms';
import { useRouter } from 'next/router';

import Header from './Header';
import Chat from './Chat';
import ChatStaging from './ChatStaging';
import { Config } from 'api-base/lib/types';

const isProd =
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ||
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ===
        'release/version-2023062201';

type LinkAlternate = {
    rel: string;
    hrefLang: string;
    href: string;
};

type LayoutProps = {
    site: { favicon?: TitleMetaLinkTag[] };
    page: {
        seo?: TitleMetaLinkTag[];
        noindex?: boolean;
        alternateLanguages: {
            locale: string;
            href: string;
            active: boolean;
        }[];
    };
    children: React.ReactNode;
    header?: any;
    footer?: React.ReactNode;
    globalConfig?: Config;
};

const Layout = ({
    site = {},
    page = {},
    children,
    header,
    footer,
    globalConfig,
}: LayoutProps) => {
    const { seo, noindex } = page;
    const { query, isReady } = useRouter();

    const shouldShow =
        globalConfig?.web.chat && !query['hide-chat'] && !query['hide-all'];

    const alternateLinks =
        page?.alternateLanguages?.length > 0
            ? page.alternateLanguages.reduce((acc, alternate) => {
                  if (alternate?.active) {
                      return acc;
                  }

                  acc.push({
                      rel: 'alternate',
                      hrefLang: alternate.locale,
                      href: `${process.env.NEXT_PUBLIC_SITE_URL}${
                          alternate.locale
                      }/${alternate.href === '/' ? '' : alternate.href}`,
                  });

                  return acc;
              }, [] as LinkAlternate[])
            : [];

    return (
        <>
            <Head>
                {renderMetaTags([
                    ...(seo ? seo : []),
                    ...(site?.favicon ? site.favicon : []),
                ])}
                {alternateLinks.map((link) => {
                    if (!link.href) {
                        return null;
                    }

                    return (
                        <link
                            key={link.hrefLang}
                            rel={link.rel}
                            hrefLang={link.hrefLang}
                            href={link.href}
                        />
                    );
                })}
                {!!noindex ? <meta name="robots" content="noindex" /> : null}
            </Head>
            {!!header ? header : <Header />}
            <main {...(!!header && { 'data-navbar-child-height': true })}>
                {children}
            </main>
            {!!footer ? footer : null}
            {shouldShow &&
                (isProd ? (
                    <Chat globalConfig={globalConfig} />
                ) : (
                    <ChatStaging globalConfig={globalConfig} />
                ))}
        </>
    );
};

export default Layout;
