import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

export interface ProcessItemProps {
    indexNumber: string;
    title: string;
    text: string;
    className?: string;
}

const ProcessItem = ({
    indexNumber,
    title,
    text,
    className,
}: ProcessItemProps) => {
    return (
        <div className={cls('d-flex', className)}>
            <Heading
                component="h4"
                font="h100"
                className={cls('text-primary-dark mr-xg')}>
                {indexNumber}
            </Heading>
            <div>
                <Heading component="h4" font="b400" className={cls('mb-xs')}>
                    {title}
                </Heading>
                <Text component="div" font="b300">
                    {text}
                </Text>
            </div>
        </div>
    );
};

export default ProcessItem;
