import React from 'react';

import { cls, Picture } from '@solublestudio/soluto-design-system';

import MenuItem, { MenuItemProps } from '@ds/components/shared/Menu/Item';
import Heading from '@ds/components/shared/Heading';

import styles from './styles.module.scss';

export interface MenuProps {
    title?: string;
    image?: string;
    items: MenuItemProps[];
}

const Menu: React.FC<MenuProps> = ({ items = [], title, image }) => {
    return (
        <>
            {image && (
                <div className={styles.wrapperImage} data-skeleton="true">
                    <Picture image={image} layout="full" />
                </div>
            )}
            {title && (
                <Heading
                    component="h3"
                    font="b400"
                    className="mt-sm mb-sm"
                    data-skeleton="true">
                    {title}
                </Heading>
            )}
            {items.length > 0 && (
                <ul className={styles.list}>
                    {items.map((item: MenuItemProps, i: number) => (
                        <li
                            className={cls('mb-sm')}
                            data-skeleton="true"
                            key={`menu-item-${i}`}>
                            <MenuItem {...item} />
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

export default Menu;
