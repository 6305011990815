export function formatImage(
    image: { [key: string]: any },
    extension?: string,
    options?: {
        [key: string]: any;
    },
) {
    if (image) {
        return {
            ...image,
            ...(options && options),
            isImage: Boolean(image?.responsiveImage),
            file: {
                publicURL: image.url || null,
                extension,
                ...(image.responsiveImage ? image.responsiveImage : {}),
            },
        };
    }

    return null;
}
