import React from 'react';

import { Container, Row, Col } from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';
import TitleSubtitle from '@ds/components/shared/TitleSubtitle';
import CardPlan, {
    CardPlanProps,
} from '@ds/components/shared/Cards/Plan/Default';
import CardPlanFeatures, {
    CardPlanFeaturesProps,
} from '@ds/components/shared/Cards/Plan/Features';
import CardPlanAlone from '@ds/components/shared/Cards/Plan/Alone';

import styles from './styles.module.scss';

export interface PlansSectionProps {
    title: string;
    subtitle: string;
    plans: (CardPlanProps | CardPlanFeaturesProps)[];
    anchor?: string;
    showFeatures?: boolean;
}

export default function PlansSection({
    title,
    subtitle,
    plans,
    anchor,
    showFeatures,
}: PlansSectionProps) {
    const CardComponent = plans.length > 1 ? CardPlan : CardPlanAlone; 
    return (
        <Section anchor={anchor}>
            <Container>
                <Row>
                    <Col col={{ xs: 12 }} className="mb-xl">
                        <TitleSubtitle title={title} subtitle={subtitle} />
                    </Col>
                    {plans.map((plan, index) => (
                        <Col
                            key={index}
                            col={{ xs: 12, md: plans.length > 1 ? 6 : 12 }}
                            className={styles.planCol}>
                            {showFeatures ? (
                                <CardPlanFeatures
                                    {...(plan as CardPlanFeaturesProps)}
                                />
                            ) : (
                                <CardComponent {...(plan as CardPlanProps)} />
                            )}
                        </Col>
                    ))}
                </Row>
            </Container>
        </Section>
    );
}
