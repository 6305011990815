import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Picture, { PictureProps } from 'Picture';
import HTMLCheckListComponent from '@ds/components/shared/HTMLComponent/CheckList';
import Heading from '@ds/components/shared/Heading';

import type { HTMLComponentProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';
import Button, { ButtonProps } from '../../Button';

export interface CardCheckProps extends HTMLComponentProps {
    image: PictureProps;
    title: string;
    className?: string;
    cta?: Omit<ButtonProps, 'variant'>;
}

const CardCheck = ({ image, title, text, className, cta }: CardCheckProps) => {
    return (
        <div
            className={cls('bg-base-1000', 'text-base-000', 'd-flex', 'flex-column', 'pb-xs', className)}>
            <Picture
                image={image}
                layout="full"
                objectFit="cover"
                classes={{
                    wrapper: cls(styles.picture),
                }}
            />
            <div
                className={cls(
                    'px-grid-padding',
                    'pb-xs',
                    'd-flex',
                    'flex-column',
                    'align-items-start',
                    styles.wrapper,
                )}>
                <Heading
                    className={cls('pt-grid-padding', 'mb-sm')}
                    font="h100"
                    component="h4">
                    {title}
                </Heading>
                <HTMLCheckListComponent text={text} />
            </div>
            {cta && (
                <div className={cls(styles.ctaWrapper)}>
                    <Button variant="link" {...cta} />
                </div>
            )}
        </div>
    );
};

export default CardCheck;
