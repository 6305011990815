import React from 'react';

import { cls, Icon } from '@solublestudio/soluto-design-system';

import Picture, { PictureProps } from 'Picture';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';
import RatingSection from '@ds/components/organisms/Rating';

export interface CardTestimonialProps {
    image?: PictureProps;
    testimonial: string;
    name: string;
    origin: string;
    rating: number;
}

const CardTestimonial = ({
    image,
    testimonial,
    name,
    origin,
    rating,
}: CardTestimonialProps) => {
    return (
        <div
            className={cls(
                styles.cardTestimonial,
                'd-lg-flex',
                'bg-base-1000',
            )}>
            <div
                className={cls(
                    styles.testimonialInfo,
                    'p-grid-padding d-flex flex-column justify-content-between text-base-000',
                )}>
                <Heading
                    font="h100"
                    component="h4"
                    className={cls(styles.title, 'mb-xxl')}>
                    {testimonial}
                </Heading>
                <div>
                    <Text
                        font="b300"
                        component="p"
                        className={cls('font-weight-semi-bold')}>
                        {name}
                    </Text>
                    <Text font="b200" component="p" className={styles.text}>
                        {origin}
                    </Text>
                    {rating && <RatingSection rating={rating} />}
                </div>
            </div>
        </div>
    );
};

export default CardTestimonial;
