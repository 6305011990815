import React from 'react';

import Section from '@ds/components/shared/Section';
import Summary, { SummaryProps } from '@ds/components/shared/Summary';
import { ButtonProps } from '@ds/components/shared/Button';
export interface ContentImageProps
    extends Omit<SummaryProps, 'alignImage' | 'ctas'> {
    anchor?: string;
    cta?: Omit<ButtonProps, 'variant'>;
}

const ContentImage = ({ anchor, cta, ...props }: ContentImageProps) => {
    return (
        <Section id="hero-section" anchor={anchor} data-theme="bg-gray">
            <Summary
                {...props}
                ctas={cta ? [cta] : []}
                alignImage="start"
                titleFont="h200"
            />
        </Section>
    );
};

export default ContentImage;
