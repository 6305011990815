import React, { useCallback, useMemo } from 'react';
import { useUser } from 'api-base/auth';
import { Header as DSHeader } from '@solublestudio/legalbrave-design-system';
import { LinkProps } from 'Link';
import { useTranslate } from '../../hooks/useTranslate';

export default function Header({
    items,
    social,
    alternateLanguages,
    altPhone,
}: {
    items: LinkProps[];
    social: LinkProps[];
    alternateLanguages: LinkProps[];
    altPhone?: LinkProps;
}) {
    const { user, isLoading } = useUser();
    const [t] = useTranslate();

    const callMeFormOnSubmit = useCallback(
        async (phone) => {
            let response = {
                status: false,
                message: t('header.callme.error'),
            };

            try {
                const res = await fetch('/api/call-me-back/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ phone }),
                });

                const json = await res.json();

                if (json?.message === 'OK') {
                    response = {
                        status: true,
                        message: t('header.callme.success'),
                    };
                } else {
                    if (res.status === 400) {
                        response = {
                            status: false,
                            message: t('header.callme.format_error'),
                        };
                    } else {
                        response = {
                            status: false,
                            message: t('header.callme.error'),
                        };
                    }
                }
            } catch (e) {}

            return response;
        },
        [t],
    );

    const buildNavItem = (item) => ({
        children: item.label,
        href: item.href || '#',
        items: item.items ? item.items.map(buildNavItem) : [],
        description: item.description,
        image: item.image,
    });

    const navProps = useMemo(() => {
        if (!items?.length) {
            return {};
        }

        const telButton = altPhone || items.find(
            (item) => item.isButton && item.href.startsWith('tel'),
        );
        const getStartedButton = items.find(
            (item) => item.isButton && !item.href.startsWith('tel'),
        );

        return {
            nav: items.filter((item) => !item.isButton).map(buildNavItem),
            telButton,
            getStartedButton,
        };
    }, [items]);

    const callMeFormTexts = {
        placeholder: t('header.callme.placeholder'),
        loading: t('header.callme.loading'),
        cta: t('header.callme.cta'),
    };

    const languageLinks =
        alternateLanguages?.map(
            (alternate) =>
                ({
                    ...alternate,
                    label: t('languages.' + alternate.locale),
                } ?? []),
        ) ?? [];

    return (
        <DSHeader
            {...navProps}
            userProps={{
                disabled: isLoading,
                href: `/user/home/`,
                avatar: user ? user.picture : '',
            }}
            languageLinks={languageLinks}
            callMeFormOnSubmit={callMeFormOnSubmit}
            callMeFormTexts={callMeFormTexts}
            socialIcons={social}
        />
    );
}
