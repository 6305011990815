import React from 'react';

import BannerSmall, {
    BannerSmallProps,
} from '@ds/components/shared/Banners/Small';

import { Container } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface BannerSmallSectionProps extends BannerSmallProps {}

const BannerSmallSection: React.FC<BannerSmallSectionProps> = ({
    text,
    cta,
    isContainer = false,
    ...props
}) => {
    return (
        <section {...props} className={isContainer ? styles.section : ''}>
            {isContainer ? (
                <Container>
                    <BannerSmall
                        text={text}
                        cta={cta}
                        isContainer={isContainer}
                    />
                </Container>
            ) : (
                <BannerSmall text={text} cta={cta} isContainer={isContainer} />
            )}
        </section>
    );
};

export default BannerSmallSection;
