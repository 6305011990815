import { useState, useEffect } from 'react';

export function useUtms(cookieName: string): [string] {
    const [utms, setUtms] = useState('');

    useEffect(() => {
        if (typeof document === 'undefined') return;

        const currentCookie = document.cookie
            .split('; ')
            .find((cookie) => cookie.startsWith(cookieName + '='));
        if (currentCookie) {
            // Parsear el valor actual de la cookie
            const value = decodeURIComponent(currentCookie.split('=')[1]);
            setUtms(value);
        }
    }, []);

    return [utms];
}
