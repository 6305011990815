import { formatMedia } from './media';

function parsePath(path = '') {
    let pathname = path || '/';
    let search = ``;
    let hash = ``;

    const hashIndex = pathname.indexOf(`#`);
    if (hashIndex !== -1) {
        hash = pathname.substr(hashIndex);
        pathname = pathname.substr(0, hashIndex);
    }

    const searchIndex = pathname.indexOf(`?`);
    if (searchIndex !== -1) {
        search = pathname.substr(searchIndex);
        pathname = pathname.substr(0, searchIndex);
    }

    return {
        pathname: pathname,
        search: search === `?` ? `` : search,
        hash: hash === `#` ? `` : hash,
    };
}

export function getSlug(page: { slug: string; parent: { slug: string } }) {
    let slug = [page.slug].filter((slug) => slug !== '_');

    if (page.parent && page.parent.slug) {
        slug = [page.parent.slug].concat(slug);
    }

    return slug.length > 0 ? `/${slug.join('/')}/` : '/';
}

export function getHref(item: any) {
    let href = '#';

    if (item.externalLink) {
        href = item.externalLink;
    }

    if (item.link && item.link.slug) {
        href = getSlug(item.link);
    }

    if (item.file && item.file.url) {
        href = item.file.url;
    }

    const { pathname, hash, search } = parsePath(href);

    href = [pathname, hash, search].join('');

    return href;
}

export function formatButton(
    button: { [key: string]: any },
    options: {
        onClick?: (e: Event, href: string) => void;
        [key: string]: any;
    } = {},
) {
    if (!button) {
        button = {};
    }

    let { link, externalLink, file, label, href, ...restButton } = button;

    if (!link) {
        link = {};
    }

    const { id, description, title } = link;
    const { onClick } = options;

    href = href
        ? href
        : getHref({
              link,
              externalLink,
              file,
          });

    return {
        ...((restButton.title || restButton.items || restButton.active) && {
            title: restButton.title,
            items: restButton.items,
            active: restButton.active,
        }),
        ...(id &&
            description &&
            title && {
                id,
                description,
                title,
            }),
        ...(!!restButton.onClick
            ? {
                  onClick: restButton.onClick,
                  component: 'button',
              }
            : !file && onClick
            ? {
                  onClick: (e: MouseEvent) => onClick(e, href),
              }
            : null),
        href,
        label,
        ...(restButton.iconName && {
            iconName: restButton.iconName,
        }),
        ...(restButton.detached && {
            detached: restButton.detached,
        }),
        ...(restButton.description && {
            description: restButton.description,
        }),
        ...(restButton.hideFromColectivos && {
            hideFromColectivos: restButton.hideFromColectivos,
        }),
        ...(restButton.image && {
            image: formatMedia(restButton.image),
        }),
        ...(restButton.children && {
            children: restButton.children.map((subitem: any) =>
                formatButton(subitem),
            ),
        }),
    };
}

export function buildSlugByLocale(
    page: {
        _allSlugLocales: [];
        parent: { _allSlugLocales: []; parent?: { _allSlugLocales: [] } };
    },
    locale: string,
    value: string,
) {
    let slug: any[] | undefined = [];

    if (value !== '_') {
        if (page.parent && page.parent._allSlugLocales) {
            const parent: any = page.parent._allSlugLocales.find(
                (i: any) => i.locale === locale,
            );
            slug = [parent.value].concat(value);

            if (page.parent.parent && page.parent.parent._allSlugLocales) {
                const grand: any = page.parent.parent._allSlugLocales.find(
                    (i: any) => i.locale === locale,
                );
                slug = [grand.value].concat(slug);
            }
        } else {
            slug = [value];
        }
    } else {
        slug = undefined;
    }

    return slug;
}

export function buildSlugsForPages(page: {
    _allSlugLocales: [];
    parent: { _allSlugLocales: []; parent?: { _allSlugLocales: [] } };
}) {
    const slugs: Array<
        | string
        | {
              params: { [x: string]: string | string[] | undefined };
              locale?: string;
          }
    > = [];

    for (let { locale, value } of page._allSlugLocales) {
        let slug: any[] | undefined = buildSlugByLocale(page, locale, value);

        slugs.push({ locale, params: { slug } });
    }

    return slugs;
}
