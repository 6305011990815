import React from 'react';

import { Container, cls } from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';
import TitleSubtitle from '@ds/components/shared/TitleSubtitle';
import Point, { PointProps } from '@ds/components/shared/Point';

import styles from './styles.module.scss';
import Button, { ButtonProps } from '@ds/components/shared/Button';
import Features from './Features';
import { PictureProps } from 'Picture';

export interface PointsSectionProps {
    title: string;
    subtitle: string;
    items: PointProps[];
    cta?: Omit<ButtonProps, 'variant'>;
    anchor?: string;
    titleMobile: string;
    image: PictureProps;
}

const PointsSection = ({
    title,
    subtitle,
    items,
    cta,
    anchor,
    titleMobile,
    image,
}: PointsSectionProps) => {
    return (
        <Section anchor={anchor} className={styles.wrapper}>
            <Features
                title={titleMobile}
                background={image}
                items={items}
                linkPlan={cta}
            />
            <Container className="pt-xl d-lg-none">
                <TitleSubtitle
                    title={title}
                    subtitle={subtitle}
                    subtitleFont="b400"
                    className={cls('mb-xg text-center d-lg-none')}
                />
                <div className={styles.points}>
                    {items.map((point, index) => {
                        return <Point key={index} {...point} />;
                    })}
                </div>
                {cta && (
                    <div
                        className={cls(
                            'd-flex justify-content-center d-lg-none mt-lg',
                        )}>
                        <Button variant="primaryLarge" {...cta} />
                    </div>
                )}
            </Container>
        </Section>
    );
};

export default PointsSection;
