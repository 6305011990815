import { useCallback, useContext, useState, createContext } from 'react';
import { ToastStack } from '@solublestudio/legalbrave-design-system';

const ToastContext = createContext<any>(null);

export default ToastContext;

export function ToastContextProvider({ children }: any) {
    const [toasts, setToasts] = useState<any>([]);

    const addToast = useCallback(
        function (toast, timeout = 8000) {
            setToasts((toasts: any) => [...toasts, toast]);
            setTimeout(() => setToasts((toasts: any) => toasts.slice(1)), timeout);
        },
        [setToasts],
    );

    return (
        <ToastContext.Provider value={addToast}>
            {children}
            <ToastStack items={toasts}/>
        </ToastContext.Provider>
    );
}

export function useToastContext() {
    return useContext(ToastContext);
}
