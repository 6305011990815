import React from 'react';

import Section from '@ds/components/shared/Section';
import TitleSubtitle from '@ds/components/shared/TitleSubtitle';

export interface TitleSectionProps {
    title: string;
    subtitle?: string;
    anchor?: string;
}

const TitleSection = ({
    title,
    subtitle,
    anchor,
}: TitleSectionProps) => {
    return (
        <Section anchor={anchor}>
            <TitleSubtitle
                title={title}
                subtitle={subtitle}
                subtitleFont="b200"
            />
        </Section>
    );
};

export default TitleSection;
