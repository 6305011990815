import React from 'react';

import { Container, cls } from '@solublestudio/soluto-design-system';

import { SectionProps } from '@ds/components/shared/Section';

import styles from './styles.module.scss';

export interface BaseLayoutProps {
    children: React.ReactNode;
    dataTheme?: SectionProps['dataTheme'];
    dataLoading?: boolean;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({
    children,
    dataTheme = 'bg-gray',
    dataLoading = false,
}) => {
    return (
        <div
            className={cls(styles.wrapper)}
            data-theme={dataTheme}
            data-loading={dataLoading}>
            <Container>{children}</Container>
        </div>
    );
};

export default BaseLayout;
