import React from 'react';

import HeroSection, {
    HeroSectionProps,
} from '@ds/components/organisms/HeroSection';

export interface ErrorSectionProps extends HeroSectionProps {}

const ErrorSection = ({ background, ...props }: ErrorSectionProps) => {
    return (
        <HeroSection
            {...props}
            fullscreen
            background={
                background ?? {
                    isImage: true,
                }
            }
        />
    );
};

export default ErrorSection;
