import React, { useImperativeHandle, useRef, forwardRef, ReactNode } from 'react';

import {
    Modal as ModalSoluto,
    ModalRef,
    cls,
    Icon,
    useIsMobile,
    ModalProps,
} from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Button, { ButtonProps } from '@ds/components/shared/Button';
import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';

export interface ModalLargeProps extends ModalProps {
    title?: string;
    description?: string;
    buttons?: ButtonProps[];
    iconName?: string;
    labelClose?: string;
    iconType?: 'error' | 'success' | 'loading' | 'null';
    hideButtons?: boolean;
    children?: ReactNode;
}

export type { ModalRef };

const ModalLarge = forwardRef<ModalRef | null, ModalLargeProps>(function (
    { title, description, buttons, iconName, iconType, labelClose, hideButtons = false, children, ...props },
    ref,
) {
    const isMobile = useIsMobile();

    const modalRef = useRef<ModalRef>(null);

    useImperativeHandle(ref, () => ({
        show: modalRef?.current?.show ?? false,
        preShow: modalRef?.current?.preShow ?? false,
        toggle: () => modalRef?.current?.toggle(),
    }));

    return (
        <ModalSoluto
            {...props}
            ref={modalRef}
            size={isMobile ? 'sm' : 'lg'}
            header={<span></span>}
            classes={{
                body: cls(styles.body, 'text-center'),
                closeButton: cls('d-none'),
            }}>
            {iconName && (
                <Icon
                    name={iconName}
                    size={40}
                    classes={{
                        icon: cls(
                            'mb-lg',
                            styles.icon,
                            iconType ? styles[iconType] : '',
                        ),
                    }}
                />
            )}
            {title && (
                <Heading
                    component="div"
                    font="h100"
                    className={cls(children && styles.title, 'text-center', 'mb-xg')}
                >
                    {title}
                </Heading>
            )}

            {description && (
                <Text component={'div'} className={hideButtons ? '' : 'mb-lg'} font="b300">
                    {description}
                </Text>
            )}

            {children}

            {!hideButtons && (
                <div
                    className={cls(
                        children && 'mt-lg',
                        buttons?.length
                            ? [
                                'd-flex',
                                'flex-column',
                                'align-items-center',
                                'justify-content-center',
                                'text-center',
                                styles.buttons,
                            ]
                            : [],
                    )}>
                    {buttons?.length > 0 && (
                        buttons?.map((button: ButtonProps, i: number) => {
                            return <Button key={`modal-large-${i}`} {...button} />;
                        })
                    )}
                    {labelClose && (
                        <Button
                            className={cls(
                                'font-weight-semi-bold',
                                children ? 'text-base-000' : 'text-primary-dark',
                                !children && styles.closeButton,
                            )}
                            label={labelClose}
                            onClick={() => {
                                modalRef?.current?.toggle();
                            }}
                        />
                    )}
                </div>
            )}
            
        </ModalSoluto>
    );
});

export default ModalLarge;
