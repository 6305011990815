import { marked } from 'marked';

marked.setOptions({
    breaks: true,
    gfm: true,
    headerIds: false,
});

const entities = {
    ['&']: "amp",
    ["'"]: "apos",
    ['<']: "lt",
    ['>']: "gt",
    ['"']: "quot",
    ['\xa0']: "nbsp",
    ['aacute']: "á",
    ['Aacute']: "Á",
    ['eacute']: "é",
    ['Eacute']: "É",
    ['iacute']: "í",
    ['Iacute']: "Í",
    ['oacute']: "ó",
    ['Oacute']: "Ó",
    ['uacute']: "ú",
    ['Uacute']: "Ú",
    ['uuml']: "ü",
    ['Uuml']: "Ü",
    ['Ntilde']: "Ñ",
    ['ntilde']: "ñ",
    ['Ccedil']: "Ç",
    ['ccedil']: "ç",
};

const entityPattern = /&([a-z]+);/gi;

function decodeHTMLEntities(text) {
    // A single replace pass with a static RegExp is faster than a loop
    return text.replace(entityPattern, function (match, entity) {
        entity = entity.toLowerCase();
        if (entities.hasOwnProperty(entity)) {
            return entities[entity];
        }
        // return original string if there is no matching entity (no replace)
        return match;
    });
}

/**
 * Returns text without line breaks.
 *
 * @param {string} text - Text to be converted
 * @return {string} - Text without line breaks.
 */
export function getMarkedText(text: string) {
    return text ? marked(text).replace(/\n$/, '') : '';
}

/**
 * Returns text without p as wrapper
 *
 * @param {string} text - Text to be converted
 * @return {string} - Text without p as wrapper
 */
export function getMarkedTextWithoutWrapper(text: string) {
    return text
        ? getMarkedText(text)
              .replace(/<p>|<\/p>/g, '')
              .trim()
        : '';
}

/**
 * Returns text without p as wrapper
 *
 * @param {string} text - Text to be converted
 * @return {string} - Text without p as wrapper
 */
export function getTextWithoutWrapper(text: string) {
    return text
        ? getTextWithoutBreaklinesAround(text.replace(/<p[^>]*>|<\/p>/g, ''))
        : '';
}

/**
 * Returns text without any wrapper around
 *
 * @param {string} text - Text to be converted
 * @return {string} - Text without any wrapper around
 */
export function getTextWithoutAnyWrapper(text: string) {
    return text
        ? getTextWithoutBreaklinesAround(
              text.replace(
                  /<p[^>]*>|<\/p>|<h1[^>]*>|<\/h1>|<h2[^>]*>|<\/h2>|<h3[^>]*>|<\/h3>|<h4[^>]*>|<\/h4>|<h5[^>]*>|<\/h5>|<h6[^>]*>|<\/h6>/g,
                  '',
              ),
          )
        : '';
}
/**
 * Returns text without breaklines around
 *
 * @param {string} text - Text to be converted
 * @return {string} - Text without breaklines around
 */
export function getTextWithoutBreaklines(text: string) {
    return text
        ? getTextWithoutBreaklinesAround(text.replace(/\r?\n|\r/, ''))
        : '';
}
/**
 * Returns text without whitespaces around
 *
 * @param {string} text - Text to be converted
 * @return {string} - Text without whitespaces around
 */
export function getTextWithoutBreaklinesAround(text: string) {
    return text ? decodeHTMLEntities(text.trim()) : '';
}

/**
 * Returns text with italic tags
 *
 * @param {string} text - Text to be converted
 * @return {string} - Text with italic tags
 */
export function changeFontStyleItalicWithITag(text: string) {
    if (!text) {
        return '';
    }

    [
        ...text.matchAll(
            /<span[^f]+font-style:\s?italic[^>]+>([^<]+)<\/[^>]+>/gm,
        ),
    ].forEach((match) => {
        text = text.replace(match[0], `<i>${match[1]}</i>`);
    });

    return text;
}

/**
 * Returns text with bold tags
 *
 * @param {string} text - Text to be converted
 * @return {string} - Text with bold tags
 */
export function changeFontWeightBoldWithBTag(text: string) {
    if (!text) {
        return '';
    }

    [
        ...text.matchAll(
            /<span[^f]+font-weight:\s?bold[^>]+>([^<]+)<\/[^>]+>/gm,
        ),
    ].forEach((match) => {
        text = text.replace(match[0], `<b>${match[1]}</b>`);
    });

    return text;
}

/**
 * Returns text with data-detached attribute instead of padding-left
 *
 * @param {string} text - Text to be converted
 * @return {string} - Text with data-detached attribute instead of padding-left
 */
export function changePaddingLeftWithPDataDetached(text: string) {
    if (!text) {
        return '';
    }

    [
        ...text.matchAll(
            /<p style="padding-left:\s?[4|8|12]0px;">(.*?)<\/p>/gm,
        ),
    ].forEach((match) => {
        text = text.replace(
            match[0],
            `<p data-detached="true">${match[1]}</p>`,
        );
    });

    return text;
}

/**
 * Returns text without style attribute
 *
 * @param {string} text - Text to be converted
 * @return {string} - Text without style attribute
 */

export function removeStyleTags(text: string) {
    if (!text) {
        return '';
    }

    return text.replace(/\style=\"[^"]+"\s?/gm, '');
}
