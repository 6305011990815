import React, { forwardRef } from 'react';
import {
    Header as SolutoHeader,
    HeaderRef,
    cls,
} from '@solublestudio/soluto-design-system';
import type { HeaderProps as SolutoHeaderProps } from '@solublestudio/soluto-design-system';

import Logo from '~logo';
import LogoMobile from '~isotipo';

import Button, { ButtonProps } from '../Button';
import Text from '../Text';

import { LinkProps } from 'Link';

import styles from './styles.module.scss';

export interface HeaderSimpleProps
    extends Omit<
        SolutoHeaderProps,
        | 'kind'
        | 'logo'
        | 'sticky'
        | 'navs'
        | 'preNavContent'
        | 'forceCollapseStyle'
        | 'classes'
    > {
    telButton?: Omit<ButtonProps, 'variant'>;
    telText?: string;
    collapseContent?: React.ReactNode;
    extraLogo?: string;
    languageLinks?: LinkProps[];
}

const HeaderSimple = forwardRef<HeaderRef, HeaderSimpleProps>(
    function HeaderSimple(
        {
            telButton,
            telText,
            collapseContent,
            extraLogo,
            languageLinks,
            ...props
        },
        ref,
    ) {
        return (
            <SolutoHeader
                {...props}
                ref={ref}
                kind="simple"
                logo={{
                    src: {
                        black: Logo?.src ?? Logo,
                        logoMobile: extraLogo
                            ? LogoMobile.src ?? LogoMobile
                            : Logo?.src ?? Logo,
                    },
                    link: {
                        href: '/',
                        title: 'LegalBrave',
                    },
                }}
                sticky="top"
                classes={{
                    root: cls(
                        styles.root,
                        extraLogo ? styles.withExtraLogo : '',
                    ),
                    collapseContent: styles['collapse-content'],
                    collapse: styles['collapse'],
                }}
                preNavContent={
                    <>
                        {!!extraLogo && (
                            <img
                                src={extraLogo}
                                className={cls(styles.extraLogo)}
                                alt=""
                            />
                        )}
                        {!!telButton && (
                            <>
                                {collapseContent && (
                                    <Button
                                        {...telButton}
                                        label={null}
                                        variant="buttonIconSingle"
                                        iconName="telephone"
                                        className={cls(
                                            styles.btnIcon,
                                            'd-lg-none',
                                        )}
                                    />
                                )}
                                <div
                                    className={cls(
                                        'b100',
                                        collapseContent
                                            ? 'd-none d-lg-block'
                                            : '',
                                    )}>
                                    <Text
                                        font="b100"
                                        className={
                                            'font-weight-semi-bold d-none d-lg-inline-block pr-tiny'
                                        }>
                                        {telText}
                                    </Text>
                                    <Button
                                        {...telButton}
                                        variant="buttonIconBorder"
                                        iconName="telephone"
                                    />
                                </div>
                            </>
                        )}
                    </>
                }
                footerMobile={collapseContent}
            />
        );
    },
);

export default HeaderSimple;
