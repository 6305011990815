const solutoIcons = require('@solublestudio/soluto-design-system/icons.js');

const icons = {
    ...solutoIcons,
    agenda: require('./src/img/icons/agenda.svg'),
    arrowRight: require('./src/img/icons/arrow-right.svg'),
    book: require('./src/img/icons/book-open.svg'),
    bookmark: require('./src/img/icons/bookmark.svg'),
    'book-saved': require('./src/img/icons/book-saved.svg'),
    briefcase: require('./src/img/icons/briefcase.svg'),
    calendar: require('./src/img/icons/calendar.svg'),
    'calendar-tick': require('./src/img/icons/calendar-tick.svg'),
    card: require('./src/img/icons/card.svg'),
    cart: require('./src/img/icons/cart.svg'),
    chat: require('./src/img/icons/chat.svg'),
    check: require('./src/img/icons/check.svg'),
    chevronLeft: require('./src/img/icons/chevron-left.svg'),
    chevronRight: require('./src/img/icons/chevron-right.svg'),
    closeEye: require('./src/img/icons/close-eye.svg'),
    close: require('./src/img/icons/close.svg'),
    conversation: require('./src/img/icons/conversation.svg'),
    downloadDocument: require('./src/img/icons/download-document.svg'),
    cross: require('./src/img/icons/cross.svg'),
    dropdownDown: require('./src/img/icons/dropdown-down.svg'),
    dropdownUp: require('./src/img/icons/dropdown-up.svg'),
    edit: require('./src/img/icons/edit.svg'),
    email: require('./src/img/icons/email.svg'),
    employment: require('./src/img/icons/employment.svg'),
    family: require('./src/img/icons/family.svg'),
    folder: require('./src/img/icons/folder.svg'),
    folderFill: require('./src/img/icons/folder-fill.svg'),
    familyLaw: require('./src/img/icons/family-2.svg'),
    'hamburger-default': require('./src/img/icons/hamburger-default.svg'),
    'hamburger-close': require('./src/img/icons/hamburger-close.svg'),
    help: require('./src/img/icons/help.svg'),
    house: require('./src/img/icons/house.svg'),
    judge: require('./src/img/icons/judge.svg'),
    loading: require('./src/img/icons/loading.svg'),
    logout: require('./src/img/icons/logout.svg'),
    menu: require('./src/img/icons/menu.svg'),
    minus: require('./src/img/icons/minus.svg'),
    note: require('./src/img/icons/note.svg'),
    openEye: require('./src/img/icons/open-eye.svg'),
    pause: require('./src/img/icons/pause.svg'),
    play: require('./src/img/icons/play.svg'),
    plus: require('./src/img/icons/plus.svg'),
    results: require('./src/img/icons/results.svg'),
    search: require('./src/img/icons/search.svg'),
    settings: require('./src/img/icons/settings.svg'),
    shield: require('./src/img/icons/shield.svg'),
    'shield-plus': require('./src/img/icons/shield-plus.svg'),
    star: require('./src/img/icons/star.svg'),
    telephone: require('./src/img/icons/telephone.svg'),
    user: require('./src/img/icons/user.svg'),
    'user-square': require('./src/img/icons/user-square.svg'),
    volumen: require('./src/img/icons/volumen.svg'),
    key: require('./src/img/icons/key.svg'),
    wifi: require('./src/img/icons/wifi.svg'),
    car: require('./src/img/icons/car.svg'),
    copy: require('./src/img/icons/copy.svg'),
    'close-filled': require('./src/img/icons/close-filled.svg'),
};

module.exports = icons;
