import React from 'react';
import {
    Section as SectionSoluto,
    SectionProps as SectionSolutoProps,
    cls,
} from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface SectionProps extends SectionSolutoProps {
    dataTheme?:
        | 'light'
        | 'bg-primary'
        | 'bg-primary-dark'
        | 'bg-primary-darker'
        | 'bg-gray'
        | 'bg-gray-primary-darker';
    children: React.ReactNode;
    className?: string;
    anchor?: string;
    fullContainer?: boolean;
    onlyText?: boolean;
}
const Section = ({
    dataTheme,
    children,
    fullContainer,
    className,
    anchor,
    onlyText,
    ...props
}: SectionProps) => {
    return (
        <SectionSoluto
            className={cls(
                styles.section,
                'position-relative',
                'pt-xxxl',
                className,
                onlyText && styles.marginOnlyText,
            )}
            data-theme={dataTheme ? dataTheme : ''}
            data-container={
                typeof fullContainer === 'boolean'
                    ? fullContainer
                        ? 'full'
                        : 'full-mobile'
                    : ''
            }
            {...props}>
            {!!anchor && (
                <div
                    className={cls('position-absolute', styles.anchor)}
                    id={anchor}
                />
            )}
            {children}
        </SectionSoluto>
    );
};

export default Section;
