import React, { useState } from 'react';

import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

import { cls } from '@solublestudio/soluto-design-system';

import TestimonialItem, { TestimonialItemProps } from './TestimonialItem';
import styles from './styles.module.scss';

export interface TestimonialsFeaturedSectionProps {
    testimonials: TestimonialItemProps[];
    title: string;
}

const TestimonialsFeaturedSection = ({
    testimonials,
    title,
}: TestimonialsFeaturedSectionProps) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [loaded, setLoaded] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        initial: 0,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setLoaded(true);
        },
    });

    return (
        <>
            <div className={cls('navigation-wrapper mt-xxxl')}>
                <div ref={sliderRef} className="keen-slider">
                    {testimonials.map(
                        (item: TestimonialItemProps, index: number) => (
                            <div
                                className={`keen-slider__slide number-slide${index}`}>
                                <TestimonialItem {...item} title={title} />
                            </div>
                        ),
                    )}
                </div>
            </div>
            {testimonials.length > 1 && loaded && instanceRef.current && (
                <div className={styles.dots}>
                    {[
                        ...Array(
                            instanceRef.current.track.details.slides.length,
                        ).keys(),
                    ].map((idx) => {
                        return (
                            <button
                                key={idx}
                                type="button"
                                onClick={() => {
                                    instanceRef.current?.moveToIdx(idx);
                                }}
                                className={cls(
                                    styles.dot,
                                    currentSlide === idx && styles.active,
                                )}></button>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default TestimonialsFeaturedSection;
